import { privateClient } from "src/libraries/http.library";
import { SuccessPayloadSchema } from "src/models";
import {
  ILodgeForm,
  LodgeListPayloadSchema,
  LodgePayloadSchema,
} from "src/models/lodge.model";

const client = privateClient();
const endpoint = "lodge";

const lodgeServices = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, LodgePayloadSchema);
  },

  listGET: async () => {
    return client.get(`/${endpoint}`, {}, LodgeListPayloadSchema);
  },

  createPOST: async (data: ILodgeForm) => {
    return client.post(`/${endpoint}`, data, LodgePayloadSchema);
  },

  updatePUT: async (id: number, data: ILodgeForm) => {
    return client.put(`/${endpoint}/${id}`, data, LodgePayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, SuccessPayloadSchema);
  },
};

export default lodgeServices;

import React, { PropsWithChildren, createContext, useContext } from "react";
import useReducerHook from "src/hooks/reducer.hook";
import EventReducer, {
  IEventState,
  defaultState,
  duckActions,
} from "src/ducks/event.duck";

const useReducer = (state = {}) => {
  return useReducerHook(
    EventReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions,
  );
};

type IEventContext = ReturnType<typeof useReducer>;

const EventContext = createContext<Partial<IEventContext>>({
  state: defaultState,
}) as React.Context<IEventContext>;

type IProps = {
  state?: Partial<IEventState>;
};

const EventProvider: React.FC<PropsWithChildren<IProps>> = ({
  children,
  state,
}) => {
  const reducer = useReducer(state);

  return (
    <EventContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

const useEventContext = () => useContext(EventContext);

export type IUseEventContext = ReturnType<typeof useEventContext>;

export { EventContext, EventProvider, useEventContext };

import {
  UserSchema,
  IUserLogin,
  UserLoginPayloadSchema,
  IUserRecoveryPassword,
  IUserChangePassword,
  UserListPayloadSchema,
  IUserCreate,
} from "src/models/user.model";
import { privateClient, publicClient } from "src/libraries/http.library";
import { SuccessPayloadSchema } from "src/models";
import yup from "src/libraries/validator.library";
import { getCookie, removeCookie } from "src/helpers/cookie.helper";
import { CookieKeys } from "src/constants";

const client = privateClient();
const authClient = publicClient();

const userServices = {
  meGET: async () => {
    return client.get(
      `/user`,
      {
        action: "me",
      },
      UserSchema,
    );
  },

  dataGET: async (id: number) => {
    return client.get(`/user/${id}`, {}, UserSchema);
  },

  listGET: async () => {
    return client.get(`/user`, {}, UserListPayloadSchema);
  },

  createPOST: async (data: IUserCreate) => {
    return client.post(`/user`, data, UserSchema);
  },

  updatePUT: async (id: number, data: IUserCreate) => {
    return client.put(`/user/${id}`, data, UserSchema);
  },

  loginPOST: async (data: IUserLogin) => {
    return client.post("/auth", data, UserLoginPayloadSchema);
  },

  logoutPOST: async () => {
    const token = getCookie(CookieKeys.UserToken);
    removeCookie(CookieKeys.UserToken);

    return client.post(
      "/auth?action=auth_logout",
      {
        token,
      },
      yup.mixed(),
    );
  },

  passwordRecoveryPOST: async (data: IUserRecoveryPassword) => {
    return authClient.post(
      "/auth?action=auth_recovery",
      data,
      SuccessPayloadSchema,
    );
  },

  changePasswordPOST: async (data: IUserChangePassword) => {
    return authClient.post(
      "/auth?action=auth_reset",
      data,
      SuccessPayloadSchema,
    );
  },

  dataDELETE: async (userId: number) => {
    return client.delete(`/user/${userId}`, SuccessPayloadSchema);
  },

  refreshTokenPOST: async () => {
    const token = getCookie(CookieKeys.UserToken);

    return client.post(
      "/auth?action=auth_refresh",
      {
        token,
      },
      yup.mixed(),
    );
  },
};

export default userServices;

import React, { PropsWithChildren, createContext, useContext } from "react";
import useReducerHook from "src/hooks/reducer.hook";
import RitualReducer, {
  IRitualState,
  defaultState,
  duckActions,
} from "src/ducks/ritual.duck";

const useReducer = (state = {}) => {
  return useReducerHook(
    RitualReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions,
  );
};

type IRitualContext = ReturnType<typeof useReducer>;

const RitualContext = createContext<Partial<IRitualContext>>({
  state: defaultState,
}) as React.Context<IRitualContext>;

type IProps = {
  state?: Partial<IRitualState>;
};

const RitualProvider: React.FC<PropsWithChildren<IProps>> = ({
  children,
  state,
}) => {
  const reducer = useReducer(state);

  return (
    <RitualContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </RitualContext.Provider>
  );
};

const useRitualContext = () => useContext(RitualContext);

export type IUseRitualContext = ReturnType<typeof useRitualContext>;

export { RitualContext, RitualProvider, useRitualContext };

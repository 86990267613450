import { ReactNode, useCallback, useRef, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useDistrictContext } from "src/contexts/district.context";
import { IDistrictForm } from "src/models/district.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import DistrictForm from "../district-form/district-form.component";
import { useDivisionContext } from "src/contexts/division.context";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";

type IProps = {
  trigger: ReactNode;
};

function DistrictAdd({ trigger }: IProps) {
  const { actions } = useDistrictContext();
  const { actions: divisionActions } = useDivisionContext();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = useCallback(
    async (formData: IDistrictForm) => {
      setLoading(true);
      const result = await actions.createPOST(formData);

      if (result.payload) {
        await divisionActions.listGET();

        ToastSuccess("District created successfully.");
        setIsOpen(false);
      }

      setLoading(false);
    },
    [actions, setIsOpen, setLoading],
  );
  return (
    <Access type={AccessType.DISTRICT_CREATE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>Add District</Modal.Header>
        <Modal.Content>
          <DistrictForm formRef={formRef} onSubmit={handleSubmit} />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={loading}
            disabled={loading}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default DistrictAdd;

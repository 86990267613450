import { Button, Modal } from "semantic-ui-react";
import DistrictForm from "../district-form/district-form.component";
import { ReactNode, useCallback, useRef, useState } from "react";
import { useDistrictContext } from "src/contexts/district.context";
import { IDistrict, IDistrictForm } from "src/models/district.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { useDivisionContext } from "src/contexts/division.context";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";

type IProps = {
  data: IDistrict;
  trigger: ReactNode;
};

function DistrictEdit({ data, trigger }: IProps) {
  const { actions } = useDistrictContext();
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState(false);
  const { actions: divisionActions } = useDivisionContext();

  const handleSubmit = useCallback(
    async (formData: IDistrictForm) => {
      setLoading(true);
      if (data) {
        const result = await actions.updatePUT(data?.districtId, formData);

        if (result.payload) {
          await divisionActions.listGET();
          ToastSuccess("District updated successfully.");
          setIsOpen(false);
        }
      }

      setLoading(false);
    },
    [actions, data, setIsOpen, setLoading, divisionActions],
  );
  return (
    <Access type={AccessType.DISTRICT_UPDATE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>Edit District</Modal.Header>
        <Modal.Content>
          <DistrictForm
            defaultValues={data}
            formRef={formRef}
            onSubmit={handleSubmit}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={loading}
            disabled={loading}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default DistrictEdit;

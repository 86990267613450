import { Dispatch } from "react";
import Axios from "axios";

import {
  TYPE_FETCHING,
  TYPE_ERROR,
  TYPE_FETCHED,
  TYPE_CANCEL,
} from "src/constants";
import Lang from "./languages";
import { Toast } from "src/components/atoms/toast/toast.component";
import { duckActions } from "src/ducks/user.duck";

export const thunkCreator = async <C extends string, T>(
  actionType: C,
  service: (dispatch: Dispatch<any>) => Promise<T>,
  dispatch: Dispatch<any>,
  meta?: IMeta,
  params?: any[],
  retry = true,
): Promise<IThunkReturn<T>> => {
  dispatch({
    type: actionType,
    status: TYPE_FETCHING,
  });

  try {
    const response = await service(dispatch);
    dispatch({
      type: actionType,
      status: TYPE_FETCHED,
      payload: response,
      params,
    });

    return { payload: response };
  } catch (error: any) {
    if (error instanceof Axios.Cancel) {
      dispatch({
        type: actionType,
        status: TYPE_CANCEL,
      });

      return { error: null };
    }

    dispatch({
      type: actionType,
      status: TYPE_ERROR,
      payload: error?.data ?? error,
      params,
    });

    if (error?.status === 401) {
      if (retry && ["post", "put", "delete"].includes(error.config.method)) {
        const data = duckActions.refreshTokenPOST;

        const result = await thunkCreator(
          data.type,
          () => data.service(),
          dispatch,
          meta,
          params,
          false,
        );

        if (result) {
          return await thunkCreator(
            actionType,
            service,
            dispatch,
            meta,
            params,
          );
        }
      }

      const logout = duckActions.logoutPOST;

      await thunkCreator(logout.type, () => logout.service(), dispatch);
      window.location.reload();
    } else if (!meta && error?.status === 400) {
      Toast({
        id: "http-error", // This will avoid multiple instance of http error
        header: Lang.TTL_TOAST_ERROR,
        content: Lang.formatString(
          error.data && error.data.message
            ? error.data.message
            : error.statusText || error.message,
          error.status,
        ),
      });
    } else if (meta && typeof meta.error === "function") {
      const content = meta.error(error);

      Toast({
        id: "http-error",
        header: Lang.TTL_TOAST_ERROR,
        content:
          content ||
          Lang.formatString(
            Lang.MSG_HTTP_ERROR_BAD_REQUEST,
            error.status ?? 400,
          ),
      });
    } else if (error?.code === "ERR_NETWORK") {
      Toast({
        id: "http-error", // This will avoid multiple instance of http error
        header: Lang.TTL_TOAST_ERROR,
        content: Lang.MSG_HTTP_ERROR_CANNOT_CONNECT,
      });

      return { error: error?.message ?? error };
    } else if (!meta || (meta && meta.error !== false)) {
      Toast({
        id: "http-error", // This will avoid multiple instance of http error
        header: Lang.TTL_TOAST_ERROR,
        content:
          error?.data?.message ??
          error?.message ??
          Lang.MSG_HTTP_ERROR_BAD_REQUEST,
      });
    }

    return { error: error?.data ?? error };
  }
};

export type IStatus = {
  error: any;
  fetching: boolean;
};

interface ICustomAction<C, P = never, S = string, A = never> {
  type: C;
  payload?: P;
  status?: S;
  params?: A;
}

export type ICommonState<T> = {
  status: {
    [K in keyof T]?: IStatus;
  };
};

export type IReturnPromise<T> = T extends Promise<infer U> ? U : T;

type IThunkReturn<T> =
  | { payload: T; error?: never }
  | { payload?: never; error: any };

type IMeta = {
  error?: boolean | ((error: any) => string);
};

export type IAsyncThunk = {
  type: string;
  service: (...args: any[]) => any;
  meta?: IMeta;
};

type IAsyncAction<T extends IAsyncThunk> = ICustomAction<
  T["type"],
  IReturnPromise<ReturnType<T["service"]>>,
  never,
  Parameters<T["service"]>
>;

export type ISyncThunk = (...args: any[]) => {
  type: string;
  payload?: any;
};

type ISyncAction<T extends ISyncThunk> = ICustomAction<
  ReturnType<T>["type"],
  ReturnType<T>["payload"]
>;

// This will auto create reducer actions response
export type IReducerAction<T> = {
  [K in keyof T]: T[K] extends IAsyncThunk
    ? IAsyncAction<T[K]>
    : T[K] extends ISyncThunk
    ? ISyncAction<T[K]>
    : never;
}[keyof T];

export type IReturnActions<T> = {
  [K in keyof T]: T[K] extends IAsyncThunk
    ? (
        ...args: Parameters<T[K]["service"]>
      ) => Promise<IThunkReturn<IReturnPromise<ReturnType<T[K]["service"]>>>>
    : T[K] extends ISyncThunk
    ? (...args: Parameters<T[K]>) => ReturnType<T[K]>
    : never;
};

const thunkFactory = <A, R>(actions: A, dispatch: Dispatch<R>) => {
  return Object.keys(actions as any).reduce((thunks, key) => {
    const action = (actions as any)[key];

    return {
      ...thunks,
      [key]: (...args: any[]) => {
        if (typeof action === "function") {
          return dispatch(action(...args));
        }
        return thunkCreator(
          action.type,
          () => action.service(...args),
          dispatch,
          action.meta,
          args,
        );
      },
    };
  }, {} as IReturnActions<A>);
};

export default thunkFactory;

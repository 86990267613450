import { IRitual } from "src/models/ritual.model";
import styles from "./ritual-detail.module.less";
import { Button, Icon, Modal } from "semantic-ui-react";
import { ReactNode, useState } from "react";
import Lang from "src/libraries/languages";

type IProps = {
  data: IRitual;
  trigger?: ReactNode;
};

function RitualDetail({ data, trigger = <Icon link name="eye" /> }: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Modal
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={trigger}
      size="tiny"
    >
      <Modal.Header>{data.name}</Modal.Header>
      <Modal.Content scrolling>
        <ul className={styles.list}>
          {data.sections.map((section) => (
            <li key={section.ritualSectionId}>
              <Icon name="file alternate outline" />
              <strong>{section.name}</strong>

              <ul>
                {section.positions.map((position) => (
                  <li key={position.ritualSectionPositionId}>
                    <Icon name="user" />
                    {position.position.name}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={() => setIsOpen(false)}>
          {Lang.LBL_CLOSE}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default RitualDetail;

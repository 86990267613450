import React, { PropsWithChildren, createContext, useContext } from "react";
import useReducerHook from "src/hooks/reducer.hook";
import DivisionReducer, {
  IDivisionState,
  defaultState,
  duckActions,
} from "src/ducks/division.duck";

const useReducer = (state = {}) => {
  return useReducerHook(
    DivisionReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions,
  );
};

type IDivisionContext = ReturnType<typeof useReducer>;

const DivisionContext = createContext<Partial<IDivisionContext>>({
  state: defaultState,
}) as React.Context<IDivisionContext>;

type IProps = {
  state?: Partial<IDivisionState>;
};

const DivisionProvider: React.FC<PropsWithChildren<IProps>> = ({
  children,
  state,
}) => {
  const reducer = useReducer(state);

  return (
    <DivisionContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </DivisionContext.Provider>
  );
};

const useDivisionContext = () => useContext(DivisionContext);

export type IUseDivisionContext = ReturnType<typeof useDivisionContext>;

export { DivisionContext, DivisionProvider, useDivisionContext };

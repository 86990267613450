import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { get, orderBy } from "lodash";
import {
  Dimmer,
  Icon,
  List,
  Loader,
  Pagination,
  Table,
} from "semantic-ui-react";
import styles from "./datatable.module.less";
import Lang from "src/libraries/languages";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router";
import qs from "qs";
import { getUrlString } from "src/helpers/url.helper";

type IData = Record<string, any>;

export type IDataTableFilter = {
  name: string;
  op?: string;
  value: any;
};

export type IDataTableColumn<T> = {
  title: string;
  index: string;
  sortable?: boolean;
  render?: (value: any, values: T) => ReactNode;
};

type IDataTableSetState = React.Dispatch<React.SetStateAction<IDataTableState>>;
type IDataTableSetPartialState = React.Dispatch<
  React.SetStateAction<Partial<IDataTableState>>
>;
export type IDataTableToolbarParams = {
  value: any;
  setValue: (value: any) => void;
  state: IDataTableState;
  setState: IDataTableSetPartialState;
};
type IDataTableToolbarFunc = (param: IDataTableToolbarParams) => ReactNode;
type IDataTypeSetFilter = IDataTableToolbarFunc | ReactNode;
export type IDataTableToolbar = Record<string, IDataTypeSetFilter>;

export type IDataTableState = {
  page: number;
  limit: number;
  sort?: string;
  order?: "asc" | "desc";
  filters: IDataTableFilter[];
};

function ToolbarItem({
  name,
  state,
  toolbar,
  handleState,
  setState,
}: {
  name: string;
  state: IDataTableState;
  toolbar: IDataTableToolbarFunc;
  handleState: (name: string, value: any) => void;
  setState: IDataTableSetPartialState;
}) {
  return (
    <>
      {toolbar({
        value: state?.filters.find((v) => v.name === name)?.value,
        setValue: (val: any) => {
          handleState(name, val);
        },
        state,
        setState,
      })}
    </>
  );
}

function DataTableToolbars({
  state,
  toolbars,
  setState,
}: {
  state: IDataTableState;
  toolbars: IDataTableToolbar;
  setState: IDataTableSetState;
}) {
  const handleChange = useCallback(
    (name: string, value: any) => {
      setState((values) => {
        const result = {
          ...values,
          filters: [
            ...(values.filters ?? []).filter((val) => val.name !== name),
            {
              name: name,
              value,
            },
          ],
        };

        return result;
      });
    },
    [setState],
  );

  return (
    <List className={styles.toolbars} horizontal>
      {Object.entries(toolbars).map(([key, toolbar]) => (
        <List.Item key={key}>
          {typeof toolbar === "function" ? (
            <ToolbarItem
              name={key}
              state={state}
              toolbar={toolbar}
              handleState={handleChange}
              setState={setState as IDataTableSetPartialState}
            />
          ) : (
            toolbar
          )}
        </List.Item>
      ))}
    </List>
  );
}

type IDataTableProps<T extends IData> = {
  data: T[];
  columns: IDataTableColumn<T>[];
  toolbars?: IDataTableToolbar;
  sortable?: boolean;
  loading?: boolean;
  onChange?: (params: IDataTableState) => void;
  rowsPerPage?: number[];
  history?: boolean;
};

function DataTable<T extends IData>({
  data,
  columns,
  toolbars,
  sortable = false,
  loading = false,
  rowsPerPage = [20],
  history = false,
  onChange,
}: IDataTableProps<T>) {
  const tableRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState<IDataTableState>({
    page: 1,
    limit: rowsPerPage[0],
    filters: [],
  });

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state, onChange]);

  const handleSetState = useCallback(
    (callback: React.SetStateAction<IDataTableState>) => {
      let tempState: Partial<IDataTableState> = {};
      if (typeof callback === "function") {
        const result = callback(state);

        tempState = {
          ...result,
          filters: result.filters ?? [],
        };
      } else {
        tempState = {
          ...callback,
          filters: callback.filters ?? [],
        };
      }

      tempState.filters = (tempState.filters ?? [])
        // .map((item) => {
        //   if (typeof item.value === "string") {
        //     return {
        //       ...item,
        //       value: item.value.trim(),
        //     };
        //   }

        //   return item;
        // })
        .filter(
          (v) => v.value !== undefined && v.value !== "" && v.value !== null,
        );

      if (history) {
        navigate(getUrlString(location.pathname, tempState));
      } else {
        setState(tempState as IDataTableState);
      }
    },
    [state, setState, history],
  );

  useEffect(() => {
    if (history) {
      const temp = (location.search
        ? qs.parse(location.search.slice(1))
        : {
            filters: [],
          }) as unknown as IDataTableState;

      setState({
        ...temp,
        filters: temp.filters || [],
      });
    }
  }, [location, history, setState]);

  const handleSort = useCallback(
    (key: string) => {
      if (sortable) {
        handleSetState((values) => ({
          ...values,
          sort: key,
          order:
            values.sort === key
              ? values.order === "asc"
                ? "desc"
                : "asc"
              : values.order,
        }));
      }
    },
    [sortable, handleSetState],
  );

  const headers = columns.map(({ sortable: sort = sortable, ...column }) => {
    return (
      <Table.HeaderCell
        key={column.index}
        sorted={
          sort && state.sort === column.index
            ? state.order === "asc"
              ? "ascending"
              : "descending"
            : undefined
        }
        className={classNames({
          [styles.disableSort]: !sort,
        })}
        onClick={sort ? () => handleSort(column.index) : undefined}
      >
        {column.title}
      </Table.HeaderCell>
    );
  });

  const content = useMemo(() => {
    if (data.length) {
      let list: T[] = [...data];

      if (sortable && state?.sort && state?.order) {
        list = orderBy(list, [state?.sort], [state.order ?? "asc"]) as T[];
      }

      const offset = (state.page - 1) * state.limit;
      list =
        list.length > state.limit
          ? list.slice(offset, offset + state.limit)
          : list;

      return list.map((val, row) => {
        const cells = columns.map((v, key) => {
          let value = get(val, `${v.index}`, "") as ReactNode;

          if (v.render) {
            value = v.render(value, val);
          }

          return (
            <Table.Cell key={`cell_${row}_${key}`}>
              <div className={styles.cellWrapper}>
                <strong>{v.title}</strong>
                <div className={styles.content}>{value}</div>
              </div>
            </Table.Cell>
          );
        });

        return <Table.Row key={`row_${row}`}>{cells}</Table.Row>;
      });
    }

    return (
      <Table.Row key={`row_empty`}>
        <Table.Cell textAlign="center" colSpan={columns.length}>
          {Lang.MSG_NO_DATA}
        </Table.Cell>
      </Table.Row>
    );
  }, [data, state, sortable]);

  return (
    <div className={styles.wrapper}>
      {toolbars && (
        <DataTableToolbars
          state={state}
          toolbars={toolbars}
          setState={handleSetState}
        />
      )}
      <div ref={tableRef} className={styles.content}>
        {loading && (
          <Dimmer active inverted>
            <Loader active />
          </Dimmer>
        )}
        <Table className={styles.table} sortable={sortable}>
          <Table.Header>
            <Table.Row>{headers}</Table.Row>
          </Table.Header>
          <Table.Body>{content}</Table.Body>
          {data.length > state.limit && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={columns.length} textAlign="center">
                  <Pagination
                    size="mini"
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    activePage={state.page}
                    totalPages={Math.ceil(data.length / state.limit)}
                    onPageChange={(_, { activePage }) => {
                      handleSetState((values) => ({
                        ...values,
                        page: Number(activePage),
                      }));

                      // if (tableRef.current) {
                      //   tableRef.current.scrollIntoView({
                      //     inline: "center",
                      //   });
                      // }
                    }}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </div>
    </div>
  );
}

export default DataTable;

import yup from "src/libraries/validator.library";

export const SuccessPayloadSchema = yup.object({
  success: yup.boolean().required(),
  message: yup.string().required(),
});

export const DefaultSchema = yup.object({
  updatedAt: yup.date(),
  updatedBy: yup.number(),
  createdAt: yup.date(),
  createdBy: yup.number(),
});

import { useEffect } from "react";
import { useParams } from "react-router";
import { Grid, Loader, Segment } from "semantic-ui-react";
import EventSegment from "src/components/organisms/event-segment/event-segment.component";
import LodgeSegment from "src/components/organisms/lodge-segment/lodge-segment.component";
import PositionSegment from "src/components/organisms/position-segment/position-segment.component";
import { useMemberContext } from "src/contexts/member.context";
import { memberActionTypes } from "src/ducks/member.duck";
import { getServiceStatus } from "src/selectors/status.selector";
import MemberEventAttendance from "../member-event-attendance/member-event-attendance.component";

function MemberDetail() {
  const { id } = useParams();
  const { state, actions } = useMemberContext();
  const status = getServiceStatus(state, memberActionTypes.MEMBER_DATA_READ);
  const memberId = Number(id);
  const { detail } = state;

  useEffect(() => {
    if (id) {
      actions.dataGET(memberId);
    }
  }, [id]);

  if (detail?.memberId !== memberId && status.fetching) {
    return <Loader active />;
  }

  if (!detail) {
    return null;
  }

  return (
    <div>
      <h1>Member Information</h1>

      <Segment>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>Member Name:</Grid.Column>
            <Grid.Column>{`${detail.firstName} ${detail.lastName}`}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>Member ID:</Grid.Column>
            <Grid.Column>{detail.memberNo}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <LodgeSegment data={detail.lodges} />
      <EventSegment data={detail.events} />

      <MemberEventAttendance memberId={memberId} />

      <PositionSegment memberId={detail.memberId} data={detail.conferrals} />
    </div>
  );
}

export default MemberDetail;

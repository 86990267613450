import { Controller, FormProvider } from "react-hook-form";
import { Button, Form, Icon } from "semantic-ui-react";
import { useFieldArray } from "react-hook-form";
import { useValidator } from "src/hooks/validator.hook";
import {
  IRitual,
  IRitualForm,
  RitualFormSchema,
} from "src/models/ritual.model";
import { usePositionContext } from "src/contexts/position.context";
import { useCallback, useEffect, useRef } from "react";
import { getServiceStatus } from "src/selectors/status.selector";
import { positionActionTypes } from "src/ducks/position.duck";
import RitualSectionForm, {
  IReOrderFunction,
  RitualPortal,
} from "./ritual-form-section/ritual-form-section.component";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import styles from "./ritual-form.module.less";
import PositionAdd from "../../position/position-add/position-add.component";

type IProps = {
  defaultValues?: Partial<IRitual>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (formData: IRitualForm) => Promise<void>;
};

function RitualForm({ defaultValues, formRef, onSubmit }: IProps) {
  const nameRef = useRef<HTMLInputElement>(null);
  const childrenRef = useRef<Record<string, any>>({});
  const { state, actions } = usePositionContext();
  const status = getServiceStatus(
    state,
    positionActionTypes.POSITION_LIST_READ,
  );
  const formProps = useValidator(RitualFormSchema, {
    defaultValues: defaultValues as IRitualForm,
  });

  const { control, handleSubmit } = formProps;
  const { fields, append, update, move, remove } = useFieldArray({
    control,
    name: "sections",
  });

  useEffect(() => {
    if (!state.list.length && !status.fetching) {
      actions.listGET();
    }
  }, [state.list, actions, status]);

  useEffect(() => {
    if (!fields.length) {
      append({
        name: "",
        positions: [],
      });
    }
  }, [fields, append]);

  const handlerOrder = (result: DropResult) => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const sourceIndex = source.index;
    const destIndex = destination.index;

    if (type === "parentContainer") {
      move(sourceIndex, destIndex);
    } else if (
      type === "childContainer" &&
      source.droppableId === destination.droppableId
    ) {
      const reorderChild = childrenRef.current[source.droppableId];
      if (reorderChild) {
        reorderChild(sourceIndex, destIndex);
      }
    }
  };

  const handleReorder = useCallback<IReOrderFunction>(
    (index, reorderCallback) => {
      childrenRef.current[index] = reorderCallback;
    },
    [childrenRef],
  );

  useEffect(() => {
    if (nameRef && nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  const isDraggable = fields.length > 1;

  return (
    <FormProvider {...formProps}>
      <div className={styles.actions}>
        <div>
          <PositionAdd
            trigger={
              <Button size="tiny" type="button">
                Add a Position
              </Button>
            }
          />
        </div>
      </div>

      <Form
        ref={formRef}
        className={styles.form}
        data-testid="form"
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Input
              {...field}
              ref={nameRef}
              placeholder="Name"
              error={error?.message}
            />
          )}
        />

        <div className={styles.wrapper}>
          <DragDropContext onDragEnd={handlerOrder}>
            <Droppable droppableId="parent" type="parentContainer">
              {(provided) => (
                <div>
                  <ul
                    className={styles.list}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {fields.map(({ id }, index) => (
                      <Draggable
                        key={id}
                        draggableId={id}
                        index={index}
                        isDragDisabled={!isDraggable}
                      >
                        {(
                          { innerRef, dragHandleProps, draggableProps },
                          snapshot,
                        ) => (
                          <RitualPortal key={id} snapshot={snapshot}>
                            <div
                              ref={innerRef}
                              {...draggableProps}
                              className={styles.section}
                            >
                              {isDraggable && (
                                <div
                                  className={styles.dragIcon}
                                  {...dragHandleProps}
                                >
                                  <Icon name="list" />
                                </div>
                              )}

                              <RitualSectionForm
                                index={index}
                                handleChange={(values) => update(index, values)}
                                handleRemove={() => remove(index)}
                                handleOrder={handleReorder}
                              />
                            </div>
                          </RitualPortal>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <Button
          type="button"
          primary
          onClick={() =>
            append({
              name: "",
              positions: [],
            })
          }
        >
          Add Section
        </Button>
      </Form>
    </FormProvider>
  );
}

export default RitualForm;

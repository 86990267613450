import { Controller } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { useValidator } from "src/hooks/validator.hook";
import {
  IDivision,
  IDivisionForm,
  DivisionFormSchema,
} from "src/models/division.model";

type IProps = {
  defaultValues?: Partial<IDivision>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (formData: IDivisionForm) => Promise<void>;
};

function DivisionForm({ defaultValues, formRef, onSubmit }: IProps) {
  const { control, handleSubmit } = useValidator(DivisionFormSchema, {
    defaultValues: defaultValues as IDivisionForm,
  });

  return (
    <Form
      ref={formRef}
      data-testid="form"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input {...field} placeholder="Name" error={error?.message} />
        )}
      />
    </Form>
  );
}

export default DivisionForm;

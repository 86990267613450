import moment from "moment";
import yup, { checkDuplicate } from "src/libraries/validator.library";
import { RitualShape } from "./ritual.model";
import { omit } from "lodash";
import { LodgeSchema, LodgeShape } from "./lodge.model";
import Lang from "src/libraries/languages";

const EventPositionShape = {
  eventPositionId: yup.number(),
  eventSectionId: yup.number(),
  ritualSectionPositionId: yup.number(),
  sequenceNo: yup.number(),
  positionId: yup.number().required(),
  positionName: yup.string().required(),
  memberId: yup.number().nullable(),
  memberNo: yup.string().nullable(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
};

const EventPositionSchema = yup.object(EventPositionShape);

const EventSectionSchema = yup.object({
  eventSectionId: yup.number(),
  ritualSectionId: yup.number().required(),
  name: yup.string().required(),
  sequenceNo: yup.number(),
  positions: yup.array().of(EventPositionSchema).defined(),
});

export const EventShape = {
  name: yup.string().trim().max(255).required(),
  address: yup.string().required(),
  lodgeId: yup.number().required(),
  lodgeName: yup.string().required(),
  lodgeNo: yup.string().required(),
  lodgeAddress: yup.string().required(),
  ritualId: yup.number().required(),
  ritualName: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "Close time can't be before Open time")
    .nullable()
    .transform((value) => (moment(value).isValid() ? value : null)),
  sections: yup.array().of(EventSectionSchema.defined()).required(),
  updatedAt: yup.date(),
  updatedBy: yup.number(),
  createdAt: yup.date(),
  createdBy: yup.number(),
};

export const EventSchema = yup.object({
  ...EventShape,
  lodge: LodgeSchema,
  ritual: yup
    .object(omit(RitualShape, ["sections"]))
    .nullable()
    .transform((_, value) => {
      if (
        value &&
        Object.values(value).some(
          (v) => !(v === null || v === undefined || v === ""),
        )
      ) {
        return value;
      }

      return null;
    })
    .default(null),
  eventId: yup.number().required(),
});

export const EventFormSchema = yup.object({
  ...EventShape,
  selectedDate: yup.date().required().typeError("Date is required"),
});

export const EventListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(EventSchema.clone()),
});

export const EventPayloadSchema = yup.object({
  data: EventSchema.clone(),
});

const EventMember = yup.object({
  memberId: yup.number().required(),
  memberNo: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  lodges: yup
    .array()
    .of(
      yup.object({
        ...LodgeShape,
        lodgeId: yup.number().required(),
      }),
    )
    .defined(),
  updatedAt: yup.date(),
  updatedBy: yup.number(),
  createdAt: yup.date(),
  createdBy: yup.number(),
});

export const EventPositionReportSchema = yup.object({
  ...EventPositionShape,
  member: EventMember.nullable(),
});

export const EventPositionReportPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(EventPositionReportSchema),
});

const EventNoteShape = {
  eventId: yup.number().required(),
  message: yup.string().required(),
  isDeleted: yup.boolean().default(false),
  createdBy: yup.number(),
  createdAt: yup.date(),
  updatedBy: yup.number(),
  updatedAt: yup.date(),
};

export const EventNoteSchema = yup.object({
  ...EventNoteShape,
  eventNoteId: yup.number().required(),
  author: yup.object({
    userId: yup.number(),
    firstName: yup.string(),
    lastName: yup.string(),
  }),
});

export const EventNoteFormSchema = yup.object({
  eventId: yup.number(),
  eventNoteId: yup.string().nullable(),
  message: yup.string().required(),
});

export const EventNoteListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(EventNoteSchema.clone()),
});

export const EventNotePayloadSchema = yup.object({
  data: EventNoteSchema.clone(),
});

export const EventAttendeeSchema = yup.object({
  eventAttendeeId: yup.number().required(),
  eventId: yup.number().required(),
  memberId: yup.number().required(),
  member: yup.object({
    memberId: yup.number().required(),
    memberNo: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    updatedAt: yup.date(),
    updatedBy: yup.number(),
    createdAt: yup.date(),
    createdBy: yup.number(),
  }),
});

export const EventAttendeeFormSchema = yup.object({
  attendees: yup
    .array()
    .of(
      yup.object({
        memberId: yup
          .number()
          .required()
          .typeError(Lang.MSG_VALIDATION_ERROR_REQUIRED),
        eventId: yup.number().required(),
      }),
    )
    .required()
    .test({
      name: "unique",
      test: checkDuplicate("memberId", "Member already exists"),
    }),
});

export const EventAttendeeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(EventAttendeeSchema),
});

export const EventAttendeesPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(EventAttendeeSchema).required(),
});

export const EventMemberAttendanceSchema = EventAttendeeSchema.concat(
  yup.object({
    event: yup.object(omit(EventShape, "sections")),
  }),
);

export const EventMemberAttendancePayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(EventMemberAttendanceSchema),
});

export type IEvent = yup.Asserts<typeof EventSchema>;
export type IEventForm = yup.Asserts<typeof EventFormSchema>;
export type IEventSection = yup.Asserts<typeof EventSectionSchema>;
export type IEventPosition = yup.Asserts<typeof EventPositionSchema>;
export type IEventNote = yup.Asserts<typeof EventNoteSchema>;
export type IEventNoteForm = yup.Asserts<typeof EventNoteFormSchema>;
export type IEventPositionReport = yup.Asserts<
  typeof EventPositionReportSchema
>;
export type IEventAttendeeForm = yup.Asserts<typeof EventAttendeeFormSchema>;
export type IEventAttendee = yup.Asserts<typeof EventAttendeeSchema>;
export type IEventMemberAttendance = yup.Asserts<
  typeof EventMemberAttendanceSchema
>;

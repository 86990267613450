import { useCallback, useEffect, useRef, useState } from "react";
import { List, Select } from "semantic-ui-react";
import DataTable, {
  IDataTableColumn,
  IDataTableState,
} from "src/components/organisms/datatable/datatable.component";
import { useDistrictContext } from "src/contexts/district.context";
// import DownloadCSV from "src/components/organisms/download/download-csv/download-csv.component";
import { useEventContext } from "src/contexts/event.context";
import { useRitualContext } from "src/contexts/ritual.context";
import { ritualActionTypes } from "src/ducks/ritual.duck";
import { getLodgeName } from "src/helpers/lodge.helper";
import Lang from "src/libraries/languages";
import { IEventPositionReport } from "src/models/event.model";
import { IRitual } from "src/models/ritual.model";
import { getServiceStatus } from "src/selectors/status.selector";
import EventAssociation from "../../event/event-association/event-association.component";
import Axios, { Canceler } from "axios";
import { eventActionTypes } from "src/ducks/event.duck";
import TableSearch from "src/components/molecules/table-search/table-search.component";

const removeDuplicates = (books: IRitual["sections"][number]["positions"]) => {
  const newArray = [];

  const uniqueObject: Record<string, any> = {};

  for (const i in books) {
    const objTitle = books[i].positionId;

    uniqueObject[objTitle] = books[i];
  }

  for (const i in uniqueObject) {
    newArray.push(uniqueObject[i]);
  }

  return newArray;
};

function ReportPosition() {
  const cancelRef = useRef<Canceler>();
  const { state, actions } = useRitualContext();
  const { state: eventState, actions: eventActions } = useEventContext();
  const [ritual, setRitual] = useState<IRitual>();
  const [section, setSection] = useState<IRitual["sections"][number]>();
  const reportStatus = getServiceStatus(
    eventState,
    eventActionTypes.EVENT_MEMBER_LIST_READ,
  );
  const status = getServiceStatus(state, ritualActionTypes.RITUAL_LIST_READ);
  const { state: districtState, actions: districtActions } =
    useDistrictContext();
  const [position, setPosition] = useState<number>();

  useEffect(() => {
    actions.listGET();
    districtActions.listGET();
  }, [actions, districtActions]);

  const fetch = useCallback(
    async (data: Partial<IDataTableState>) => {
      if (cancelRef.current) {
        await new Promise((resolve) => {
          if (cancelRef.current) {
            cancelRef.current();
          }
          setTimeout(() => {
            resolve(true);
          }, 10);
        });
      }

      const params: Partial<IDataTableState> = {
        ...data,
        sort: "lastName",
        order: "asc",
      };

      if (!params.filters?.find((v) => v.name === "ritualId" && v.value)) {
        params.filters = params.filters?.filter(
          (v) => !["ritualSectionId", "positionId"].includes(v.name),
        );
      }

      if (
        !params.filters?.find((v) => v.name === "ritualSectionId" && v.value)
      ) {
        params.filters = params.filters?.filter(
          (v) => !["positionId"].includes(v.name),
        );
      }

      const { token, cancel } = Axios.CancelToken.source();
      cancelRef.current = cancel;

      await eventActions.reportMemberGET(
        {
          ...params,
          filters: params.filters?.filter((v) => v.value),
        },
        {
          cancelToken: token,
        },
      );
    },
    [ritual, section, eventActions, cancelRef],
  );

  const columns: IDataTableColumn<IEventPositionReport>[] = [
    {
      index: "lastName",
      title: Lang.LBL_LAST_NAME,
    },
    {
      index: "firstName",
      title: Lang.LBL_FIRST_NAME,
    },
    {
      index: "lodge",
      title: Lang.LBL_LODGE,
      render: (_, value) =>
        value.member ? (
          <List>
            {value.member.lodges.map((v, i) => (
              <List.Item key={`${i}_${v.lodgeId}`}>{getLodgeName(v)}</List.Item>
            ))}
          </List>
        ) : null,
    },
    {
      index: "memberId",
      title: "Events Participated",
      sortable: false,
      render: (value) => (
        <EventAssociation
          memberId={value}
          ritualId={ritual?.ritualId}
          positionId={position}
        />
      ),
    },
  ];

  return (
    <div>
      <h1>Position Report</h1>

      <DataTable
        columns={columns}
        data={eventState.members}
        loading={reportStatus.fetching}
        sortable
        onChange={fetch}
        toolbars={{
          filterBy: <span>{Lang.LBL_FILTER_BY}:</span>,
          ritualId: ({ value, setValue, setState }) => (
            <Select
              placeholder="Select Ritual"
              options={state.list.map((value) => ({
                key: value.ritualId,
                value: value.ritualId,
                text: value.name,
              }))}
              clearable
              search
              value={value}
              selectOnBlur={false}
              onChange={(_, { value }) => {
                const exist = state.list.find((v) => v.ritualId === value);

                setRitual(exist);
                setPosition(undefined);

                if (!exist) {
                  setState((values) => ({
                    ...values,
                    filters: values.filters?.filter(
                      (v) =>
                        !["ritualId", "ritualSectionId", "positionId"].includes(
                          v.name,
                        ),
                    ),
                  }));
                } else {
                  setValue(value);
                }

                setSection(undefined);

                return value;
              }}
              loading={status.fetching}
            />
          ),
          ritualSectionId: ({ value, setValue, setState }) => (
            <Select
              key={`${ritual?.ritualId}`}
              placeholder="Select Section"
              options={(ritual?.sections ?? []).map((value) => ({
                key: value.ritualSectionId,
                value: value.ritualSectionId,
                text: value.name,
              }))}
              clearable
              search
              value={value}
              selectOnBlur={false}
              onChange={(_, { value }) => {
                const exist = (ritual?.sections ?? []).find(
                  (v) => v.ritualSectionId === value,
                );

                setPosition(undefined);

                if (!exist) {
                  setState((values) => ({
                    ...values,
                    filters: values.filters?.filter(
                      (v) =>
                        !["ritualSectionId", "positionId"].includes(v.name),
                    ),
                  }));
                } else {
                  setValue(value);
                }

                setSection(exist);

                return value;
              }}
              loading={status.fetching}
            />
          ),
          positionId: ({ value, setValue }) => (
            <Select
              key={`${ritual?.ritualId}_${section?.ritualSectionId}`}
              placeholder="Select Position"
              options={removeDuplicates(section?.positions ?? []).map(
                (value) => ({
                  key: value.positionId,
                  value: value.positionId,
                  text: value.position.name,
                }),
              )}
              clearable
              search
              value={value}
              selectOnBlur={false}
              onChange={(_, { value: val }) => {
                setValue(val);
                setPosition(Number(val) || undefined);

                return val;
              }}
              loading={status.fetching}
            />
          ),
          lodgeId: ({ value, setValue }) => (
            <Select
              placeholder="Select Lodge"
              options={(districtState.list ?? [])
                .map((v) => v.lodges)
                .flat()
                .map((value) => ({
                  key: value.lodgeId,
                  value: value.lodgeId,
                  text: getLodgeName(value),
                }))}
              clearable
              value={value}
              selectOnBlur={false}
              search
              onChange={(_, data) => setValue(data.value)}
            />
          ),
          keyword: (params) => <TableSearch {...params} delay={300} />,
          // download: () => (
          //   <DownloadCSV
          //     name={Lang.TTL_POSITION_REPORT}
          //     data={eventState.members.map((member) => ({
          //       [Lang.LBL_LAST_NAME]: member.lastName,
          //       [Lang.LBL_FIRST_NAME]: member.firstName,
          //       [Lang.LBL_POSITION]: member.positionName,
          //     }))}
          //   />
          // ),
        }}
      />
    </div>
  );
}

export default ReportPosition;

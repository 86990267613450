import { sortBy } from "lodash";
import { useCallback, useMemo } from "react";
import { AccessRole, AccessType } from "src/constants";
import { useLodgeContext } from "src/contexts/lodge.context";
import { useUserContext } from "src/contexts/user.context";
import { hasAccess } from "src/helpers/access.helper";
import { ILodge } from "src/models/lodge.model";
import { IMember } from "src/models/member.model";

export const useMemberAction = (member: IMember) => {
  const {
    state: { me },
  } = useUserContext();

  const checkAccess = useCallback(
    (type: AccessType) => {
      if (!me || !hasAccess(type)) {
        return false;
      }

      if (
        [AccessRole.Admin, AccessRole.JurisdictionAdmin].includes(me.userTypeId)
      ) {
        return true;
      }

      if (me.userTypeId === AccessRole.DivisionAdmin) {
        return member.lodges.some((v) => v.divisionId === me.divisionId);
      } else if (me.userTypeId === AccessRole.DistrictAdmin) {
        return member.lodges.some((v) => v.districtId === me.districtId);
      } else if (me.userTypeId === AccessRole.LodgeAdmin) {
        return member.lodges.some((v) => v.lodgeId === me.lodgeId);
      }

      return false;
    },
    [me, member],
  );

  const hasEdit = useMemo(
    () => checkAccess(AccessType.MEMBER_UPDATE),
    [checkAccess],
  );

  const hasDelete = useMemo(
    () => checkAccess(AccessType.MEMBER_DELETE),
    [checkAccess],
  );

  return { hasEdit, hasDelete };
};

export const useMemberLodgeList = () => {
  const {
    state: { me },
  } = useUserContext();
  const { state: lodgeState } = useLodgeContext();

  return useMemo(() => {
    if (!me) {
      return [];
    }

    let list: ILodge[] = [];

    if (
      [AccessRole.Admin, AccessRole.JurisdictionAdmin].includes(me.userTypeId)
    ) {
      list = lodgeState.list;
    } else if (AccessRole.DivisionAdmin === me.userTypeId) {
      list = lodgeState.list.filter((v) => v.divisionId === me.divisionId);
    } else if (AccessRole.DistrictAdmin === me.userTypeId) {
      list = lodgeState.list.filter((v) => v.districtId === me.districtId);
    } else if (AccessRole.LodgeAdmin === me.userTypeId) {
      list = lodgeState.list.filter((v) => v.lodgeId === me.lodgeId);
    }

    return sortBy(list, (lodge) => Number(lodge.name) || lodge.name);
  }, [lodgeState]);
};

class EventManager<T = any> {
  private list = new Map();

  on(event: T, callback: (...args: any[]) => void) {
    if (!this.list.has(event)) {
      this.list.set(event, []);
    }

    this.list.get(event).push(callback);

    return this;
  }

  off(event: T) {
    this.list.delete(event);

    return this;
  }

  emit(event: T, ...args: any[]) {
    if (this.list.has(event)) {
      this.list
        .get(event)
        .forEach((callback: (...args: any[]) => void) => callback(...args));
    }
  }
}

export default EventManager;

export const getFullName = (
  user: {
    userId?: number | null;
    memberNo?: string | null;
    memberId?: number | null;
    firstName?: string | null;
    lastName?: string | null;
  },
  hasNumber = false,
) =>
  user.firstName && user.firstName
    ? `${user.firstName} ${user.lastName} ${
        hasNumber && user.memberNo ? `(${user.memberNo})` : ""
      }`.trim()
    : "";

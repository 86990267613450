import { Button, Form } from "semantic-ui-react";
import styles from "./password-forgot.module.less";
import { useNavigate } from "react-router";
import { useValidator } from "src/hooks/validator.hook";
import {
  IUserRecoveryPassword,
  UserRecoveryPasswordSchema,
} from "src/models/user.model";
import { useCallback } from "react";
import { useUserContext } from "src/contexts/user.context";
import { Controller } from "react-hook-form";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import { getServiceStatus } from "src/selectors/status.selector";
import { userActionTypes } from "src/ducks/user.duck";
import Lang from "src/libraries/languages";
import Logo from "src/components/atoms/logo/logo.component";

function PasswordForgot() {
  const navigate = useNavigate();
  const { state, actions } = useUserContext();
  const { control, handleSubmit } = useValidator(UserRecoveryPasswordSchema);
  const status = getServiceStatus(state, userActionTypes.USER_FORGOT_PASSWORD);

  const handleFormSubmit = useCallback(
    async (formData: IUserRecoveryPassword) => {
      const result = await actions.passwordRecoveryPOST(formData);

      if (result.payload) {
        ToastSuccess(result.payload.message);
        navigate("/auth");
      }
    },
    [actions, navigate],
  );

  return (
    <div className={styles.wrapper}>
      <Logo size="huge" />

      <Form autocomplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
        <h2>{Lang.TTL_FORGOT_PASSWORD}</h2>
        <p>{Lang.MSG_FORGOT_PASSWORD}</p>

        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Input
              {...field}
              placeholder={Lang.LBL_EMAIL}
              type="email"
              error={error?.message}
            />
          )}
        />

        <Button
          secondary
          size="big"
          fluid
          disabled={status.fetching}
          loading={status.fetching}
        >
          {Lang.LBL_SEND}
        </Button>
        <Button
          size="big"
          fluid
          disabled={status.fetching}
          loading={status.fetching}
          type="button"
          onClick={() => navigate("/auth")}
        >
          {Lang.LBL_CANCEL}
        </Button>
      </Form>
    </div>
  );
}

export default PasswordForgot;

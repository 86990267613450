import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import routes, { IRoute } from "./router";
import * as Contexts from "./contexts";
import ToastContainer from "./components/atoms/toast/toast.component";
import { httpCancel } from "./libraries/http.library";
import { Loader } from "semantic-ui-react";

const addLoader = (route: IRoute) => {
  return {
    ...route,
    loader: async (data) => {
      const result = await httpCancel(data);

      if (route.loader) {
        return route.loader(data);
      }

      return result;
    },
  } as IRoute;
};

const router = createBrowserRouter(
  routes.map((route) => {
    if (route.children) {
      return addLoader({
        ...route,
        children: route.children.map((value) => addLoader(value)),
      });
    }

    return addLoader(route);
  }) as RouteObject[],
);

function App() {
  const wrappedChildren = Object.values(Contexts).reduce(
    (child, ContextProvider) => <ContextProvider>{child}</ContextProvider>,
    <RouterProvider router={router} fallbackElement={<Loader active />} />,
  );

  return (
    <>
      {wrappedChildren}
      <ToastContainer delay={10000} />
    </>
  );
}

export default App;

import { Button, Icon, Modal } from "semantic-ui-react";
import { ReactNode, useState } from "react";
import Lang from "src/libraries/languages";
import { ILodge } from "src/models/lodge.model";
import FieldReadOnly from "src/components/atoms/field-readonly/field-readonly.component";
import { IDivision } from "src/models/division.model";
import { IDistrict } from "src/models/district.model";
import { getLodgeName } from "src/helpers/lodge.helper";
import styles from "./lodge-detail.module.less";
import { Link } from "react-router-dom";
import { getUrlString } from "src/helpers/url.helper";

type IProps = {
  lodge: ILodge;
  division: IDivision;
  district: IDistrict;
  trigger?: ReactNode;
};

function LodgeDetail({
  lodge,
  division,
  district,
  trigger = <Icon link name="eye" />,
}: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Modal
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={trigger}
      size="tiny"
    >
      <Modal.Header>{getLodgeName(lodge)}</Modal.Header>
      <Modal.Content>
        <FieldReadOnly label={Lang.LBL_DIVISION} value={division.name} />
        <FieldReadOnly label={Lang.LBL_DISTRICT} value={district.name} />
        <FieldReadOnly label={Lang.LBL_LODGE_NUMBER} value={lodge.lodgeNo} />
        <FieldReadOnly label={Lang.LBL_ADDRESS} value={lodge.address} />
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <Link
          to={getUrlString("/events", {
            filters: [
              {
                name: "divisionId",
                value: lodge.divisionId,
              },
              {
                name: "districtId",
                value: lodge.districtId,
              },
              {
                name: "lodgeId",
                value: lodge.lodgeId,
              },
            ],
          })}
        >
          See Events
        </Link>
        <Button type="button" onClick={() => setIsOpen(false)}>
          {Lang.LBL_CLOSE}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default LodgeDetail;

import { ICommonState, IReducerAction } from "src/libraries/thunk.library";
import positionServices from "src/services/position.service";
import { IPosition } from "src/models/position.model";
import { sortBy } from "lodash";

export const positionActionTypes = {
  POSITION_DATA_READ: "POSITION_DATA_READ",
  POSITION_LIST_READ: "POSITION_LIST_READ",
  POSITION_DATA_CREATE: "POSITION_DATA_CREATE",
  POSITION_DATA_UPDATE: "POSITION_DATA_UPDATE",
  POSITION_DATA_DELETE: "POSITION_DATA_DELETE",
  POSITION_DATA_SET: "POSITION_DATA_SET",
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: positionActionTypes.POSITION_DATA_READ,
    service: positionServices.dataGET,
  },

  listGET: {
    type: positionActionTypes.POSITION_LIST_READ,
    service: positionServices.listGET,
  },

  createPOST: {
    type: positionActionTypes.POSITION_DATA_CREATE,
    service: positionServices.createPOST,
  },

  updatePUT: {
    type: positionActionTypes.POSITION_DATA_UPDATE,
    service: positionServices.updatePUT,
  },

  dataDELETE: {
    type: positionActionTypes.POSITION_DATA_DELETE,
    service: positionServices.dataDELETE,
  },

  // This is a sync action
  setData: (data: IPosition) => ({
    type: positionActionTypes.POSITION_DATA_SET,
    payload: {
      data,
    },
  }),
};

export type IPositionAsync = typeof duckActions;

export interface IPositionState
  extends ICommonState<typeof positionActionTypes> {
  data?: IPosition;
  list: IPosition[];
  total: number;
}

export const defaultState: IPositionState = {
  status: {},
  list: [],
  total: 0,
};

const PositionReducer = (
  state: IPositionState,
  action: IReducerAction<IPositionAsync>,
): IPositionState => {
  switch (action.type) {
    case positionActionTypes.POSITION_DATA_SET:
    case positionActionTypes.POSITION_DATA_READ: {
      return {
        ...state,
        data: action.payload?.data,
      };
    }

    case positionActionTypes.POSITION_DATA_CREATE: {
      if (action.payload?.data) {
        const { data } = action.payload;
        return {
          ...state,
          data: data,
          list: sortBy([...state.list, data], ["name"]),
        };
      }

      return state;
    }

    case positionActionTypes.POSITION_DATA_UPDATE: {
      if (action.payload?.data) {
        const list = [...state.list];
        const { data } = action.payload;
        const index = list.findIndex((v) => v.positionId === data.positionId);

        list.splice(index, 1, data);

        return {
          ...state,
          data: data,
          list: sortBy(list, ["name"]),
        };
      }

      return state;
    }

    case positionActionTypes.POSITION_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case positionActionTypes.POSITION_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.positionId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default PositionReducer;

import { Button, Modal, Popup, Radio } from "semantic-ui-react";
import RitualForm from "../ritual-form/ritual-form.component";
import { ReactNode, useCallback, useRef, useState } from "react";
import { useRitualContext } from "src/contexts/ritual.context";
import { IRitual, IRitualForm } from "src/models/ritual.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { ritualActionTypes } from "src/ducks/ritual.duck";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";
import styles from "../ritual-form/ritual-form.module.less";

type IProps = {
  data: IRitual;
  trigger: ReactNode;
};

function RitualEdit({ data, trigger }: IProps) {
  const { state, actions } = useRitualContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isConferral, setIsConferral] = useState(data.isConferral);
  const formRef = useRef<HTMLFormElement>(null);
  const status = getServiceStatus(state, ritualActionTypes.RITUAL_DATA_UPDATE);

  const handleSubmit = useCallback(
    async (formData: IRitualForm) => {
      if (data) {
        const sections = formData.sections.map((value, index) => ({
          ...value,
          sequenceNo: index + 1,
          positions: value.positions.map((v, i) => ({
            ...v,
            sequenceNo: i + 1,
          })),
        }));

        const result = await actions.updatePUT(data?.ritualId, {
          ...formData,
          isConferral,
          sections,
        });

        if (result.payload) {
          ToastSuccess("Ritual updated successfully.");
          actions.listGET();
          setIsOpen(false);
        }
      }
    },
    [actions, data, setIsOpen, isConferral],
  );
  return (
    <Access type={AccessType.RITUAL_UPDATE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>
          Edit Ritual
          <Popup
            trigger={
              <div className={styles.conferral}>
                <Radio
                  toggle
                  checked={isConferral}
                  title="Conferral"
                  onChange={(_, { checked, value }) => {
                    setIsConferral(!!checked);

                    return value;
                  }}
                />
              </div>
            }
            content={
              <div>
                Turning this <strong>ON</strong> sets this ritual to be a
                Conferral and will be added to the Member positions Report.
              </div>
            }
            basic
          />
        </Modal.Header>
        <Modal.Content scrolling className="no-padding">
          <RitualForm
            defaultValues={data}
            formRef={formRef}
            onSubmit={handleSubmit}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={status.fetching}
            disabled={status.fetching}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default RitualEdit;

import { useCallback, useEffect, useState } from "react";
import { Button, Header, Icon, Input } from "semantic-ui-react";
import DataTable, {
  IDataTableColumn,
  IDataTableState,
} from "src/components/organisms/datatable/datatable.component";
import { useUserContext } from "src/contexts/user.context";
import { IUser } from "src/models/user.model";
import UserAdd from "../user-add/user-add.component";
import UserEdit from "../user-edit/user-edit.component";
import UserDelete from "../user-delete/user-delete.component";
import Lang from "src/libraries/languages";
import { AccessRole } from "src/constants";

const columns: IDataTableColumn<IUser>[] = [
  {
    index: "name",
    title: Lang.LBL_FULL_NAME,
    render: (_, values) => `${values.firstName} ${values.lastName}`,
  },
  {
    index: "status",
    title: Lang.LBL_STATUS,
  },
  {
    index: "userTypeId",
    title: Lang.LBL_LEVEL,
    render: (value: AccessRole) => Lang.roles[value],
  },
  {
    index: "action",
    title: Lang.LBL_ACTIONS,
    render: (_, values) => (
      <div className="action-buttons">
        <UserEdit data={values} trigger={<Icon link name="edit outline" />} />
        <UserDelete
          data={values}
          trigger={<Icon link name="trash alternate outline" />}
        />
      </div>
    ),
  },
];

function UserList() {
  const {
    state: { list: stateList },
    actions,
  } = useUserContext();
  const [keyword, setKeyword] = useState<string>("");
  const [list, setList] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(stateList.length === 0);
      await actions.listGET();
      setLoading(false);
    };

    fetch();
  }, [actions, setLoading]);

  useEffect(() => {
    if (keyword) {
      setList(
        stateList.filter((v) =>
          `${v.firstName} ${v.lastName}`
            .toLowerCase()
            .includes(keyword.toLowerCase()),
        ),
      );
    } else {
      setList(stateList);
    }
  }, [stateList, keyword, setList]);

  const handleFilter = useCallback(
    (params: IDataTableState) => {
      const filter = params.filters.find((v) => v.name === "filter");

      if (filter) {
        setKeyword(filter.value);
      }
    },
    [setKeyword],
  );

  return (
    <div>
      <Header as="h1">{Lang.TTL_USER_LIST}</Header>

      <DataTable
        columns={columns}
        data={list}
        loading={loading}
        onChange={handleFilter}
        toolbars={{
          filter: ({ value, setValue }) => (
            <Input
              placeholder={Lang.LBL_SEARCH}
              value={value}
              onChange={(_, data) => setValue(data.value)}
              icon={
                value ? (
                  <Icon name="close" link onClick={() => setValue("")} />
                ) : undefined
              }
            />
          ),
          add: () => (
            <UserAdd
              trigger={
                <Button primary icon="user" content={Lang.TTL_USER_ADD} />
              }
            />
          ),
        }}
      />
    </div>
  );
}

export default UserList;

import yup from "src/libraries/validator.library";

export const PositionShape = {
  name: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  createdAt: yup.date(),
  createdBy: yup.string(),
};

export const PositionSchema = yup.object({
  ...PositionShape,
  positionId: yup.number().required(),
});

export const PositionFormSchema = yup.object({
  name: yup.string().required().default(""),
  updatedBy: yup.string().nullable(),
  createdBy: yup.string().nullable(),
});

export const PositionListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(PositionSchema.clone()),
});

export const PositionPayloadSchema = yup.object({
  data: PositionSchema.clone(),
});

export type IPosition = yup.Asserts<typeof PositionSchema>;
export type IPositionForm = yup.Asserts<typeof PositionFormSchema>;

import { Loader } from "semantic-ui-react";
import { IEvent } from "src/models/event.model";
import { useEffect, useRef, useState } from "react";
import EventAttendanceForm from "../event-attendance-form/event-attendance-form.component";
import { useEventContext } from "src/contexts/event.context";
import { useMemberContext } from "src/contexts/member.context";
import EventAttendanceDetail from "../event-attendance-detail/event-attendance-detail.component";

type IProps = {
  data: IEvent;
  isView?: boolean;
};

function EventAttendance({ data, isView }: IProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState(true);
  const {
    state: { attendees },
    actions,
  } = useEventContext();
  const { actions: memberActions } = useMemberContext();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await Promise.all([
        actions.attendanceGET(data.eventId),
        memberActions.listGET(),
      ]);
      setLoading(false);
    };

    fetch();
  }, [actions, data, setLoading]);

  return (
    <div>
      {loading ? (
        <div className="modal-content-scrolling">
          <Loader active />
        </div>
      ) : isView ? (
        <EventAttendanceDetail data={attendees[data.eventId]} />
      ) : (
        <EventAttendanceForm
          defaultValues={{
            attendees: attendees[data.eventId],
          }}
          eventId={data.eventId}
          formRef={formRef}
        />
      )}
    </div>
  );
}

export default EventAttendance;

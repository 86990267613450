import { Button, Comment, Form, Loader, Modal } from "semantic-ui-react";
import styles from "./note-segment.module.less";
import {
  EventNoteFormSchema,
  IEventNote,
  IEventNoteForm,
} from "src/models/event.model";
import { useValidator } from "src/hooks/validator.hook";
import { Controller } from "react-hook-form";
import { useUserContext } from "src/contexts/user.context";
import { getFullName } from "src/helpers/member.helper";
import Lang from "src/libraries/languages";
import { useEventContext } from "src/contexts/event.context";
import { useCallback, useEffect, useState } from "react";
import { getDateTimeFromNow } from "src/helpers/datetime.helper";
import Confirm from "src/components/atoms/confirm/confirm.component";
import { getServiceStatus } from "src/selectors/status.selector";
import { eventActionTypes } from "src/ducks/event.duck";
import { hasRole } from "src/helpers/access.helper";
import { AccessRole } from "src/constants";
import classNames from "classnames";

type IProps = {
  eventId: number;
  isView?: boolean;
};

function NoteSegment({ eventId, isView = false }: IProps) {
  const {
    state: { me: user },
  } = useUserContext();
  const { control, handleSubmit, reset } = useValidator(EventNoteFormSchema);
  const { state, actions } = useEventContext();
  const status = getServiceStatus(state, eventActionTypes.EVENT_NOTE_LIST_READ);
  const [note, setNote] = useState<IEventNote>();
  const [loading, setLoading] = useState(false);
  const { notes } = state;

  useEffect(() => {
    actions.noteListPOST(eventId);
  }, [actions, eventId]);

  const resetForm = () => {
    setNote(undefined);
    reset({ message: "" });
  };

  const onSubmit = useCallback(
    async (data: IEventNoteForm) => {
      setLoading(true);
      if (note) {
        await actions.notePUT(note.eventNoteId, {
          eventId,
          ...data,
        });
      } else {
        await actions.notePOST({
          eventId,
          ...data,
        });
      }

      resetForm();
      setLoading(false);
    },
    [actions, eventId, note, reset],
  );

  const handleDelete = useCallback(
    async (eventNoteId: number) => {
      const result = await actions.noteDELETE(eventNoteId);

      if (result.payload) {
        await actions.noteListPOST(eventId);
      }
    },
    [actions, eventId],
  );

  const handleEdit = useCallback(
    (value: IEventNote) => {
      reset(value as unknown as IEventNoteForm);
      setNote(value);
    },
    [reset, setNote],
  );

  return (
    <div className={classNames(styles.wrapper, "modal-content-scrolling")}>
      <div className={styles.list}>
        {status.fetching && <Loader active />}

        {notes[eventId] && notes[eventId].length > 0 ? (
          <Comment.Group>
            {notes[eventId].map((value, index) => (
              <Comment key={`note_${eventId}_${index}`}>
                <Comment.Content>
                  <Comment.Author as="a">
                    {getFullName(value.author)}
                  </Comment.Author>
                  <Comment.Metadata>
                    <div>{getDateTimeFromNow(value.createdAt)}</div>
                  </Comment.Metadata>
                  <Comment.Text>{value.message}</Comment.Text>

                  {!isView &&
                    (user?.userId === value.createdBy ||
                      hasRole([
                        AccessRole.Admin,
                        AccessRole.JurisdictionAdmin,
                      ])) && (
                      <Comment.Actions>
                        <Comment.Action onClick={() => handleEdit(value)}>
                          Edit
                        </Comment.Action>
                        <Confirm
                          header={Lang.TTL_CONFIRM_DELETE}
                          content={Lang.MSG_CONFIRM_DELETE}
                          trigger={<Comment.Action>Delete</Comment.Action>}
                          onConfirm={async () =>
                            await handleDelete(value.eventNoteId)
                          }
                          confirmButton={{
                            negative: true,
                            content: Lang.LBL_DELETE,
                          }}
                        />
                      </Comment.Actions>
                    )}
                </Comment.Content>
              </Comment>
            ))}
          </Comment.Group>
        ) : (
          <div>{Lang.MSG_NO_NOTES}</div>
        )}
      </div>
      {!isView && (
        <Form
          key={note?.eventNoteId ?? "form"}
          onSubmit={(event) => {
            handleSubmit(onSubmit)(event);
          }}
        >
          <div className={styles.form}>
            <Controller
              name="message"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Form.TextArea
                  fluid
                  {...field}
                  error={error?.message}
                  placeholder={Lang.LBL_NOTE_MESSAGE}
                />
              )}
            />
            <div className={styles.footer}>
              <div>
                <strong>Author: </strong>
                {note
                  ? getFullName(note.author)
                  : (user && getFullName(user)) || "Unknown"}
              </div>
              <div>
                {note && (
                  <Button
                    type="button"
                    size="mini"
                    disabled={loading}
                    onClick={resetForm}
                  >
                    {Lang.LBL_CANCEL}
                  </Button>
                )}
              </div>
            </div>
          </div>

          <Modal.Actions>
            <Button primary loading={loading} disabled={loading}>
              {Lang.LBL_SAVE}
            </Button>
          </Modal.Actions>
        </Form>
      )}
    </div>
  );
}

export default NoteSegment;

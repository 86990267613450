import { ReactNode, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import { useUserContext } from "src/contexts/user.context";
import { userActionTypes } from "src/ducks/user.duck";
import { useUserAction } from "src/hooks/auth.hook";
import Lang from "src/libraries/languages";
import { IUser } from "src/models/user.model";
import { getServiceStatus } from "src/selectors/status.selector";

type IProps = {
  data: IUser;
  trigger: ReactNode;
};

function UserDelete({ data, trigger }: IProps) {
  const { hasDelete } = useUserAction();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { state, actions } = useUserContext();
  const status = getServiceStatus(state, userActionTypes.USER_DATA_DELETE);

  const handleConfirm = useCallback(async () => {
    const result = await actions.dataDELETE(data.userId);

    if (result.payload) {
      ToastSuccess(result.payload.message);

      if (state.me?.userId === data.userId) {
        await actions.logoutPOST();
        navigate("/auth");
      } else {
        actions.listGET();
        setIsOpen(false);
      }
    }
  }, [data, actions, setIsOpen, navigate]);

  return (
    <Access type={AccessType.USER_DELETE} when={hasDelete(data)}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        size="tiny"
      >
        <Modal.Header>{Lang.TTL_CONFIRM_DELETE}</Modal.Header>
        <Modal.Content>
          Are you sure you want to delete{" "}
          <strong>{`${data.firstName} ${data.lastName}`}</strong> as a user?
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            type="button"
            negative
            onClick={handleConfirm}
            loading={status.fetching}
            disabled={status.fetching}
          >
            {Lang.LBL_CONFIRM}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default UserDelete;

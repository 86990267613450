import { useState } from "react";
import { Dropdown, Menu, Sidebar } from "semantic-ui-react";
import styles from "./navigation.module.less";
import Logo from "src/components/atoms/logo/logo.component";
import routes from "src/router";
import { useUserContext } from "src/contexts/user.context";
import { Link, useLocation } from "react-router-dom";
import { hasAccess } from "src/helpers/access.helper";
import Lang from "src/libraries/languages";
import { AccessRole } from "src/constants";

type IProps = {
  isOpen?: boolean;
  setClose?: () => void;
};

function Navigation({ isOpen = true, setClose }: IProps) {
  const location = useLocation();
  const {
    state: { me },
    actions,
  } = useUserContext();
  const list = routes.filter(
    (v) =>
      v.title && (!v.permission || (v.permission && hasAccess(v.permission))),
  );
  const [open, setOpen] = useState(location.pathname.includes("/report"));

  return (
    <Sidebar
      animation="slide along"
      visible={isOpen}
      className={styles.wrapper}
    >
      <div className={styles.logo}>
        <Logo />
      </div>
      <Menu secondary vertical fluid>
        {list.map((route, index) => {
          const children = route.children
            ? route.children.filter((v) => v.title)
            : [];

          return children.length ? (
            <Dropdown
              key={`page_${index}`}
              item
              text={route.title}
              className={styles.dropdown}
              open={open}
              onClick={() => setOpen((value) => !value)}
            >
              <Dropdown.Menu>
                {children.map((child, key) => (
                  <Dropdown.Item
                    key={key}
                    as={Link}
                    to={child.path}
                    active={location.pathname === child.path}
                  >
                    {child.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Menu.Item
              key={`page_${index}`}
              name={route.title}
              active={
                route.path !== "/"
                  ? location.pathname.includes(route.path ?? "")
                  : location.pathname === route.path
              }
              as={Link}
              to={route.path}
              onClick={() => {
                if (setClose) {
                  setClose();
                }
              }}
            />
          );
        })}
      </Menu>

      <div className={styles.profile}>
        <div className={styles.content}>
          {me && (
            <div className={styles.user}>
              <strong>
                {me.firstName} {me.lastName}
              </strong>
              <span>{Lang.roles[me.userTypeId as AccessRole]}</span>
            </div>
          )}
          <a
            className={styles.logout}
            role="button"
            onClick={() => actions.logoutPOST()}
          >
            <strong>Logout</strong>
          </a>
        </div>
      </div>
    </Sidebar>
  );
}

export default Navigation;

import { ReactNode, useCallback, useEffect, useState } from "react";
import styles from "./private.module.less";
import Navigation from "../../organisms/navigation/navigation.component";
import { Dimmer, Icon, Loader, Sidebar } from "semantic-ui-react";
import { useUserContext } from "src/contexts/user.context";
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import { AccessRole, AccessType, CookieKeys } from "src/constants";
import { getUserRole, hasAccess } from "src/helpers/access.helper";
import useAuth from "src/hooks/auth.hook";
import Logo from "src/components/atoms/logo/logo.component";
import { getServiceStatus } from "src/selectors/status.selector";
import { userActionTypes } from "src/ducks/user.duck";
import { getCookie, removeCookie } from "src/helpers/cookie.helper";

type IProps = {
  content: ReactNode;
  permission?: AccessType | AccessType[];
  redirect?: (redirect: NavigateFunction, role: AccessRole) => void;
};

function PrivateTemplate({ content, permission, redirect }: IProps) {
  useAuth();
  const navigate = useNavigate();
  const { state, actions } = useUserContext();
  const [ready, setReady] = useState(false);
  const [active, setActive] = useState(false);
  const logoutStatus = getServiceStatus(state, userActionTypes.USER_LOGOUT);

  const fetchUser = useCallback(async () => {
    if (!state.me) {
      const result = await actions.meGET();

      if (!result.payload) {
        removeCookie(CookieKeys.UserToken);

        navigate("/auth", {
          state: {
            pathname: window.location.pathname,
            search: window.location.search,
          },
          replace: true,
        });
      }
    }

    setReady(true);
  }, [setReady, actions, state.me, navigate]);

  useEffect(() => {
    if (getCookie(CookieKeys.UserToken)) {
      fetchUser();
    }
  }, [fetchUser]);

  if (!ready) {
    return <Loader active />;
  }

  if (permission && !hasAccess(permission)) {
    const role = getUserRole();

    if (role && redirect) {
      redirect(navigate, role);
    } else {
      navigate("/404", {
        replace: true,
      });
    }
  }

  return (
    <div>
      <Dimmer active={logoutStatus.fetching}>
        <Loader
          content="Please wait, while we're logging you out."
          active={logoutStatus.fetching}
        />
      </Dimmer>
      <Sidebar.Pushable className={styles.wrapper}>
        <Navigation isOpen={active} setClose={() => setActive(false)} />

        <Sidebar.Pusher className={styles.pusher}>
          <div className={styles.main}>
            <div className={styles.header}>
              <Icon
                name="bars"
                size="big"
                onClick={() => setActive((value) => !value)}
              />
              <Logo />
            </div>
            <main className={styles.content} onClick={() => setActive(false)}>
              {content}
              <Outlet />
            </main>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
}

export default PrivateTemplate;

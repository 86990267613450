import { Fragment, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Dimmer,
  Header,
  Icon,
  List,
  Loader,
  Segment,
} from "semantic-ui-react";
import { useDivisionContext } from "src/contexts/division.context";
import DivisionAdd from "../division-add/division-add.component";
import DistrictAdd from "../../district/district-add/district-add.component";
import DivisionDelete from "../division-delete/division-delete.component";
import styles from "./division-list.module.less";
import DivisionEdit from "../division-edit/division-edit.component";
import DistrictDelete from "../../district/district-delete/district-delete.component";
import DistrictEdit from "../../district/district-edit/district-edit.component";
import LodgeAdd from "../../lodge/lodge-add/lodge-add.component";
import LodgeDelete from "../../lodge/lodge-delete/lodge-delete.component";
import LodgeEdit from "../../lodge/lodge-edit/lodge-edit.component";
import Lang from "src/libraries/languages";
import { getLodgeName } from "src/helpers/lodge.helper";
import LodgeDetail from "../../lodge/lodge-detail/lodge-detail.component";
import { getDivisionName } from "src/helpers/division.helper";
import { getDistrictName } from "src/helpers/district.helper";
import { useUserContext } from "src/contexts/user.context";
import { AccessRole } from "src/constants";
import { Link } from "react-router-dom";
import { getUrlString } from "src/helpers/url.helper";

function DivisionList() {
  const [isOpen, setIsOpen] = useState<Record<string, boolean>>({});
  const {
    state: { me },
  } = useUserContext();
  const { state, actions } = useDivisionContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(state.list.length === 0);
      await actions.listGET();
      setLoading(false);
    };

    fetch();
  }, [actions, setLoading]);

  return (
    <div>
      <Header as="h1">Division List</Header>

      <List horizontal className={styles.list}>
        <List.Item>
          <DivisionAdd
            trigger={<Button primary icon="add" content="Add Division" />}
          />
        </List.Item>
        <List.Item>
          <DistrictAdd
            trigger={<Button primary icon="add" content="Add District" />}
          />
        </List.Item>
        <List.Item>
          <LodgeAdd
            trigger={<Button primary icon="add" content="Add Lodge" />}
          />
        </List.Item>
      </List>

      {state.list.length === 0 ? (
        <Segment textAlign="center">
          <Dimmer active={loading} inverted>
            <Loader active={loading} />
          </Dimmer>
          {Lang.MSG_NO_DATA}
        </Segment>
      ) : (
        <Accordion styled fluid>
          {state.list.map((division) => (
            <>
              <Accordion.Title
                onClick={() =>
                  setIsOpen((values) => ({
                    ...values,
                    [`division_${division.divisionId}`]: !(
                      values[`division_${division.divisionId}`] === true
                    ),
                  }))
                }
                active={
                  isOpen[`division_${division.divisionId}`] ||
                  ([
                    AccessRole.DivisionAdmin,
                    AccessRole.DistrictAdmin,
                  ].includes(me?.userTypeId as AccessRole) &&
                    me?.divisionId === division.divisionId)
                }
              >
                {division.districts.length > 0 && <Icon name="dropdown" />}
                {getDivisionName(division)}

                <div className={styles.actions}>
                  <Link
                    to={getUrlString("/events", {
                      filters: [
                        {
                          name: "divisionId",
                          value: division.divisionId,
                        },
                      ],
                    })}
                  >
                    <Icon name="calendar alternate outline" />
                  </Link>
                  <DivisionEdit
                    data={division}
                    trigger={<Icon name="edit outline" />}
                  />
                  <DivisionDelete
                    data={division}
                    trigger={<Icon name="trash alternate outline" />}
                  />
                </div>
              </Accordion.Title>

              {division.districts.length > 0 && (
                <Accordion.Content
                  active={
                    isOpen[`division_${division.divisionId}`] ||
                    ([
                      AccessRole.DivisionAdmin,
                      AccessRole.DistrictAdmin,
                    ].includes(me?.userTypeId as AccessRole) &&
                      me?.divisionId === division.divisionId)
                  }
                >
                  <Accordion styled fluid>
                    {division.districts.map((district, key) => (
                      <Fragment key={key}>
                        <Accordion.Title
                          onClick={() =>
                            setIsOpen((values) => ({
                              ...values,
                              [`district_${district.districtId}`]: !(
                                values[`district_${district.districtId}`] ===
                                true
                              ),
                            }))
                          }
                          active={
                            isOpen[`district_${district.districtId}`] ||
                            (me?.userTypeId === AccessRole.DistrictAdmin &&
                              me?.districtId === district.districtId)
                          }
                        >
                          {district.lodges.length > 0 && (
                            <Icon name="dropdown" />
                          )}
                          {getDistrictName(district)}

                          <div className={styles.actions}>
                            <Link
                              to={getUrlString("/events", {
                                filters: [
                                  {
                                    name: "divisionId",
                                    value: division.divisionId,
                                  },
                                  {
                                    name: "districtId",
                                    value: district.districtId,
                                  },
                                ],
                              })}
                            >
                              <Icon name="calendar alternate outline" />
                            </Link>
                            <DistrictEdit
                              data={district}
                              trigger={<Icon name="edit outline" />}
                            />
                            <DistrictDelete
                              data={district}
                              trigger={<Icon name="trash alternate outline" />}
                            />
                          </div>
                        </Accordion.Title>

                        {district.lodges.length > 0 && (
                          <Accordion.Content
                            active={
                              isOpen[`district_${district.districtId}`] ||
                              (me?.userTypeId === AccessRole.DistrictAdmin &&
                                me?.districtId === district.districtId)
                            }
                          >
                            <Accordion>
                              {district.lodges.map((lodge) => (
                                <Accordion.Title key={lodge.lodgeId}>
                                  <LodgeDetail
                                    lodge={lodge}
                                    division={division}
                                    district={district}
                                    trigger={<span>{getLodgeName(lodge)}</span>}
                                  />

                                  <div className={styles.actions}>
                                    <LodgeEdit
                                      data={lodge}
                                      trigger={<Icon name="edit outline" />}
                                    />
                                    <LodgeDelete
                                      data={lodge}
                                      trigger={
                                        <Icon name="trash alternate outline" />
                                      }
                                    />
                                  </div>
                                </Accordion.Title>
                              ))}
                            </Accordion>
                          </Accordion.Content>
                        )}
                      </Fragment>
                    ))}
                  </Accordion>
                </Accordion.Content>
              )}
            </>
          ))}
        </Accordion>
      )}
    </div>
  );
}

export default DivisionList;

import { Button, Modal } from "semantic-ui-react";
import UserForm from "../user-form/user-form.component";
import { ReactNode, useCallback, useRef, useState } from "react";
import { useUserContext } from "src/contexts/user.context";
import { IUser, IUserCreate } from "src/models/user.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { userActionTypes } from "src/ducks/user.duck";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";
import { useUserAction } from "src/hooks/auth.hook";

type IProps = {
  data: IUser;
  trigger: ReactNode;
};

function UserEdit({ data, trigger }: IProps) {
  const { hasEdit } = useUserAction();
  const { state, actions } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const status = getServiceStatus(state, userActionTypes.USER_DATA_UPDATE);

  const handleSubmit = useCallback(
    async (formData: IUserCreate) => {
      if (data) {
        const result = await actions.updatePUT(data?.userId, formData);

        if (result) {
          ToastSuccess("User updated successfully.");
          actions.listGET();
          setIsOpen(false);
        }
      }
    },
    [actions, data, setIsOpen],
  );

  return (
    <Access type={AccessType.USER_UPDATE} when={hasEdit(data)}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>{Lang.TTL_USER_EDIT}</Modal.Header>
        <Modal.Content>
          <UserForm
            defaultValues={data}
            formRef={formRef}
            onSubmit={handleSubmit}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={status.fetching}
            disabled={status.fetching}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default UserEdit;

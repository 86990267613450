import { privateClient } from "src/libraries/http.library";
import { SuccessPayloadSchema } from "src/models";
import {
  IDivisionForm,
  DivisionListPayloadSchema,
  DivisionPayloadSchema,
} from "src/models/division.model";

const client = privateClient();
const endpoint = "division";

const divisionServices = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, DivisionPayloadSchema);
  },

  listGET: async () => {
    return client.get(`/${endpoint}`, {}, DivisionListPayloadSchema);
  },

  createPOST: async (data: IDivisionForm) => {
    return client.post(`/${endpoint}`, data, DivisionPayloadSchema);
  },

  updatePUT: async (id: number, data: IDivisionForm) => {
    return client.put(`/${endpoint}/${id}`, data, DivisionPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, SuccessPayloadSchema);
  },
};

export default divisionServices;

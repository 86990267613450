import Cookies, { CookieAttributes } from "js-cookie";
import { CookieKeys } from "src/constants";

export const setCookie = (
  key: CookieKeys,
  value: any,
  options?: CookieAttributes
) => {
  try {
    Cookies.set(
      key,
      JSON.stringify({
        value,
      }),
      options
    );
  } catch (e) {
    console.error(e);
  }
};

export const getCookie = <T extends any>(
  key: CookieKeys,
  defaultValue?: T
): T => {
  try {
    const data = Cookies.get(key);

    if (data) {
      const parsedData = JSON.parse(data);

      return parsedData.value;
    }
  } catch (e) {
    /* empty */
  }

  return defaultValue as T;
};

export const removeCookie = (key: CookieKeys) => {
  Cookies.remove(key);
};

import React, { PropsWithChildren, createContext, useContext } from "react";
import useReducerHook from "src/hooks/reducer.hook";
import DistrictReducer, {
  IDistrictState,
  defaultState,
  duckActions,
} from "src/ducks/district.duck";

const useReducer = (state = {}) => {
  return useReducerHook(
    DistrictReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions,
  );
};

type IDistrictContext = ReturnType<typeof useReducer>;

const DistrictContext = createContext<Partial<IDistrictContext>>({
  state: defaultState,
}) as React.Context<IDistrictContext>;

type IProps = {
  state?: Partial<IDistrictState>;
};

const DistrictProvider: React.FC<PropsWithChildren<IProps>> = ({
  children,
  state,
}) => {
  const reducer = useReducer(state);

  return (
    <DistrictContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </DistrictContext.Provider>
  );
};

const useDistrictContext = () => useContext(DistrictContext);

export type IUseDistrictContext = ReturnType<typeof useDistrictContext>;

export { DistrictContext, DistrictProvider, useDistrictContext };

import { useEffect } from "react";
import { Select } from "semantic-ui-react";
import { IDataTableToolbar } from "src/components/organisms/datatable/datatable.component";
import { useDivisionContext } from "src/contexts/division.context";
import { divisionActionTypes } from "src/ducks/division.duck";
import { getDistrictName } from "src/helpers/district.helper";
import { getDivisionName } from "src/helpers/division.helper";
import { getLodgeName } from "src/helpers/lodge.helper";
import Lang from "src/libraries/languages";
import { IDistrict } from "src/models/district.model";
import { IDivision } from "src/models/division.model";
import { getServiceStatus } from "src/selectors/status.selector";

export const useTableToolbar = ({
  hasDistrict = true,
  hasLodge = true,
} = {}) => {
  const { state: divisionState, actions: divisionActions } =
    useDivisionContext();
  const status = getServiceStatus(
    divisionState,
    divisionActionTypes.DIVISION_LIST_READ,
  );
  useEffect(() => {
    divisionActions.listGET();
  }, [divisionActions]);

  const toolbars: IDataTableToolbar = {
    filterBy: <span>{Lang.LBL_FILTER_BY}:</span>,
    divisionId: ({ value, setValue, setState }) => {
      return (
        <Select
          placeholder={Lang.LBL_SELECT_DIVISION}
          options={divisionState.list.map((value) => ({
            key: value.divisionId,
            value: value.divisionId,
            text: getDivisionName(value),
          }))}
          clearable
          search
          value={Number(value) || ""}
          selectOnBlur={false}
          onChange={(_, { value }) => {
            const exist = divisionState.list.find(
              (v) => v.divisionId === value,
            );

            if (!exist) {
              setState((values) => ({
                ...values,
                filters: [],
              }));
            } else {
              setValue(value);
            }

            return value;
          }}
          loading={!divisionState.list.length && status.fetching}
        />
      );
    },
  };

  if (hasDistrict) {
    toolbars.districtId = ({ value, setValue, setState, state }) => {
      let division: IDivision | undefined;
      const divisionId = state.filters.find((v) => v.name === "divisionId")
        ?.value;

      if (divisionId) {
        division = divisionState.list.find(
          (v) => v.divisionId === Number(divisionId),
        );
      }

      return (
        <Select
          key={`${division?.divisionId}`}
          placeholder={Lang.LBL_SELECT_DISTRICT}
          options={(division?.districts ?? []).map((value) => ({
            key: value.districtId,
            value: value.districtId,
            text: getDistrictName(value),
          }))}
          clearable
          search
          value={Number(value) || ""}
          selectOnBlur={false}
          onChange={(_, { value }) => {
            const exist = (division?.districts ?? []).find(
              (v) => v.districtId === value,
            );

            if (!exist) {
              setState((values) => ({
                ...values,
                filters: values.filters?.filter(
                  (v) => !["districtId", "lodgeId"].includes(v.name),
                ),
              }));
            } else {
              setValue(value);
            }

            return value;
          }}
          loading={!divisionState.list.length && status.fetching}
        />
      );
    };
  }

  if (hasLodge) {
    toolbars.lodgeId = ({ value, setValue, state }) => {
      let district: IDistrict | undefined;
      const districtId = state.filters.find((v) => v.name === "districtId")
        ?.value;

      if (districtId) {
        const divisionId = state.filters.find((v) => v.name === "divisionId")
          ?.value;

        const division = divisionState.list.find(
          (v) => v.divisionId === Number(divisionId),
        );

        if (division) {
          district = division.districts.find(
            (v) => v.districtId === Number(districtId),
          );
        }
      }

      return (
        <Select
          key={district?.districtId}
          placeholder={Lang.LBL_SELECT_LODGE}
          options={(district?.lodges ?? []).map((value) => ({
            key: value.lodgeId,
            value: value.lodgeId,
            text: getLodgeName(value),
          }))}
          clearable
          search
          value={Number(value) || ""}
          selectOnBlur={false}
          onChange={(_, data) => setValue(data.value)}
          loading={!divisionState.list.length && status.fetching}
        />
      );
    };
  }

  return toolbars;
};

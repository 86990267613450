import { useCallback, useEffect, useState } from "react";
import { Button, Header, Icon } from "semantic-ui-react";
import DataTable, {
  IDataTableColumn,
  IDataTableState,
} from "src/components/organisms/datatable/datatable.component";
import { useEventContext } from "src/contexts/event.context";
import { IEvent } from "src/models/event.model";
import EventAdd from "../event-add/event-add.component";
import EventDelete from "../event-delete/event-delete.component";
import { getDate } from "src/helpers/datetime.helper";
import { getLodgeName } from "src/helpers/lodge.helper";
import { useUserContext } from "src/contexts/user.context";
import { Link } from "react-router-dom";
import { useTableToolbar } from "src/hooks/table-filter.hook";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";
import { useEventAccess } from "src/hooks/event.hook";
import TableSearch from "src/components/molecules/table-search/table-search.component";

function EventList() {
  const {
    state: { list: stateList },
    actions,
  } = useEventContext();
  const {
    state: { me },
  } = useUserContext();
  const toolbar = useTableToolbar();
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [list, setList] = useState<IEvent[]>([]);
  const [loading, setLoading] = useState(false);
  const { hasEdit } = useEventAccess();

  const columns: IDataTableColumn<IEvent>[] = [
    {
      index: "startDate",
      title: "Date",
      render: (value) => getDate(value),
    },
    {
      index: "name",
      title: "Name",
      render: (_, values) => (
        <Link to={`/events/${values.eventId}`}>{values.name}</Link>
      ),
    },
    {
      index: "ritualName",
      title: "Ritual Type",
    },
    {
      index: "lodgeName",
      title: "Lodge",
      render: (_, values) =>
        getLodgeName({
          lodgeNo: values.lodgeNo,
          name: values.lodgeName,
        }),
    },
    {
      index: "action",
      title: "Actions",
      sortable: false,
      render: (_, values) => (
        <div className="action-buttons">
          <Access type={AccessType.EVENT_UPDATE} when={hasEdit(values)}>
            <Link to={`/events/${values.eventId}/edit`}>
              <Icon name="edit outline" />
            </Link>
          </Access>

          <EventDelete
            data={values}
            trigger={<Icon link name="trash alternate outline" />}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetch = async () => {
      setLoading(stateList.length === 0);
      await actions.listGET();
      setLoading(false);
    };

    fetch();
  }, [me, actions, setLoading]);

  useEffect(() => {
    if (Object.values(filters).length) {
      let list = stateList;

      if (filters.keyword?.trim().length) {
        list = list.filter((v) =>
          `${v.name} ${getDate(v.startDate)} ${v.ritualName} ${getLodgeName({
            lodgeNo: v.lodgeNo,
            name: v.lodgeName,
          })}`
            .toLowerCase()
            .includes(filters.keyword.toLowerCase()),
        );
      }

      if (filters.divisionId) {
        list = list.filter((v) => v.lodge.divisionId == filters.divisionId);
      }

      if (filters.districtId) {
        list = list.filter((v) => v.lodge.districtId == filters.districtId);
      }

      if (filters.lodgeId) {
        list = list.filter((v) => v.lodge.lodgeId == filters.lodgeId);
      }

      setList(list);
    } else {
      setList(stateList);
    }
  }, [stateList, filters, setList]);

  const handleFilter = useCallback(
    (params: IDataTableState) => {
      setFilters(
        params.filters.reduce((items, item) => {
          if (item.value) {
            return { ...items, [item.name]: item.value };
          }

          return items;
        }, {}),
      );
    },
    [setFilters],
  );

  return (
    <div>
      <Header as="h1">Event List</Header>

      <DataTable
        columns={columns}
        data={list}
        loading={loading}
        onChange={handleFilter}
        sortable
        history
        toolbars={{
          ...toolbar,
          keyword: (params) => <TableSearch {...params} />,
          add: () => (
            <EventAdd
              trigger={<Button primary icon="add" content="Add Event" />}
            />
          ),
        }}
      />
    </div>
  );
}

export default EventList;

export default {
  MSG_VALIDATION_ERROR_REQUIRED: "This field is required.",
  MSG_HTTP_ERROR_BAD_REQUEST: "Bad Request.",
  MSG_HTTP_ERROR_REQUEST_CANCELLED: "Cancelled Request.",
  MSG_HTTP_ERROR_CANNOT_CONNECT: "Cannot connect to server.",

  MSG_FORGOT_PASSWORD:
    "Please enter your e-mail address to receive a link to reset your password.",

  MSG_LOGIN_SUCCESS: "You have successfully logged-in.",

  MSG_NO_DATA: "No data found.",
  MSG_NO_NOTES: "No Notes found.",
  MSG_NO_ATTENDEES: "No Attendees.",
  MSG_CONFIRM_DELETE: "Are you sure you want to delete?",

  MSG_ATTENDANCE_UPDATE_SUCCESS: "Attendance was successfully updated",
};

import { ReactNode, useEffect, useState } from "react";
import { Button, Loader, Modal, Table } from "semantic-ui-react";
import { useEventContext } from "src/contexts/event.context";
import { eventActionTypes } from "src/ducks/event.duck";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import EventDetail from "../event-detail/event-detail.component";

type IProps = {
  memberId?: number;
  lodgeId?: number;
  divisionId?: number;
  districtId?: number;
  ritualId?: number;
  positionId?: number;
  trigger?: ReactNode;
};

function EventAssociation({
  lodgeId,
  memberId,
  divisionId,
  districtId,
  ritualId,
  positionId,
  trigger = <a>See Events</a>,
}: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { state, actions } = useEventContext();
  const status = getServiceStatus(
    state,
    eventActionTypes.EVENT_ASSOCIATION_LIST_READ,
  );

  useEffect(() => {
    if (isOpen) {
      const filters = [];

      if (lodgeId) {
        filters.push({
          name: "lodgeId",
          value: lodgeId,
        });
      }

      if (memberId) {
        filters.push({
          name: "memberId",
          value: memberId,
        });
      }

      if (districtId) {
        filters.push({
          name: "districtId",
          value: districtId,
        });
      }

      if (divisionId) {
        filters.push({
          name: "divisionId",
          value: divisionId,
        });
      }

      if (ritualId) {
        filters.push({
          name: "ritualId",
          value: ritualId,
        });
      }

      if (positionId) {
        filters.push({
          name: "positionId",
          value: positionId,
        });
      }

      actions.associationListGET({
        filters,
      });
    }
  }, [
    isOpen,
    lodgeId,
    memberId,
    districtId,
    divisionId,
    ritualId,
    positionId,
    actions,
  ]);

  return (
    <Modal
      open={isOpen}
      trigger={trigger}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      size="small"
    >
      <Modal.Header>Events</Modal.Header>
      <Modal.Content className="loader" scrolling>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!status.fetching && state.associationList.length > 0 ? (
              state.associationList.map((value) => (
                <Table.Row key={value.eventId}>
                  <Table.Cell>
                    <EventDetail
                      id={value.eventId}
                      trigger={<a>{value.name}</a>}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell textAlign="center">
                  {status.fetching ? (
                    <Loader active inline />
                  ) : (
                    Lang.MSG_NO_DATA
                  )}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={() => setIsOpen(false)}>
          {Lang.LBL_CLOSE}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EventAssociation;

import { ICommonState, IReducerAction } from "src/libraries/thunk.library";
import { IUser } from "src/models/user.model";
import { removeCookie, setCookie } from "src/helpers/cookie.helper";
import { AccessRole, CookieKeys } from "src/constants";
import { setUserRole } from "src/helpers/access.helper";
import userServices from "src/services/user.service";

export const userActionTypes = {
  USER_ME_READ: "USER_ME_READ",
  USER_DATA_READ: "USER_DATA_READ",
  USER_LIST_READ: "USER_LIST_READ",
  USER_LOGIN: "USER_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",
  USER_DATA_CREATE: "USER_DATA_CREATE",
  USER_DATA_UPDATE: "USER_DATA_UPDATE",
  USER_FORGOT_PASSWORD: "USER_FORGOT_PASSWORD",
  USER_CHANGE_PASSWORD: "USER_CHANGE_PASSWORD",
  USER_DATA_DELETE: "USER_DATA_DELETE",
  USER_TOKEN_REFRESH: "USER_TOKEN_REFRESH",
} as const;

export const duckActions = {
  meGET: {
    type: userActionTypes.USER_ME_READ,
    service: userServices.meGET,
  },

  dataGET: {
    type: userActionTypes.USER_DATA_READ,
    service: userServices.dataGET,
  },

  listGET: {
    type: userActionTypes.USER_LIST_READ,
    service: userServices.listGET,
  },

  loginPOST: {
    type: userActionTypes.USER_LOGIN,
    service: userServices.loginPOST,
  },

  passwordRecoveryPOST: {
    type: userActionTypes.USER_FORGOT_PASSWORD,
    service: userServices.passwordRecoveryPOST,
  },

  changePasswordPOST: {
    type: userActionTypes.USER_CHANGE_PASSWORD,
    service: userServices.changePasswordPOST,
  },

  createPOST: {
    type: userActionTypes.USER_DATA_CREATE,
    service: userServices.createPOST,
  },

  updatePUT: {
    type: userActionTypes.USER_DATA_UPDATE,
    service: userServices.updatePUT,
  },

  logoutPOST: {
    type: userActionTypes.USER_LOGOUT,
    service: userServices.logoutPOST,
  },

  dataDELETE: {
    type: userActionTypes.USER_DATA_DELETE,
    service: userServices.dataDELETE,
  },

  refreshTokenPOST: {
    type: userActionTypes.USER_TOKEN_REFRESH,
    service: userServices.refreshTokenPOST,
  },
};

export type IUserAsync = typeof duckActions;

export interface IUserState extends ICommonState<typeof userActionTypes> {
  me?: IUser;
  data?: IUser;
  list: IUser[];
  total: number;
}

export const defaultState: IUserState = {
  status: {},
  list: [],
  total: 0,
};

const UserReducer = (
  state: IUserState,
  action: IReducerAction<IUserAsync>,
): IUserState => {
  switch (action.type) {
    case userActionTypes.USER_ME_READ: {
      if (action.payload) {
        setUserRole(action.payload?.userTypeId as AccessRole);

        return {
          ...state,
          me: action.payload,
        };
      }

      return state;
    }

    case userActionTypes.USER_LOGIN: {
      if (action.payload) {
        setUserRole(action.payload?.data.userTypeId as AccessRole);
        const {
          data: me,
          authentication: { token, expiry },
        } = action.payload;
        setCookie(CookieKeys.UserToken, token, {
          expires: expiry,
        });

        return {
          ...state,
          me,
        };
      }

      return state;
    }

    case userActionTypes.USER_LOGOUT: {
      setUserRole(undefined);
      removeCookie(CookieKeys.UserToken);

      return {
        ...state,
        me: undefined,
      };
    }

    case userActionTypes.USER_DATA_READ: {
      if (action.payload) {
        return {
          ...state,
          data: action.payload,
        };
      }

      return state;
    }

    case userActionTypes.USER_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case userActionTypes.USER_DATA_CREATE: {
      if (action.payload) {
        return {
          ...state,
          data: action.payload,
          list: [action.payload, ...state.list],
          total: ++state.total,
        };
      }

      return state;
    }

    case userActionTypes.USER_DATA_UPDATE: {
      if (action.payload) {
        const newState = {
          ...state,
          data: action.payload,
        };

        if (state.me?.userId === action.payload.userId) {
          const { userTypeId } = action.payload;
          setUserRole(userTypeId);

          newState.me = {
            ...newState.me,
            ...action.payload,
          };
        }

        newState.list = state.list.map((v) =>
          v.userId === action.payload?.userId ? action.payload : v,
        );

        return newState;
      }

      return state;
    }

    case userActionTypes.USER_DATA_DELETE: {
      return {
        ...state,
        data: undefined,
        list: state.list.filter((value) => value.userId !== action.params?.[0]),
        total: --state.total,
      };
    }

    default: {
      return state;
    }
  }
};

export default UserReducer;

import { useCallback, useEffect } from "react";
import { AccessRole } from "src/constants";
import { useLodgeContext } from "src/contexts/lodge.context";
import { useUserContext } from "src/contexts/user.context";
import { IEvent } from "src/models/event.model";

let fetched = false;

export const useEventAccess = (event?: IEvent) => {
  const {
    state: { me },
  } = useUserContext();
  const {
    state: { list: lodgeList },
    actions,
  } = useLodgeContext();

  useEffect(() => {
    if (!lodgeList.length && !fetched) {
      fetched = true;
      actions.listGET();
    }
  }, [actions]);

  const hasEdit = useCallback(
    (data = event) => {
      if (!me) {
        return false;
      }

      const lodge = lodgeList.find((v) => data && v.lodgeId === data.lodgeId);

      if (
        [AccessRole.Admin, AccessRole.JurisdictionAdmin].includes(me.userTypeId)
      ) {
        return true;
      }

      if (me.userTypeId === AccessRole.DivisionAdmin) {
        return lodge?.divisionId === me.divisionId;
      } else if (me.userTypeId === AccessRole.DistrictAdmin) {
        return lodge?.districtId === me.districtId;
      } else if (me.userTypeId === AccessRole.LodgeAdmin) {
        return lodge?.lodgeId === me.lodgeId;
      }

      return false;
    },
    [me, lodgeList, event],
  );

  const hasDelete = useCallback(
    (data = event) => {
      if (!me) {
        return false;
      }

      const lodge = lodgeList.find((v) => data && v.lodgeId === data.lodgeId);

      if (
        [AccessRole.Admin, AccessRole.JurisdictionAdmin].includes(me.userTypeId)
      ) {
        return true;
      }

      if (me.userTypeId === AccessRole.DivisionAdmin) {
        return lodge?.divisionId === me.divisionId;
      } else if (me.userTypeId === AccessRole.DistrictAdmin) {
        return lodge?.districtId === me.districtId;
      } else if (me.userTypeId === AccessRole.LodgeAdmin) {
        return lodge?.lodgeId === me.lodgeId;
      }

      return false;
    },
    [me, lodgeList, event],
  );

  return { hasEdit, hasDelete };
};

import { ReactNode, useCallback, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import { useDistrictContext } from "src/contexts/district.context";
import { useDivisionContext } from "src/contexts/division.context";
import Lang from "src/libraries/languages";
import { IDistrict } from "src/models/district.model";

type IProps = {
  data: IDistrict;
  trigger: ReactNode;
};

function DistrictDelete({ data, trigger }: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { actions } = useDistrictContext();
  const [loading, setLoading] = useState(false);
  const { actions: divisionActions } = useDivisionContext();

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    const result = await actions.dataDELETE(data.districtId);

    if (result.payload) {
      await divisionActions.listGET();

      ToastSuccess(result.payload.message);
      setIsOpen(false);
    }

    setLoading(false);
  }, [data, actions, setIsOpen, divisionActions, setLoading]);

  return (
    <Access type={AccessType.DISTRICT_DELETE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        size="tiny"
      >
        <Modal.Header>{Lang.TTL_CONFIRM_DELETE}</Modal.Header>
        <Modal.Content>
          Are you sure you want to delete <strong>{`${data.name}`}</strong> as a
          district?
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            type="button"
            negative
            onClick={handleConfirm}
            loading={loading}
            disabled={loading}
          >
            {Lang.LBL_CONFIRM}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default DistrictDelete;

import { ReactNode, useCallback, useRef, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useMemberContext } from "src/contexts/member.context";
import { IMember, IMemberForm } from "src/models/member.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { memberActionTypes } from "src/ducks/member.duck";
import MemberForm from "../member-form/member-form.component";

type IProps = {
  trigger: ReactNode;
  defaultValues?: Partial<IMember>;
};

function MemberAdd({ defaultValues, trigger }: IProps) {
  const { state, actions } = useMemberContext();
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const status = getServiceStatus(state, memberActionTypes.MEMBER_DATA_CREATE);

  const handleSubmit = useCallback(
    async (formData: IMemberForm) => {
      const result = await actions.createPOST(formData);

      if (result.payload) {
        ToastSuccess("Member added successfully.");
        actions.listGET();
        setIsOpen(false);
      }
    },
    [actions, setIsOpen],
  );
  return (
    <Modal
      open={isOpen}
      trigger={trigger}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      closeOnDimmerClick={false}
      size="tiny"
    >
      <Modal.Header>Add Member</Modal.Header>
      <Modal.Content>
        <MemberForm
          defaultValues={defaultValues}
          formRef={formRef}
          onSubmit={handleSubmit}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={() => setIsOpen(false)}>
          {Lang.LBL_CANCEL}
        </Button>
        <Button
          primary
          type="button"
          onClick={() => formRef.current?.requestSubmit()}
          loading={status.fetching}
          disabled={status.fetching}
        >
          {Lang.LBL_SAVE}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default MemberAdd;

import { Grid } from "semantic-ui-react";
import FieldReadOnly from "src/components/atoms/field-readonly/field-readonly.component";
import { getDate, getTime } from "src/helpers/datetime.helper";
import { getLodgeName } from "src/helpers/lodge.helper";
import { IEvent } from "src/models/event.model";

type IProps = {
  data: IEvent;
};

function EventDetailInfo({ data }: IProps) {
  return (
    <Grid columns={3} stackable doubling>
      <Grid.Row columns={1}>
        <Grid.Column>
          <FieldReadOnly label="Name" value={data.name} compact />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={1}>
        <Grid.Column>
          <FieldReadOnly
            label="Lodge"
            value={getLodgeName({
              name: data.lodgeName,
              lodgeNo: data.lodgeNo,
            })}
            compact
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={1}>
        <Grid.Column>
          <FieldReadOnly label="Address" value={data.address} compact />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={1}>
        <Grid.Column>
          <FieldReadOnly label="Ritual Type" value={data.ritualName} compact />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <FieldReadOnly
            label="Date"
            value={getDate(data.startDate)}
            block
            compact
          />
        </Grid.Column>
        <Grid.Column>
          <FieldReadOnly
            label="Open Time"
            value={getTime(data.startDate)}
            block
            compact
          />
        </Grid.Column>
        <Grid.Column>
          <FieldReadOnly
            label="Close Time"
            value={getTime(data.endDate)}
            block
            compact
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default EventDetailInfo;

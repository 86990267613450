import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Form } from "semantic-ui-react";
import Access from "src/components/atoms/access/access.component";
import { AccessRole, AccessType } from "src/constants";
import { useDivisionContext } from "src/contexts/division.context";
import { divisionActionTypes } from "src/ducks/division.duck";
import { getUserRole, getUserTypeList } from "src/helpers/access.helper";
import { getDistrictName } from "src/helpers/district.helper";
import { getDivisionName } from "src/helpers/division.helper";
import { getLodgeName } from "src/helpers/lodge.helper";
import { useValidator } from "src/hooks/validator.hook";
import Lang from "src/libraries/languages";
import { IDistrict } from "src/models/district.model";
import { IDivision } from "src/models/division.model";
import { IUser, IUserCreate, UserCreateSchema } from "src/models/user.model";
import { getServiceStatus } from "src/selectors/status.selector";

type IProps = {
  defaultValues?: Partial<IUser>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (formData: IUserCreate) => Promise<void>;
};

function UserForm({ defaultValues, formRef, onSubmit }: IProps) {
  const { control, handleSubmit, resetField } = useValidator(UserCreateSchema, {
    defaultValues: defaultValues as IUserCreate,
  });
  const [division, setDivision] = useState<IDivision>();
  const [district, setDistrict] = useState<IDistrict>();
  const userTypeId = getUserRole();

  const { state: divisionState, actions: divisionActions } =
    useDivisionContext();
  const divisionStatus = getServiceStatus(
    divisionState,
    divisionActionTypes.DIVISION_LIST_READ,
  );

  useEffect(() => {
    divisionActions.listGET();
  }, [divisionActions]);

  useEffect(() => {
    if (defaultValues) {
      const value = divisionState.list.find(
        (v) => v.divisionId === defaultValues.divisionId,
      );
      setDivision(value);

      if (value && defaultValues.districtId) {
        setDistrict(
          (value?.districts ?? []).find(
            (v) => v.districtId === defaultValues.districtId,
          ),
        );
      }
    }
  }, [divisionState.list, defaultValues, setDivision, setDistrict]);

  return (
    <Form
      ref={formRef}
      data-testid="form"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
    >
      <Controller
        name="firstName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input
            {...field}
            placeholder={Lang.LBL_FIRST_NAME}
            error={error?.message}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input
            {...field}
            placeholder={Lang.LBL_LAST_NAME}
            error={error?.message}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input
            {...field}
            placeholder={Lang.LBL_EMAIL}
            type="email"
            error={error?.message}
          />
        )}
      />

      <Controller
        name="userTypeId"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Select
            {...field}
            placeholder={Lang.LBL_USER_TYPE}
            options={getUserTypeList().map((value) => ({
              key: value,
              value: Number(value),
              text: (Lang.roles as any)[value],
            }))}
            error={error?.message}
            onChange={(_, { value }) => {
              resetField("userTypeId", {
                defaultValue: Number(value),
              });

              return value;
            }}
            search
            selectOnBlur={false}
          />
        )}
      />

      <Controller
        name="divisionId"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Select
            {...(field as any)}
            placeholder={Lang.LBL_DIVISION}
            options={divisionState.list.map((value) => ({
              key: value.divisionId,
              value: value.divisionId,
              text: getDivisionName(value),
            }))}
            error={error?.message}
            onChange={(_, { value }) => {
              resetField("divisionId", {
                defaultValue: Number(value) || null,
              });

              if (!value) {
                setDivision(undefined);
                setDistrict(undefined);
              } else {
                setDivision(
                  divisionState.list.find((v) => v.divisionId === value),
                );
              }

              resetField("districtId", {
                defaultValue: null,
              });
              resetField("lodgeId", {
                defaultValue: null,
              });

              return value;
            }}
            clearable
            search
            selectOnBlur={false}
            loading={divisionStatus.fetching}
            disabled={
              !!defaultValues &&
              [
                AccessRole.DivisionAdmin,
                AccessRole.DistrictAdmin,
                AccessRole.LodgeAdmin,
              ].includes(userTypeId as AccessRole)
            }
          />
        )}
      />

      <Controller
        name="districtId"
        control={control}
        render={({ field: { value, ...field }, fieldState: { error } }) => (
          <Form.Select
            {...field}
            placeholder={Lang.LBL_DISTRICT}
            value={value as number}
            options={(division?.districts ?? []).map((value) => ({
              key: value.districtId,
              value: value.districtId,
              text: getDistrictName(value),
            }))}
            error={error?.message}
            onChange={(_, { value }) => {
              resetField("districtId", {
                defaultValue: Number(value) || null,
              });
              setDistrict(
                (division?.districts ?? []).find((v) => v.districtId === value),
              );
              resetField("lodgeId", {
                defaultValue: null,
              });

              return value;
            }}
            clearable
            search
            selectOnBlur={false}
            loading={divisionStatus.fetching}
            disabled={
              !!defaultValues &&
              [AccessRole.DistrictAdmin, AccessRole.LodgeAdmin].includes(
                userTypeId as AccessRole,
              )
            }
          />
        )}
      />

      <Access type={AccessType.USER_LODGE}>
        <Controller
          name="lodgeId"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Select
                {...(field as any)}
                placeholder={Lang.LBL_LODGE}
                options={(district?.lodges ?? []).map((value) => ({
                  key: value.lodgeId,
                  value: Number(value.lodgeId),
                  text: getLodgeName(value),
                }))}
                error={error?.message}
                onChange={(_, { value }) => {
                  resetField("lodgeId", {
                    defaultValue: Number(value) || null,
                  });

                  return value;
                }}
                clearable
                search
                selectOnBlur={false}
                loading={divisionStatus.fetching}
              />
            );
          }}
        />
      </Access>
    </Form>
  );
}

export default UserForm;

import { useState } from "react";
import { Controller } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { useDivisionContext } from "src/contexts/division.context";
import { divisionActionTypes } from "src/ducks/division.duck";
import { getDistrictName } from "src/helpers/district.helper";
import { getDivisionName } from "src/helpers/division.helper";
import { useValidator } from "src/hooks/validator.hook";
import Lang from "src/libraries/languages";
import { ILodge, ILodgeForm, LodgeFormSchema } from "src/models/lodge.model";
import { getServiceStatus } from "src/selectors/status.selector";

type IProps = {
  defaultValues?: Partial<ILodge>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (formData: ILodgeForm) => Promise<void>;
};

function LodgeForm({ defaultValues, formRef, onSubmit }: IProps) {
  const [divisionId, setDivisionId] = useState<number | undefined>(
    defaultValues?.divisionId,
  );
  const { state } = useDivisionContext();
  const { control, handleSubmit } = useValidator(LodgeFormSchema, {
    defaultValues: defaultValues as ILodgeForm,
  });

  const divisionStatus = getServiceStatus(
    state,
    divisionActionTypes.DIVISION_LIST_READ,
  );

  return (
    <Form
      ref={formRef}
      data-testid="form"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
    >
      <Controller
        name="divisionId"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Select
            {...field}
            options={state.list.map((v) => ({
              key: v.divisionId,
              value: v.divisionId,
              text: getDivisionName(v),
            }))}
            search
            placeholder={Lang.LBL_DIVISION}
            error={error?.message}
            onChange={(_, data) => {
              field.onChange(data.value ? Number(data.value) : undefined);
              setDivisionId(Number(data.value));

              return data.value;
            }}
            clearable
            loading={!state.list.length && divisionStatus.fetching}
          />
        )}
      />

      <Controller
        key={divisionId}
        name="districtId"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Select
            {...field}
            options={(
              state.list.find((v) => v.divisionId === divisionId)?.districts ||
              []
            ).map((v) => ({
              key: v.districtId,
              value: v.districtId,
              text: getDistrictName(v),
            }))}
            search
            placeholder={Lang.LBL_DISTRICT}
            error={error?.message}
            onChange={(_, data) => {
              field.onChange(data.value ? Number(data.value) : undefined);

              return data.value;
            }}
            clearable
            loading={!state.list.length && divisionStatus.fetching}
          />
        )}
      />

      <Controller
        name="name"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input {...field} placeholder="Name" error={error?.message} />
        )}
      />

      <Controller
        name="lodgeNo"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input
            {...field}
            placeholder={Lang.LBL_CONTACT_NUMBER}
            error={error?.message}
            type="number"
          />
        )}
      />

      <Controller
        name="address"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input
            {...field}
            placeholder={Lang.LBL_ADDRESS}
            error={error?.message}
          />
        )}
      />
    </Form>
  );
}

export default LodgeForm;

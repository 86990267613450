import React, { PropsWithChildren, createContext, useContext } from "react";
import useReducerHook from "src/hooks/reducer.hook";
import MemberReducer, {
  IMemberState,
  defaultState,
  duckActions,
} from "src/ducks/member.duck";

const useReducer = (state = {}) => {
  return useReducerHook(
    MemberReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions,
  );
};

type IMemberContext = ReturnType<typeof useReducer>;

const MemberContext = createContext<Partial<IMemberContext>>({
  state: defaultState,
}) as React.Context<IMemberContext>;

type IProps = {
  state?: Partial<IMemberState>;
};

const MemberProvider: React.FC<PropsWithChildren<IProps>> = ({
  children,
  state,
}) => {
  const reducer = useReducer(state);

  return (
    <MemberContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

const useMemberContext = () => useContext(MemberContext);

export type IUseMemberContext = ReturnType<typeof useMemberContext>;

export { MemberContext, MemberProvider, useMemberContext };

import { ICommonState, IReducerAction } from "src/libraries/thunk.library";
import lodgeServices from "src/services/lodge.service";
import { ILodge } from "src/models/lodge.model";

export const lodgeActionTypes = {
  LODGE_DATA_READ: "LODGE_DATA_READ",
  LODGE_LIST_READ: "LODGE_LIST_READ",
  LODGE_DATA_CREATE: "LODGE_DATA_CREATE",
  LODGE_DATA_UPDATE: "LODGE_DATA_UPDATE",
  LODGE_DATA_DELETE: "LODGE_DATA_DELETE",
  LODGE_DATA_SET: "LODGE_DATA_SET",
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: lodgeActionTypes.LODGE_DATA_READ,
    service: lodgeServices.dataGET,
  },

  listGET: {
    type: lodgeActionTypes.LODGE_LIST_READ,
    service: lodgeServices.listGET,
  },

  createPOST: {
    type: lodgeActionTypes.LODGE_DATA_CREATE,
    service: lodgeServices.createPOST,
  },

  updatePUT: {
    type: lodgeActionTypes.LODGE_DATA_UPDATE,
    service: lodgeServices.updatePUT,
  },

  dataDELETE: {
    type: lodgeActionTypes.LODGE_DATA_DELETE,
    service: lodgeServices.dataDELETE,
  },

  // This is a sync action
  setData: (data: ILodge) => ({
    type: lodgeActionTypes.LODGE_DATA_SET,
    payload: {
      data,
    },
  }),
};

export type ILodgeAsync = typeof duckActions;

export interface ILodgeState extends ICommonState<typeof lodgeActionTypes> {
  data?: ILodge;
  list: ILodge[];
  total: number;
}

export const defaultState: ILodgeState = {
  status: {},
  list: [],
  total: 0,
};

const LodgeReducer = (
  state: ILodgeState,
  action: IReducerAction<ILodgeAsync>,
): ILodgeState => {
  switch (action.type) {
    case lodgeActionTypes.LODGE_DATA_SET:
    case lodgeActionTypes.LODGE_DATA_READ:
    case lodgeActionTypes.LODGE_DATA_UPDATE:
    case lodgeActionTypes.LODGE_DATA_CREATE: {
      return {
        ...state,
        data: action.payload?.data,
      };
    }

    case lodgeActionTypes.LODGE_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case lodgeActionTypes.LODGE_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.lodgeId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default LodgeReducer;

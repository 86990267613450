import { ICommonState, IReducerAction } from "src/libraries/thunk.library";
import memberServices from "src/services/member.service";
import { IMember, IMemberDetail } from "src/models/member.model";
import { sortBy } from "lodash";

export const memberActionTypes = {
  MEMBER_DATA_READ: "MEMBER_DATA_READ",
  MEMBER_LIST_READ: "MEMBER_LIST_READ",
  MEMBER_DATA_CREATE: "MEMBER_DATA_CREATE",
  MEMBER_DATA_UPDATE: "MEMBER_DATA_UPDATE",
  MEMBER_DATA_DELETE: "MEMBER_DATA_DELETE",
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: memberActionTypes.MEMBER_DATA_READ,
    service: memberServices.dataGET,
  },

  listGET: {
    type: memberActionTypes.MEMBER_LIST_READ,
    service: memberServices.listGET,
  },

  createPOST: {
    type: memberActionTypes.MEMBER_DATA_CREATE,
    service: memberServices.createPOST,
  },

  updatePUT: {
    type: memberActionTypes.MEMBER_DATA_UPDATE,
    service: memberServices.updatePUT,
  },

  dataDELETE: {
    type: memberActionTypes.MEMBER_DATA_DELETE,
    service: memberServices.dataDELETE,
  },
};

export type IMemberAsync = typeof duckActions;

export interface IMemberState extends ICommonState<typeof memberActionTypes> {
  data?: IMember;
  detail?: IMemberDetail;
  list: IMember[];
  total: number;
}

export const defaultState: IMemberState = {
  status: {},
  list: [],
  total: 0,
};

const MemberReducer = (
  state: IMemberState,
  action: IReducerAction<IMemberAsync>,
): IMemberState => {
  switch (action.type) {
    case memberActionTypes.MEMBER_DATA_READ: {
      return {
        ...state,
        detail: action.payload,
      };
    }

    case memberActionTypes.MEMBER_DATA_CREATE: {
      if (action.payload?.data) {
        const { data } = action.payload;
        return {
          ...state,
          data: data,
          list: sortBy([data, ...state.list], ["lastName"]),
        };
      }

      return state;
    }

    case memberActionTypes.MEMBER_DATA_UPDATE: {
      if (action.payload?.data) {
        const { data } = action.payload;
        const list = state.list.filter((v) => v.memberId !== data.memberId);

        return {
          ...state,
          data: data,
          list: sortBy([data, ...list], ["lastName"]),
        };
      }

      return state;
    }

    case memberActionTypes.MEMBER_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case memberActionTypes.MEMBER_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.memberId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default MemberReducer;

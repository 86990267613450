import { useCallback, useEffect, useState } from "react";
import { Button, Header, Icon, Input } from "semantic-ui-react";
import DataTable, {
  IDataTableColumn,
  IDataTableState,
} from "src/components/organisms/datatable/datatable.component";
import { useRitualContext } from "src/contexts/ritual.context";
import { IRitual } from "src/models/ritual.model";
import RitualAdd from "../ritual-add/ritual-add.component";
import RitualEdit from "../ritual-edit/ritual-edit.component";
import RitualDelete from "../ritual-delete/ritual-delete.component";
import Lang from "src/libraries/languages";
import RitualDetail from "../ritual-detail/ritual-detail.component";

const columns: IDataTableColumn<IRitual>[] = [
  {
    index: "name",
    title: "Name",
    render: (value, values) => (
      <RitualDetail data={values} trigger={<a>{value}</a>} />
    ),
  },
  {
    index: "action",
    title: "Actions",
    sortable: false,
    render: (_, values) => (
      <div className="action-buttons">
        <RitualEdit data={values} trigger={<Icon link name="edit outline" />} />
        <RitualDelete
          data={values}
          trigger={<Icon link name="trash alternate outline" />}
        />
      </div>
    ),
  },
];

function RitualList() {
  const {
    state: { list: stateList },
    actions,
  } = useRitualContext();
  const [keyword, setKeyword] = useState<string>("");
  const [list, setList] = useState<IRitual[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(stateList.length === 0);
      await actions.listGET();
      setLoading(false);
    };

    fetch();
  }, [actions, setLoading]);

  useEffect(() => {
    if (keyword) {
      setList(
        stateList.filter((v) =>
          v.name.toLowerCase().includes(keyword.toLowerCase()),
        ),
      );
    } else {
      setList(stateList);
    }
  }, [stateList, keyword, setList]);

  const handleFilter = useCallback(
    (params: IDataTableState) => {
      const filter = params.filters.find((v) => v.name === "filter");

      if (filter) {
        setKeyword(filter.value);
      }
    },
    [setKeyword],
  );

  return (
    <div>
      <Header as="h1">Ritual List</Header>

      <DataTable
        columns={columns}
        data={list}
        loading={loading}
        sortable
        onChange={handleFilter}
        toolbars={{
          filter: ({ value, setValue }) => (
            <Input
              placeholder={Lang.LBL_SEARCH}
              value={value}
              onChange={(_, data) => setValue(data.value)}
              icon={
                value ? (
                  <Icon name="close" link onClick={() => setValue("")} />
                ) : undefined
              }
            />
          ),
          add: () => (
            <RitualAdd
              trigger={<Button primary icon="add" content="Add Ritual" />}
            />
          ),
        }}
      />
    </div>
  );
}

export default RitualList;

import { AccessPermission, AccessRole, AccessType } from "src/constants";

let userRole: AccessRole | undefined = undefined;

export const setUserRole = (role?: AccessRole) => {
  userRole = role;
};

export const getUserRole = () => userRole;

export const hasAccess = (
  type: AccessType | AccessType[],
  when: (() => boolean) | boolean = true,
) => {
  const typeList = Array.isArray(type) ? type : [type];
  const role = getUserRole();

  if (role) {
    const list = AccessPermission[role];

    const isValid = typeof when === "function" ? when() : when;
    if (typeList.some((value) => list.includes(value)) && isValid) {
      return true;
    }
  }

  return false;
};

export const hasRole = (roles: AccessRole | AccessRole[]) => {
  const role = getUserRole();

  if (role) {
    return (Array.isArray(roles) ? roles : [roles]).includes(role);
  }

  return false;
};

export const getUserTypeList = () => {
  const role = getUserRole();

  if (role) {
    switch (role) {
      case AccessRole.JurisdictionAdmin:
      case AccessRole.Admin: {
        return Object.keys(AccessRole).filter(Number);
      }

      case AccessRole.DivisionAdmin: {
        return [
          AccessRole.DivisionAdmin,
          AccessRole.DistrictAdmin,
          AccessRole.LodgeAdmin,
        ];
      }

      case AccessRole.DistrictAdmin: {
        return [AccessRole.DistrictAdmin, AccessRole.LodgeAdmin];
      }
    }
  }

  return [];
};

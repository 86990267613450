import { useCallback, useEffect, useState } from "react";
import { Button, Header, Icon, List } from "semantic-ui-react";
import DataTable, {
  IDataTableColumn,
  IDataTableState,
} from "src/components/organisms/datatable/datatable.component";
import { useMemberContext } from "src/contexts/member.context";
import { IMember } from "src/models/member.model";
import MemberAdd from "../member-add/member-add.component";
import MemberEdit from "../member-edit/member-edit.component";
import MemberDelete from "../member-delete/member-delete.component";
import Lang from "src/libraries/languages";
import { Link } from "react-router-dom";
import { getLodgeName } from "src/helpers/lodge.helper";
import { getDistrictName } from "src/helpers/district.helper";
import { useTableToolbar } from "src/hooks/table-filter.hook";
import TableSearch from "src/components/molecules/table-search/table-search.component";

const columns: IDataTableColumn<IMember>[] = [
  {
    index: "memberNo",
    title: Lang.LBL_MEMBER_NUMBER,
    render: (value, values) => (
      <Link to={`/members/${values.memberId}`}>{value}</Link>
    ),
  },
  {
    index: "lastName",
    title: Lang.LBL_LAST_NAME,
  },
  {
    index: "firstName",
    title: Lang.LBL_FIRST_NAME,
  },
  {
    index: "lodge",
    title: "Lodges",
    render: (_, value) => (
      <List>
        {value.lodges.map((v, i) => (
          <List.Item key={`${i}_${v.lodgeId}`}>{`${getDistrictName(
            v.district,
          )} - ${getLodgeName(v)}`}</List.Item>
        ))}
      </List>
    ),
  },
  {
    index: "action",
    title: "Actions",
    sortable: false,
    render: (_, values) => (
      <div className="action-buttons">
        <MemberEdit data={values} trigger={<Icon link name="edit outline" />} />
        <MemberDelete
          data={values}
          trigger={<Icon link name="trash alternate outline" />}
        />
      </div>
    ),
  },
];

function MemberList() {
  const {
    state: { list: stateList },
    actions,
  } = useMemberContext();

  const toolbar = useTableToolbar();
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [list, setList] = useState<IMember[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(stateList.length === 0);
      await actions.listGET();
      setLoading(false);
    };

    fetch();
  }, [actions, setLoading]);

  useEffect(() => {
    if (Object.values(filters).length) {
      let list = stateList;

      if (filters.keyword?.trim().length) {
        list = list.filter((v) =>
          `${v.memberNo} ${v.firstName} ${v.lastName}`
            .toLowerCase()
            .includes(filters.keyword.toLowerCase()),
        );
      }

      if (filters.divisionId) {
        list = list.filter((v) =>
          v.lodges.map((v) => v.divisionId).includes(filters.divisionId),
        );
      }

      if (filters.districtId) {
        list = list.filter((v) =>
          v.lodges.map((v) => v.districtId).includes(filters.districtId),
        );
      }

      if (filters.lodgeId) {
        list = list.filter((v) =>
          v.lodges.map((v) => v.lodgeId).includes(filters.lodgeId),
        );
      }

      setList(list);
    } else {
      setList(stateList);
    }
  }, [stateList, filters, setList]);

  const handleFilter = useCallback(
    (params: IDataTableState) => {
      setFilters(
        params.filters.reduce((items, item) => {
          if (item.value) {
            return { ...items, [item.name]: item.value };
          }

          return items;
        }, {}),
      );
    },
    [setFilters],
  );

  return (
    <div>
      <Header as="h1">{Lang.TTL_MEMBER_LIST}</Header>

      <DataTable
        columns={columns}
        data={list}
        loading={loading}
        sortable
        onChange={handleFilter}
        toolbars={{
          ...toolbar,

          keyword: (props) => <TableSearch {...props} />,
          add: () => (
            <MemberAdd
              trigger={<Button primary icon="add" content="Add Member" />}
            />
          ),
        }}
      />
    </div>
  );
}

export default MemberList;

import { IDataTableState } from "src/components/organisms/datatable/datatable.component";
import { privateClient } from "src/libraries/http.library";
import { SuccessPayloadSchema } from "src/models";
import {
  IDistrictForm,
  DistrictListPayloadSchema,
  DistrictPayloadSchema,
} from "src/models/district.model";

const client = privateClient();
const endpoint = "district";

const districtServices = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, DistrictPayloadSchema);
  },

  listGET: async (params: Partial<IDataTableState> = {}) => {
    return client.get(`/${endpoint}`, params, DistrictListPayloadSchema);
  },

  createPOST: async (data: IDistrictForm) => {
    return client.post(`/${endpoint}`, data, DistrictPayloadSchema);
  },

  updatePUT: async (id: number, data: IDistrictForm) => {
    return client.put(`/${endpoint}/${id}`, data, DistrictPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, SuccessPayloadSchema);
  },
};

export default districtServices;

import { ReactNode, useCallback, useEffect, useState } from "react";
import { Button, Icon, Loader, Modal, Tab } from "semantic-ui-react";
import { useEventContext } from "src/contexts/event.context";
import { eventActionTypes } from "src/ducks/event.duck";
import { getServiceStatus } from "src/selectors/status.selector";
import styles from "./event-detail.module.less";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Lang from "src/libraries/languages";
import { IEvent } from "src/models/event.model";
import EventDetailInfo from "./event-detail-info.component";
import NoteSegment from "src/components/organisms/note-segment/note-segment.component";
import EventAttendance from "../event-attendance/event-attendance.component";
import classNames from "classnames";
import EventEdit from "../event-edit/event-edit.component";
import { useEventAccess } from "src/hooks/event.hook";

type IProps = {
  id?: number;
  data?: IEvent;
  trigger?: ReactNode;
};

function EventDetail({ id: eventId, data: defaultData, trigger }: IProps) {
  const { state, actions } = useEventContext();
  const status = getServiceStatus(state, eventActionTypes.EVENT_DATA_READ);
  const location = useLocation();
  const navigate = useNavigate();
  const { hasEdit } = useEventAccess();
  const [isOpen, setIsOpen] = useState(!eventId);
  const params = useParams();
  const id = eventId || Number(params.id);
  const data = defaultData || state.data;

  const handleOpen = useCallback(
    (value: boolean) => {
      if (eventId) {
        setIsOpen(value);
      } else if (!value) {
        navigate(-1);
      }
    },
    [eventId, setIsOpen, navigate],
  );

  useEffect(() => {
    if (id && isOpen) {
      actions.dataGET(id);
    }
  }, [id, isOpen]);

  return (
    <Modal
      open={isOpen}
      trigger={trigger}
      onOpen={() => handleOpen(true)}
      onClose={() => handleOpen(false)}
      closeIcon
      size="tiny"
      className={classNames(styles.modalContent, styles.tab)}
    >
      <Modal.Content className="loader">
        {!data || (data && id && data?.eventId !== id && status.fetching) ? (
          <Loader active />
        ) : (
          <Tab
            panes={[
              {
                menuItem: "Details",
                render: () => (
                  <Tab.Pane>
                    <div className="modal-content-scrolling">
                      <div className={styles.tabHeader}>
                        {data && (
                          <div className={styles.actions}>
                            {hasEdit(state.data) &&
                            location.pathname.includes("event") ? (
                              <Link
                                to={`/events/${
                                  id ?? defaultData?.eventId
                                }/edit`}
                              >
                                <Icon name="edit" />
                              </Link>
                            ) : (
                              <EventEdit
                                data={data}
                                trigger={
                                  <a>
                                    <Icon name="edit" />
                                  </a>
                                }
                              />
                            )}

                            <Link
                              to={`/events/${id ?? defaultData?.eventId}/print`}
                              target="_blank"
                            >
                              <Icon name="print" />
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className={styles.wrapper}>
                        <EventDetailInfo data={data} />

                        <ul className={styles.list}>
                          {data.sections.map((value) => (
                            <li key={value.eventSectionId}>
                              <strong>{value.name}</strong>
                              <ul className={styles.subList}>
                                {value.positions.map((val) => {
                                  return (
                                    <li key={val.positionId}>
                                      <div className={styles.position}>
                                        <span>{val.positionName}</span>

                                        {val.firstName ? (
                                          <Link
                                            to={`/members/${val.memberId}`}
                                          >{`${val.firstName} ${val.lastName}`}</Link>
                                        ) : (
                                          <strong>-</strong>
                                        )}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                ),
              },
              {
                menuItem: "Notes",
                render: () =>
                  state.data ? (
                    <Tab.Pane>
                      <NoteSegment isView eventId={state.data.eventId} />
                    </Tab.Pane>
                  ) : null,
              },
              {
                menuItem: "Attendance",
                render: () =>
                  state.data ? (
                    <Tab.Pane>
                      <EventAttendance isView data={state.data} />
                    </Tab.Pane>
                  ) : null,
              },
            ]}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={() => handleOpen(false)}>
          {Lang.LBL_CLOSE}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EventDetail;

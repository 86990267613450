import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FieldValues, UseFormProps } from "react-hook-form";
import { ObjectSchema } from "yup";

export const useValidator = <TFieldValues extends FieldValues>(
  schema: ObjectSchema<TFieldValues>,
  props: Partial<UseFormProps<TFieldValues>> = {},
) => {
  return useForm({
    ...props,
    resolver: yupResolver(schema),
    mode: "onChange",
  });
};

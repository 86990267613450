import { ICommonState, IReducerAction } from "src/libraries/thunk.library";
import divisionServices from "src/services/division.service";
import { IDivision } from "src/models/division.model";
import { sortDivisionData } from "src/helpers/division.helper";

export const divisionActionTypes = {
  DIVISION_DATA_READ: "DIVISION_DATA_READ",
  DIVISION_LIST_READ: "DIVISION_LIST_READ",
  DIVISION_DATA_CREATE: "DIVISION_DATA_CREATE",
  DIVISION_DATA_UPDATE: "DIVISION_DATA_UPDATE",
  DIVISION_DATA_DELETE: "DIVISION_DATA_DELETE",
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: divisionActionTypes.DIVISION_DATA_READ,
    service: divisionServices.dataGET,
  },

  listGET: {
    type: divisionActionTypes.DIVISION_LIST_READ,
    service: divisionServices.listGET,
  },

  createPOST: {
    type: divisionActionTypes.DIVISION_DATA_CREATE,
    service: divisionServices.createPOST,
  },

  updatePUT: {
    type: divisionActionTypes.DIVISION_DATA_UPDATE,
    service: divisionServices.updatePUT,
  },

  dataDELETE: {
    type: divisionActionTypes.DIVISION_DATA_DELETE,
    service: divisionServices.dataDELETE,
  },
};

export type IDivisionAsync = typeof duckActions;

export interface IDivisionState
  extends ICommonState<typeof divisionActionTypes> {
  data?: IDivision;
  list: IDivision[];
  total: number;
}

export const defaultState: IDivisionState = {
  status: {},
  list: [],
  total: 0,
};

const DivisionReducer = (
  state: IDivisionState,
  action: IReducerAction<IDivisionAsync>,
): IDivisionState => {
  switch (action.type) {
    case divisionActionTypes.DIVISION_DATA_READ:
    case divisionActionTypes.DIVISION_DATA_UPDATE:
    case divisionActionTypes.DIVISION_DATA_CREATE: {
      return {
        ...state,
        data: action.payload?.data,
      };
    }

    case divisionActionTypes.DIVISION_LIST_READ: {
      return {
        ...state,
        list: sortDivisionData(action.payload?.rows ?? []),
        total: action.payload?.count ?? 0,
      };
    }

    case divisionActionTypes.DIVISION_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.divisionId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default DivisionReducer;

import LocalizedStrings from "react-localization";
import English from "./en";

const Lang = new LocalizedStrings({
  en: {
    ...English,
  } as const,
} as const);

export default Lang;

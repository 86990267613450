import { getFullName } from "src/helpers/member.helper";
import styles from "./event-attendance-detail.module.less";
import { IEventAttendee } from "src/models/event.model";
import Lang from "src/libraries/languages";
import classNames from "classnames";

type IProps = {
  data: IEventAttendee[];
};

function EventAttendanceDetail({ data = [] }: IProps) {
  return (
    <div className={classNames(styles.wrapper, "modal-content-scrolling")}>
      <ul className={styles.members}>
        {data.length > 0 ? (
          data.map((val, index) => (
            <li key={val.eventAttendeeId}>
              <label>{index + 1}:</label>
              <div>{getFullName(val.member)}</div>
            </li>
          ))
        ) : (
          <li>
            <div>{Lang.MSG_NO_DATA}</div>
          </li>
        )}
      </ul>
    </div>
  );
}

export default EventAttendanceDetail;

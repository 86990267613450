import { RouteObject } from "react-router-dom";
import PrivateTemplate from "./components/templates/private/private.component";
import PublicTemplate from "./components/templates/public/public.component";
import Login from "./components/pages/login/login.component";
import PasswordForgot from "./components/pages/password-forgot/password-forgot.component";
import PasswordChange from "./components/pages/password-change/password-change.component";
import UserList from "./components/pages/user/user-list/user-list.component";
import { AccessRole, AccessType } from "./constants";
import Error404 from "./components/pages/error/error-404/error-404.component";
import PositionList from "./components/pages/position/position-list/position-list.component";
import RitualList from "./components/pages/ritual/ritual-list/ritual-list.component";
import DivisionList from "./components/pages/division/division-list/division-list.component";
import MemberList from "./components/pages/member/member-list/member-list.component";
import EventList from "./components/pages/event/event-list/event-list.component";
import MemberDetail from "./components/pages/member/member-detail/member-detail.component";
import ReportPosition from "./components/pages/report/report-position/report-position.component";
import EventPrint from "./components/pages/event/event-print/event-print.component";
import EventDetail from "./components/pages/event/event-detail/event-detail.component";
import EventEdit from "./components/pages/event/event-edit/event-edit.component";

export type IRoute = Omit<RouteObject, "children"> & {
  title?: string;
  permission?: AccessType[];
  children?: IRoute[];
};

const routes: IRoute[] = [
  {
    path: "/",
    element: (
      <PrivateTemplate
        permission={AccessType.USER_READ}
        redirect={(redirect, role: AccessRole) =>
          redirect(
            [AccessRole.Admin, AccessRole.JurisdictionAdmin].includes(role)
              ? "/"
              : "/divisions",
            {
              replace: true,
            },
          )
        }
        content={<UserList />}
      />
    ),
    title: "Users",
    permission: [AccessType.USER_READ],
  },
  {
    path: "/positions",
    element: <PrivateTemplate content={<PositionList />} />,
    title: "Positions",
  },
  {
    path: "/rituals",
    element: <PrivateTemplate content={<RitualList />} />,
    title: "Rituals",
  },
  {
    path: "/divisions",
    element: <PrivateTemplate content={<DivisionList />} />,
    title: "Divisions",
  },
  {
    path: "/members/:id",
    element: <PrivateTemplate content={<MemberDetail />} />,
  },
  {
    path: "/members",
    element: <PrivateTemplate content={<MemberList />} />,
    title: "Members",
  },
  {
    path: "/events",
    element: <PrivateTemplate content={<EventList />} />,
    title: "Events",
    children: [
      {
        path: ":id/edit",
        element: <EventEdit />,
      },
      {
        path: ":id",
        element: <EventDetail />,
      },
    ],
  },

  {
    path: "/events/:id/print",
    element: <EventPrint />,
  },
  {
    title: "Reports",
    children: [
      {
        path: "/reports/position",
        element: <PrivateTemplate content={<ReportPosition />} />,
        title: "Position",
      },
    ],
  },
  {
    path: "/auth/forgot",
    element: <PublicTemplate content={<PasswordForgot />} />,
  },
  {
    path: "/auth/change",
    element: <PublicTemplate content={<PasswordChange />} />,
  },
  {
    path: "/auth",
    element: <PublicTemplate content={<Login />} />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

export default routes;

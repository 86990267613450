import yup from "src/libraries/validator.library";
import { LodgeSchema, LodgeShape } from "./lodge.model";
import { EventSchema } from "./event.model";
import { DistrictShape } from "./district.model";
import { omit } from "lodash";

export const MemberSchema = yup.object({
  memberId: yup.number().required(),
  memberNo: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  lodges: yup
    .array()
    .of(
      yup.object({
        ...LodgeShape,
        lodgeId: yup.number().required(),
        district: yup.object(omit(DistrictShape, "lodges")),
      }),
    )
    .defined(),
  updatedAt: yup.date(),
  updatedBy: yup.number(),
  createdAt: yup.date(),
  createdBy: yup.number(),
});

export const MemberDetailSchema = yup.object({
  memberId: yup.number().required(),
  memberNo: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  lodges: yup.array().of(LodgeSchema).defined(),
  events: yup.array().of(EventSchema).defined(),
  conferrals: yup.array().of(EventSchema).defined(),
  updatedAt: yup.date(),
  updatedBy: yup.number(),
  createdAt: yup.date(),
  createdBy: yup.number(),
});

export const MemberFormSchema = yup.object({
  memberNo: yup.string().required(),
  firstName: yup.string().required().default("").max(255).trim(),
  lastName: yup.string().required().default("").max(255).trim(),
  lodges: yup
    .array()
    .of(yup.number().defined())
    .min(1, "Must select at least 1")
    .required(),
});

export const MemberListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(MemberSchema.clone()),
});

export const MemberPayloadSchema = yup.object({
  data: MemberSchema.clone(),
});

export type IMember = yup.Asserts<typeof MemberSchema>;
export type IMemberForm = yup.Asserts<typeof MemberFormSchema>;
export type IMemberDetail = yup.Asserts<typeof MemberDetailSchema>;

import { Button, Form } from "semantic-ui-react";
import styles from "./password-change.module.less";
import { useValidator } from "src/hooks/validator.hook";
import {
  IUserChangePassword,
  UserChangePasswordSchema,
} from "src/models/user.model";
import { useCallback } from "react";
import { useUserContext } from "src/contexts/user.context";
import { Controller } from "react-hook-form";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import { getServiceStatus } from "src/selectors/status.selector";
import { userActionTypes } from "src/ducks/user.duck";
import { useNavigate, useSearchParams } from "react-router-dom";
import Lang from "src/libraries/languages";
import Logo from "src/components/atoms/logo/logo.component";

function PasswordChange() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { state, actions } = useUserContext();
  const { control, handleSubmit } = useValidator(UserChangePasswordSchema, {
    defaultValues: {
      token: params.get("token") || "",
    },
  });
  const status = getServiceStatus(state, userActionTypes.USER_CHANGE_PASSWORD);

  const handleFormSubmit = useCallback(
    async (formData: IUserChangePassword) => {
      const result = await actions.changePasswordPOST(formData);

      if (result.payload) {
        ToastSuccess(result.payload.message);
        navigate("/auth", {
          replace: true,
        });
      }
    },
    [actions, navigate],
  );

  return (
    <div className={styles.wrapper}>
      <Logo size="huge" />
      <h2>{Lang.TTL_RESET_PASSWORD}</h2>

      <Form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Input
              {...field}
              placeholder={Lang.LBL_PASSWORD}
              type="password"
              error={error?.message}
            />
          )}
        />

        <Controller
          name="confirmPassword"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Input
              {...field}
              placeholder={Lang.LBL_PASSWORD_CONFIRM}
              type="password"
              error={error?.message}
            />
          )}
        />

        <Button
          secondary
          size="big"
          fluid
          disabled={status.fetching}
          loading={status.fetching}
        >
          {Lang.LBL_SAVE}
        </Button>
      </Form>
    </div>
  );
}

export default PasswordChange;

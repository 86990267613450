import classNames from "classnames";
import styles from "./field-readonly.module.less";

type IProps = {
  label: string;
  value: any;
  block?: boolean;
  compact?: boolean;
};

function FieldReadOnly({ label: title, value, block, compact }: IProps) {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.block]: block,
        [styles.compact]: compact,
      })}
    >
      <label>{title}</label>
      <span>{value}</span>
    </div>
  );
}

export default FieldReadOnly;

import { Button, Modal } from "semantic-ui-react";
import UserForm from "../user-form/user-form.component";
import { ReactNode, useCallback, useRef, useState } from "react";
import { useUserContext } from "src/contexts/user.context";
import { IUserCreate } from "src/models/user.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { userActionTypes } from "src/ducks/user.duck";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";

type IProps = {
  trigger: ReactNode;
};

function UserAdd({ trigger }: IProps) {
  const { state, actions } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const status = getServiceStatus(state, userActionTypes.USER_DATA_CREATE);

  const handleSubmit = useCallback(
    async (formData: IUserCreate) => {
      const result = await actions.createPOST(formData);

      if (result.payload) {
        ToastSuccess("User created successfully.");
        actions.listGET();
        setIsOpen(false);
      }
    },
    [actions, setIsOpen],
  );
  return (
    <Access type={AccessType.USER_CREATE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>{Lang.TTL_USER_ADD}</Modal.Header>
        <Modal.Content>
          <UserForm formRef={formRef} onSubmit={handleSubmit} />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={status.fetching}
            disabled={status.fetching}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default UserAdd;

import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { useLodgeContext } from "src/contexts/lodge.context";
import { lodgeActionTypes } from "src/ducks/lodge.duck";
import { getLodgeName } from "src/helpers/lodge.helper";
import { useMemberLodgeList } from "src/hooks/member.hook";
import { useValidator } from "src/hooks/validator.hook";
import Lang from "src/libraries/languages";
import {
  IMember,
  IMemberForm,
  MemberFormSchema,
} from "src/models/member.model";
import { getServiceStatus } from "src/selectors/status.selector";

type IProps = {
  defaultValues?: Partial<IMember>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (formData: IMemberForm) => Promise<void>;
};

function MemberForm({ defaultValues, formRef, onSubmit }: IProps) {
  const { state, actions } = useLodgeContext();
  const lodgeList = useMemberLodgeList();
  const { control, handleSubmit } = useValidator(MemberFormSchema, {
    defaultValues: {
      ...defaultValues,
      lodges: (defaultValues?.lodges ?? []).map((v) => v.lodgeId),
    } as IMemberForm,
  });
  const status = getServiceStatus(state, lodgeActionTypes.LODGE_LIST_READ);

  useEffect(() => {
    actions.listGET();
  }, [actions]);

  return (
    <Form
      ref={formRef}
      data-testid="form"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
    >
      <Controller
        name="memberNo"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input
            {...field}
            placeholder="Member ID"
            error={error?.message}
          />
        )}
      />

      <Controller
        name="firstName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input
            {...field}
            placeholder={Lang.LBL_FIRST_NAME}
            error={error?.message}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input
            {...field}
            placeholder={Lang.LBL_LAST_NAME}
            error={error?.message}
          />
        )}
      />

      <Controller
        name="lodges"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Select
            {...field}
            options={lodgeList.map((v) => ({
              key: v.lodgeId,
              value: v.lodgeId,
              text: getLodgeName(v),
            }))}
            search
            placeholder={Lang.LBL_LODGE}
            error={error?.message}
            onChange={(_, data) => {
              field.onChange(data.value ? data.value : undefined);

              return data.value;
            }}
            clearable
            multiple
            loading={!lodgeList.length && status.fetching}
          />
        )}
      />
    </Form>
  );
}

export default MemberForm;

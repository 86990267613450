import { Header } from "semantic-ui-react";
import styles from "./logo.module.less";
import classNames from "classnames";
import { Link } from "react-router-dom";

type IProps = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5";
  size?: "large" | "huge";
  className?: string;
};

function Logo({ as = "h1", className, size }: IProps) {
  return (
    <Header
      as={as}
      className={classNames(styles.wrapper, size && styles[size], className)}
    >
      <Link to="/" className={styles.link}>
        Tiler’s<span>Register</span>
      </Link>
    </Header>
  );
}

export default Logo;

import { Button } from "semantic-ui-react";
import styles from "../error.module.less";
import { Link } from "react-router-dom";
import useAuth from "src/hooks/auth.hook";

function Error404() {
  useAuth();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1>404</h1>
        <p>The page you requested was not found.</p>
        <Button as={Link} to="/" primary size="big">
          Home
        </Button>
      </div>
    </div>
  );
}

export default Error404;

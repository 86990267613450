import moment, { Moment } from "moment";

export const getDate = (date: Date | Moment | string) => {
  return moment(date).format("MM/DD/YYYY");
};

export const getTime = (date?: Date | Moment | string | null) => {
  return date ? moment(date).format("hh:mm a") : null;
};

export const getDateTimeFromNow = (date?: Date | Moment | string | null) => {
  return date ? moment(date).fromNow() : null;
};

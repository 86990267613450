import { ICommonState, IReducerAction } from "src/libraries/thunk.library";
import eventServices from "src/services/event.service";
import {
  IEvent,
  IEventAttendee,
  IEventMemberAttendance,
  IEventNote,
  IEventPositionReport,
} from "src/models/event.model";

export const eventActionTypes = {
  EVENT_DATA_READ: "EVENT_DATA_READ",
  EVENT_LIST_READ: "EVENT_LIST_READ",
  EVENT_DATA_CREATE: "EVENT_DATA_CREATE",
  EVENT_DATA_UPDATE: "EVENT_DATA_UPDATE",
  EVENT_DATA_DELETE: "EVENT_DATA_DELETE",
  EVENT_DATA_SET: "EVENT_DATA_SET",
  EVENT_MEMBER_LIST_READ: "EVENT_MEMBER_LIST_READ",

  EVENT_NOTE_LIST_READ: "EVENT_NOTE_LIST_READ",
  EVENT_NOTE_DATA_CREATE: "EVENT_NOTE_DATA_CREATE",
  EVENT_NOTE_DATA_UPDATE: "EVENT_NOTE_DATA_UPDATE",
  EVENT_NOTE_DATA_DELETE: "EVENT_NOTE_DATA_DELETE",

  EVENT_ATTENDANCE_CREATE: "EVENT_ATTENDANCE_CREATE",
  EVENT_ATTENDANCE_READ: "EVENT_ATTENDANCE_READ",

  EVENT_ASSOCIATION_LIST_READ: "EVENT_ASSOCIATION_LIST_READ",
  EVENT_MEMBER_ATTENDANCE_LIST_READ: "EVENT_MEMBER_ATTENDANCE_LIST_READ",
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: eventActionTypes.EVENT_DATA_READ,
    service: eventServices.dataGET,
  },

  listGET: {
    type: eventActionTypes.EVENT_LIST_READ,
    service: eventServices.listGET,
  },

  associationListGET: {
    type: eventActionTypes.EVENT_ASSOCIATION_LIST_READ,
    service: eventServices.listGET,
  },

  reportMemberGET: {
    type: eventActionTypes.EVENT_MEMBER_LIST_READ,
    service: eventServices.reportMemberGET,
  },

  createPOST: {
    type: eventActionTypes.EVENT_DATA_CREATE,
    service: eventServices.createPOST,
  },

  updatePUT: {
    type: eventActionTypes.EVENT_DATA_UPDATE,
    service: eventServices.updatePUT,
  },

  dataDELETE: {
    type: eventActionTypes.EVENT_DATA_DELETE,
    service: eventServices.dataDELETE,
  },

  noteListPOST: {
    type: eventActionTypes.EVENT_NOTE_LIST_READ,
    service: eventServices.noteListPOST,
  },

  notePOST: {
    type: eventActionTypes.EVENT_NOTE_DATA_CREATE,
    service: eventServices.notePOST,
  },

  notePUT: {
    type: eventActionTypes.EVENT_NOTE_DATA_UPDATE,
    service: eventServices.notePUT,
  },

  noteDELETE: {
    type: eventActionTypes.EVENT_NOTE_DATA_DELETE,
    service: eventServices.noteDELETE,
  },

  attendanceGET: {
    type: eventActionTypes.EVENT_ATTENDANCE_READ,
    service: eventServices.attendanceGET,
  },

  attendancePOST: {
    type: eventActionTypes.EVENT_ATTENDANCE_CREATE,
    service: eventServices.attendancePOST,
  },

  attendanceEventGET: {
    type: eventActionTypes.EVENT_MEMBER_ATTENDANCE_LIST_READ,
    service: eventServices.attendanceEventGET,
  },

  // This is a sync action
  setData: (data: IEvent) => ({
    type: eventActionTypes.EVENT_DATA_SET,
    payload: {
      data,
    },
  }),
};

export type IEventAsync = typeof duckActions;

export interface IEventState extends ICommonState<typeof eventActionTypes> {
  data?: IEvent;
  list: IEvent[];
  associationList: IEvent[];
  members: IEventPositionReport[];
  notes: Record<number, IEventNote[]>;
  total: number;
  attendees: Record<number, IEventAttendee[]>;
  memberEventList: IEventMemberAttendance[];
}

export const defaultState: IEventState = {
  status: {},
  list: [],
  associationList: [],
  members: [],
  total: 0,
  notes: {},
  attendees: {},
  memberEventList: [],
};

const EventReducer = (
  state: IEventState,
  action: IReducerAction<IEventAsync>,
): IEventState => {
  switch (action.type) {
    case eventActionTypes.EVENT_DATA_SET:
    case eventActionTypes.EVENT_DATA_READ:
    case eventActionTypes.EVENT_DATA_CREATE: {
      return {
        ...state,
        data: action.payload?.data,
      };
    }

    case eventActionTypes.EVENT_DATA_UPDATE: {
      if (action.payload?.data) {
        const list = [...state.list];
        const { data } = action.payload;
        const index = list.findIndex((v) => v.eventId === data.eventId);

        list.splice(index, 1, data);

        return {
          ...state,
          data: data,
          list,
        };
      }

      return state;
    }

    case eventActionTypes.EVENT_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case eventActionTypes.EVENT_ASSOCIATION_LIST_READ: {
      return {
        ...state,
        associationList: action.payload?.rows ?? [],
      };
    }

    case eventActionTypes.EVENT_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.eventId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case eventActionTypes.EVENT_MEMBER_LIST_READ: {
      return {
        ...state,
        members: action.payload?.rows ?? [],
      };
    }

    case eventActionTypes.EVENT_NOTE_LIST_READ: {
      if (action.params) {
        return {
          ...state,
          notes: {
            ...state.notes,
            [action.params[0]]: action.payload?.rows ?? [],
          },
        };
      }

      return state;
    }

    case eventActionTypes.EVENT_NOTE_DATA_UPDATE: {
      if (action.payload?.data) {
        const data = action.payload.data;

        return {
          ...state,
          notes: {
            ...state.notes,
            [data.eventId]: state.notes[data.eventId].map((v) =>
              v.eventNoteId === data.eventNoteId ? data : v,
            ),
          },
        };
      }

      return state;
    }

    case eventActionTypes.EVENT_NOTE_DATA_CREATE: {
      if (action.payload?.data) {
        return {
          ...state,
          notes: {
            ...state.notes,
            [action.payload.data.eventId]: [
              action.payload.data,
              ...state.notes[action.payload.data.eventId],
            ],
          },
        };
      }

      return state;
    }

    case eventActionTypes.EVENT_ATTENDANCE_CREATE:
    case eventActionTypes.EVENT_ATTENDANCE_READ: {
      if (action.payload?.rows && action.params?.length) {
        return {
          ...state,
          attendees: {
            ...state.attendees,
            [action.params[0]]: action.payload?.rows,
          },
        };
      }

      return state;
    }

    case eventActionTypes.EVENT_MEMBER_ATTENDANCE_LIST_READ: {
      return {
        ...state,
        memberEventList: action.payload?.rows ?? [],
      };
    }

    default: {
      return state;
    }
  }
};

export default EventReducer;

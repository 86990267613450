import {
  Button,
  Dimmer,
  Loader,
  Modal,
  Popup,
  Radio,
  Tab,
} from "semantic-ui-react";
import EventForm from "../event-form/event-form.component";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useEventContext } from "src/contexts/event.context";
import { IEvent, IEventForm } from "src/models/event.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { eventActionTypes } from "src/ducks/event.duck";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";
import { useEventAccess } from "src/hooks/event.hook";
import styles from "../event-form/event-form.module.less";
import { useLocation, useNavigate, useParams } from "react-router";
import NoteSegment from "src/components/organisms/note-segment/note-segment.component";
import classNames from "classnames";
import EventAttendance from "../event-attendance/event-attendance.component";

type IProps = {
  data?: IEvent;
  trigger?: ReactNode;
};

function EventEdit({ data, trigger }: IProps) {
  const params = useParams();
  const location = useLocation();
  const id = data?.eventId || Number(params.id);
  const { state, actions } = useEventContext();
  const { hasEdit } = useEventAccess();
  const [isOpen, setIsOpen] = useState(
    location.pathname.includes("events") && !!params.id,
  );
  const [loading, setLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const fetchStatus = getServiceStatus(state, eventActionTypes.EVENT_DATA_READ);
  const status = getServiceStatus(state, eventActionTypes.EVENT_DATA_UPDATE);
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    if (data) {
      setIsOpen(false);
    } else {
      navigate(-1);
    }
  }, [data, navigate]);

  const handleSubmit = useCallback(
    async (formData: IEventForm) => {
      if (state.data) {
        setLoading(true);
        const result = await actions.updatePUT(state.data?.eventId, formData);

        if (result.payload) {
          await actions.listGET();
          ToastSuccess("Event updated successfully.");
          handleClose();
        }

        setLoading(false);
      }
    },
    [actions, state.data, handleClose, setLoading],
  );

  useEffect(() => {
    const fetch = async () => {
      await actions.dataGET(id);
    };

    if (!data && id) {
      fetch();
    }
  }, [id, data]);

  if (params.id && state.data?.eventId !== id && fetchStatus.fetching) {
    return (
      <Dimmer active page>
        <Loader active />
      </Dimmer>
    );
  }

  return (
    <Access type={AccessType.EVENT_UPDATE} when={() => hasEdit(state.data)}>
      <Modal
        open={isOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
        size="tiny"
        className={classNames(styles.modalContent, styles.tab)}
        onOpen={() => setIsOpen(true)}
        trigger={trigger}
      >
        <Modal.Content className="loader">
          {!state.data ||
          (state.data &&
            id &&
            state.data?.eventId !== id &&
            fetchStatus.fetching) ? (
            <Loader active />
          ) : (
            <Tab
              panes={[
                {
                  menuItem: "Details",
                  render: () => (
                    <Tab.Pane>
                      <div className="modal-content-scrolling">
                        <div className={styles.tabHeader}>
                          <span>Use Latest Ritual Version</span>
                          <Popup
                            trigger={
                              <div className={styles.sync}>
                                <Radio
                                  toggle
                                  checked={isSync}
                                  title="Use Ritual Update"
                                  onChange={(_, { checked, value }) => {
                                    setIsSync(!!checked);

                                    return value;
                                  }}
                                />
                              </div>
                            }
                            content={
                              <div>
                                Turning this <strong>ON</strong> will update
                                this event to the latest version of this ritual.
                              </div>
                            }
                            basic
                          />
                        </div>
                        <EventForm
                          defaultValues={state.data}
                          isSync={isSync}
                          formRef={formRef}
                          onSubmit={handleSubmit}
                        />
                      </div>
                      <Modal.Actions>
                        <Button type="button" onClick={handleClose}>
                          {Lang.LBL_CANCEL}
                        </Button>
                        <Button
                          primary
                          type="button"
                          onClick={() => formRef.current?.requestSubmit()}
                          loading={status.fetching || loading}
                          disabled={status.fetching || loading}
                        >
                          {Lang.LBL_SAVE}
                        </Button>
                      </Modal.Actions>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: "Notes",
                  render: () =>
                    state.data ? (
                      <Tab.Pane>
                        <NoteSegment eventId={state.data.eventId} />
                      </Tab.Pane>
                    ) : null,
                },
                {
                  menuItem: "Attendance",
                  render: () =>
                    state.data ? (
                      <Tab.Pane>
                        <EventAttendance data={state.data} />
                      </Tab.Pane>
                    ) : null,
                },
              ]}
            />
          )}
        </Modal.Content>
      </Modal>
    </Access>
  );
}

export default EventEdit;

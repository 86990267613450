import { IDataTableState } from "src/components/organisms/datatable/datatable.component";
import { privateClient } from "src/libraries/http.library";
import { SuccessPayloadSchema } from "src/models";
import {
  IRitualForm,
  RitualListPayloadSchema,
  RitualPayloadSchema,
} from "src/models/ritual.model";

const client = privateClient();
const endpoint = "ritual";

const ritualServices = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, RitualPayloadSchema);
  },

  listGET: async (params: Partial<IDataTableState> = {}) => {
    return client.get(`/${endpoint}`, params, RitualListPayloadSchema);
  },

  createPOST: async (data: IRitualForm) => {
    return client.post(`/${endpoint}`, data, RitualPayloadSchema);
  },

  updatePUT: async (id: number, data: IRitualForm) => {
    return client.put(`/${endpoint}/${id}`, data, RitualPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, SuccessPayloadSchema);
  },
};

export default ritualServices;

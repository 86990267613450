import { Segment, Table } from "semantic-ui-react";
import { getLodgeName } from "src/helpers/lodge.helper";
import Lang from "src/libraries/languages";
import { ILodge } from "src/models/lodge.model";

type IProps = {
  data: ILodge[];
};

function LodgeSegment({ data }: IProps) {
  return (
    <Segment>
      <h4>Lodges</h4>
      <Table>
        <Table.Body>
          {data.length > 0 ? (
            data.map((lodge) => (
              <Table.Row key={lodge.lodgeId}>
                <Table.Cell>{getLodgeName(lodge)}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={4} textAlign="center">
                {Lang.MSG_NO_DATA}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default LodgeSegment;

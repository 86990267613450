import { ReactNode, useCallback, useRef, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useEventContext } from "src/contexts/event.context";
import { IEventForm } from "src/models/event.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { eventActionTypes } from "src/ducks/event.duck";
import EventForm from "../event-form/event-form.component";
import styles from "../event-form/event-form.module.less";

type IProps = {
  trigger: ReactNode;
};

function EventAdd({ trigger }: IProps) {
  const { state, actions } = useEventContext();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const status = getServiceStatus(state, eventActionTypes.EVENT_DATA_CREATE);

  const handleSubmit = useCallback(
    async (formData: IEventForm) => {
      setLoading(true);
      const result = await actions.createPOST(formData);

      if (result.payload) {
        await actions.listGET();
        ToastSuccess("Event created successfully.");
        setIsOpen(false);
      }

      setLoading(false);
    },
    [actions, setIsOpen, setLoading],
  );
  return (
    <Modal
      open={isOpen}
      trigger={trigger}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      closeIcon
      size="tiny"
      className={styles.modalContent}
    >
      <Modal.Header>Add Event</Modal.Header>
      <Modal.Content scrolling>
        {isOpen && <EventForm formRef={formRef} onSubmit={handleSubmit} />}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={() => setIsOpen(false)}>
          {Lang.LBL_CANCEL}
        </Button>
        <Button
          primary
          type="button"
          onClick={() => formRef.current?.requestSubmit()}
          loading={status.fetching || loading}
          disabled={status.fetching || loading}
        >
          {Lang.LBL_SAVE}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EventAdd;

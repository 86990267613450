import yup from "src/libraries/validator.library";
import { DistrictSchema } from "./district.model";

export const DivisionShape = {
  name: yup.string().required(),
  districts: yup.array().of(DistrictSchema).default([]),
  updatedAt: yup.date(),
  updatedBy: yup.number(),
  createdAt: yup.date(),
  createdBy: yup.number(),
};

export const DivisionSchema = yup.object({
  ...DivisionShape,
  divisionId: yup.number().required(),
});

export const DivisionFormSchema = yup.object({
  name: yup.string().required().default(""),
});

export const DivisionListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(DivisionSchema.clone()),
});

export const DivisionPayloadSchema = yup.object({
  data: DivisionSchema.clone(),
});

export type IDivision = yup.Asserts<typeof DivisionSchema>;
export type IDivisionForm = yup.Asserts<typeof DivisionFormSchema>;

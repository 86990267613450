import { PropsWithChildren } from "react";
import { AccessType } from "src/constants";
import { hasAccess } from "src/helpers/access.helper";

type IProps = {
  type: AccessType | AccessType[];
  when?: (() => boolean) | boolean;
  onError?: () => void;
};

function Access({
  type,
  when = true,
  children,
  onError,
}: PropsWithChildren<IProps>) {
  const typeList = Array.isArray(type) ? type : [type];

  if (hasAccess(typeList, when)) {
    return <>{children}</>;
  } else if (onError) {
    onError();
  }

  return null;
}

export { AccessType };

export default Access;

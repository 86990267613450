import React, { PropsWithChildren, createContext, useContext } from "react";
import useReducerHook from "src/hooks/reducer.hook";
import LodgeReducer, {
  ILodgeState,
  defaultState,
  duckActions,
} from "src/ducks/lodge.duck";

const useReducer = (state = {}) => {
  return useReducerHook(
    LodgeReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions,
  );
};

type ILodgeContext = ReturnType<typeof useReducer>;

const LodgeContext = createContext<Partial<ILodgeContext>>({
  state: defaultState,
}) as React.Context<ILodgeContext>;

type IProps = {
  state?: Partial<ILodgeState>;
};

const LodgeProvider: React.FC<PropsWithChildren<IProps>> = ({
  children,
  state,
}) => {
  const reducer = useReducer(state);

  return (
    <LodgeContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </LodgeContext.Provider>
  );
};

const useLodgeContext = () => useContext(LodgeContext);

export type IUseLodgeContext = ReturnType<typeof useLodgeContext>;

export { LodgeContext, LodgeProvider, useLodgeContext };

import Axios, { Canceler, AxiosRequestConfig, AxiosInstance } from "axios";
import { isEmpty } from "lodash";
import { LoaderFunctionArgs } from "react-router";
import { InferType, Schema, ValidateOptions } from "yup";

const cancelList: Record<string, Canceler> = {};
let prevUrl = "/";

export const httpCancel = async (params?: LoaderFunctionArgs) => {
  if (params && prevUrl !== params.request.url) {
    prevUrl = params.request.url;
    Object.entries(cancelList).map(([key, cancel]) => {
      cancel();
      delete cancelList[key];
    });
  } else {
    Object.entries(cancelList).map(([key, cancel]) => {
      cancel();
      delete cancelList[key];
    });
  }

  return true;
};

const createClient = (xhr: AxiosInstance) => {
  return {
    instance: xhr,

    post: async <D, S extends Schema<any>, C>(
      url: string,
      data: D,
      schema: S,
      axiosConfig: AxiosRequestConfig = {},
      validateConfig: ValidateOptions<C> = {},
    ): Promise<InferType<S>> => {
      const response = await xhr.post(url, data, axiosConfig);

      return schema.validate(response.data, {
        stripUnknown: true,
        ...validateConfig,
      });
    },

    get: async <D, S extends Schema<any>, C>(
      url: string,
      data: D,
      schema: S,
      axiosConfig: AxiosRequestConfig = {},
      validateConfig: ValidateOptions<C> = {},
    ): Promise<InferType<S>> => {
      if (process.env.NODE_ENV !== "test" && isEmpty(axiosConfig)) {
        const key = JSON.stringify({ ...(data || {}), url });
        const { token, cancel } = Axios.CancelToken.source();
        cancelList[key] = cancel;
        axiosConfig.cancelToken = token;
      }

      const response = await xhr.get(url, {
        ...axiosConfig,
        params: data,
      });

      return schema.validate(response.data, {
        stripUnknown: true,
        ...validateConfig,
      });
    },

    put: async <D, S extends Schema<any>, C>(
      url: string,
      data: D,
      schema: S,
      axiosConfig: AxiosRequestConfig = {},
      validateConfig: ValidateOptions<C> = {},
    ): Promise<InferType<S>> => {
      const response = await xhr.put(url, data, axiosConfig);

      return schema.validate(response.data, {
        stripUnknown: true,
        ...validateConfig,
      });
    },

    delete: async <S extends Schema<any>, C>(
      url: string,
      schema: S,
      axiosConfig: AxiosRequestConfig = {},
      validateConfig: ValidateOptions<C> = {},
    ): Promise<InferType<S>> => {
      const response = await xhr.delete(url, axiosConfig);

      return schema.validate(response.data, {
        stripUnknown: true,
        ...validateConfig,
      });
    },
  };
};

export const privateClient = () => {
  const baseURL = process.env.REACT_APP_API_ENDPOINT;

  const xhr = Axios.create({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    transformRequest: [
      (data) => {
        // This is for uploading files
        if (data instanceof FormData) {
          return data;
        }

        return JSON.stringify(data);
      },
    ],
    baseURL,
    withCredentials: true,
  });

  xhr.interceptors.request.use(
    (config) => config,
    (error) => {
      return Promise.reject(error);
    },
  );

  xhr.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error.response ?? error);
    },
  );

  return createClient(xhr);
};

export const publicClient = () => {
  const baseURL = process.env.REACT_APP_API_ENDPOINT;

  const xhr = Axios.create({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    baseURL,
  });

  xhr.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error.response ?? error);
    },
  );

  return createClient(xhr);
};

import * as yup from "yup";
import Lang from "./languages";

yup.setLocale({ mixed: { required: Lang.MSG_VALIDATION_ERROR_REQUIRED } });

export const checkDuplicate =
  <TValue extends Record<string, any>, TContext>(
    field: keyof TValue,
    message?: string,
  ) =>
  (value: TValue[], main: yup.TestContext<TContext>) => {
    const duplicates = value.reduce(
      (items: number[], item, index) =>
        value.findIndex(
          (v) =>
            v[field] &&
            item[field] &&
            v[field].toString().toLowerCase() ===
              item[field].toString().toLowerCase(),
        ) !== index
          ? [...items, index]
          : items,
      [],
    );

    if (duplicates.length) {
      const errors = duplicates.map((index) => {
        return new yup.ValidationError(
          message || `${field as string} already exist`,
          value[index],
          `${main.path}[${index}].${field as string}`,
        );
      });

      return main.createError({
        message: () => errors,
      });
    }

    return true;
  };

export default yup;

import { Icon, Input, Segment } from "semantic-ui-react";
import { IEvent } from "src/models/event.model";
import DataTable, {
  IDataTableColumn,
  IDataTableState,
} from "../datatable/datatable.component";
import Lang from "src/libraries/languages";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getDate } from "src/helpers/datetime.helper";
import EventDetail from "src/components/pages/event/event-detail/event-detail.component";

type IProps = {
  data: IEvent[];
};

function EventSegment({ data }: IProps) {
  const positions = useMemo(
    () =>
      data
        .map((event) =>
          event.sections
            .map((section) =>
              section.positions
                .map((position) => ({
                  startDate: event.startDate,
                  eventId: event.eventId,
                  eventName: event.name,
                  positionName: position.positionName,
                  ritualName: event.ritualName,
                  sectionName: section.name,
                }))
                .flat(),
            )
            .flat(),
        )
        .flat(),
    [data],
  );

  type IPosition = typeof positions;

  const [keyword, setKeyword] = useState<string>("");
  const [list, setList] = useState<IPosition>([]);

  const columns: IDataTableColumn<IPosition[number]>[] = [
    {
      index: "startDate",
      title: "Date",
      render: (value) => getDate(value),
    },
    {
      index: "eventName",
      title: "Event Name",
      render: (_, values) => (
        <EventDetail
          id={values.eventId}
          trigger={<a role="button">{values.eventName}</a>}
        />
      ),
    },
    {
      index: "positionName",
      title: "Position",
    },
    {
      index: "ritualName",
      title: "Ritual",
    },
    {
      index: "sectionName",
      title: "Section",
    },
  ];

  useEffect(() => {
    if (keyword) {
      setList(
        positions.filter((v) =>
          `${v.eventName} ${getDate(v.startDate)} ${v.ritualName} ${
            v.positionName
          } ${v.sectionName}`
            .toLowerCase()
            .includes(keyword.toLowerCase()),
        ),
      );
    } else {
      setList(positions);
    }
  }, [positions, keyword, setList]);

  const handleFilter = useCallback(
    (params: IDataTableState) => {
      const filter = params.filters.find((v) => v.name === "filter");

      if (filter) {
        setKeyword(filter.value);
      }
    },
    [setKeyword],
  );

  return (
    <Segment id="events">
      <h4>Events Participated</h4>
      <DataTable
        columns={columns}
        data={list}
        onChange={handleFilter}
        rowsPerPage={[10]}
        sortable
        toolbars={{
          filter: ({ value, setValue }) => (
            <Input
              placeholder={Lang.LBL_SEARCH}
              value={value}
              onChange={(_, data) => setValue(data.value)}
              icon={
                value ? (
                  <Icon name="close" link onClick={() => setValue("")} />
                ) : undefined
              }
            />
          ),
        }}
      />
    </Segment>
  );
}

export default EventSegment;

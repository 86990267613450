import { sortBy } from "lodash";
import { IDivision } from "src/models/division.model";

export const getDivisionName = ({
  name,
}: {
  divisionId?: number;
  name: string;
}) => `Division ${name}`;

export const sortDivisionData = (data: IDivision[]) =>
  sortBy(
    data.map((values) => {
      return {
        ...values,
        districts: sortBy(
          values.districts.map((district) => ({
            ...district,
            lodges: sortBy(district.lodges, (lodge) => Number(lodge.lodgeNo)),
          })),
          (division) => Number(division.name) || division.name,
        ),
      };
    }),
    (v) => Number(v.name) || v.name,
  );

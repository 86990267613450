import { Controller } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { useValidator } from "src/hooks/validator.hook";
import {
  IPosition,
  IPositionForm,
  PositionFormSchema,
} from "src/models/position.model";

type IProps = {
  defaultValues?: Partial<IPosition>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (formData: IPositionForm) => Promise<void>;
};

function PositionForm({ defaultValues, formRef, onSubmit }: IProps) {
  const { control, handleSubmit } = useValidator(PositionFormSchema, {
    defaultValues: defaultValues as IPositionForm,
  });

  return (
    <Form
      ref={formRef}
      data-testid="form"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input {...field} placeholder="Name" error={error?.message} />
        )}
      />
    </Form>
  );
}

export default PositionForm;

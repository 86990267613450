import { ICommonState, IStatus } from "src/libraries/thunk.library";

export const getServiceStatus = <S, T>(
  state: ICommonState<S>,
  actionType: T
): IStatus =>
  (state.status as any)[actionType] || {
    fetch: false,
    error: null,
  };

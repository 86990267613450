import yup from "src/libraries/validator.library";

export const LodgeShape = {
  name: yup.string().required(),
  lodgeNo: yup.string().required(),
  address: yup.string().required(),
  divisionId: yup.number().required(),
  districtId: yup.number().required(),
  updatedAt: yup.date(),
  updatedBy: yup.number(),
  createdAt: yup.date(),
  createdBy: yup.number(),
};

export const LodgeSchema = yup.object({
  ...LodgeShape,
  lodgeId: yup.number().required(),
});

export const LodgeFormSchema = yup.object({
  name: yup.string().required().default(""),
  lodgeNo: yup.string().required().default(""),
  address: yup.string().required().default(""),
  divisionId: yup.number().required(),
  districtId: yup.number().required(),
});

export const LodgeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(LodgeSchema.clone()),
});

export const LodgePayloadSchema = yup.object({
  data: LodgeSchema.clone(),
});

export type ILodge = yup.Asserts<typeof LodgeSchema>;
export type ILodgeForm = yup.Asserts<typeof LodgeFormSchema>;

import { ReactNode } from "react";
import styles from "./public.module.less";
import { Loader } from "semantic-ui-react";
import useAuth from "src/hooks/auth.hook";

type IProps = {
  content: ReactNode;
};

function PublicTemplate({ content }: IProps) {
  const ready = useAuth(false);

  if (!ready) {
    return <Loader active />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{content}</div>
    </div>
  );
}

export default PublicTemplate;

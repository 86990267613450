import { Button, Modal } from "semantic-ui-react";
import LodgeForm from "../lodge-form/lodge-form.component";
import { ReactNode, useCallback, useRef, useState } from "react";
import { useLodgeContext } from "src/contexts/lodge.context";
import { ILodge, ILodgeForm } from "src/models/lodge.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { useDivisionContext } from "src/contexts/division.context";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";

type IProps = {
  data: ILodge;
  trigger: ReactNode;
};

function LodgeEdit({ data, trigger }: IProps) {
  const { actions } = useLodgeContext();
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState(false);
  const { actions: divisionActions } = useDivisionContext();

  const handleSubmit = useCallback(
    async (formData: ILodgeForm) => {
      setLoading(true);

      if (data) {
        const result = await actions.updatePUT(data?.lodgeId, formData);

        if (result.payload) {
          await divisionActions.listGET();
          ToastSuccess("Lodge updated successfully.");
          setIsOpen(false);
        }
      }

      setLoading(false);
    },
    [actions, divisionActions, data, setIsOpen, setLoading],
  );
  return (
    <Access type={AccessType.LODGE_UPDATE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>Edit Lodge</Modal.Header>
        <Modal.Content>
          <LodgeForm
            defaultValues={data}
            formRef={formRef}
            onSubmit={handleSubmit}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={loading}
            disabled={loading}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default LodgeEdit;

import { sortBy, uniq } from "lodash";
import { useMemo } from "react";
import { Segment, Table } from "semantic-ui-react";
import Lang from "src/libraries/languages";
import { IEvent } from "src/models/event.model";
import PositionSegmentModal, {
  IEventModal,
} from "./position-segment-modal/position-segment-modal.component";

type IProps = {
  memberId: number;
  data: IEvent[];
};

type IPosition = {
  positionId: number;
  memberId?: number | null;
  name: string;
  rituals: Record<
    number,
    {
      events: IEventModal[];
      total: number;
    }
  >;
};

type ISection = {
  sectionId: number;
  name: string;
  positions: IPosition[];
};

function PositionSegment({ memberId, data }: IProps) {
  const rituals = useMemo(
    () =>
      sortBy(
        data
          .filter(
            (value, index) =>
              index === data.findIndex((v) => value.ritualId === v.ritualId),
          )
          .map((v) => ({
            ritualId: v.ritualId,
            name: v.ritualName,
            positions: uniq(
              v.sections
                .map((v) => v.positions.map((v) => v.positionId))
                .flat(),
            ),
          })),
        (v) => Number(v.name) || v.name,
      ),
    [data],
  );

  const report = useMemo(() => {
    return data.reduce((items: Record<string, ISection>, item) => {
      const result = item.sections.reduce(
        (list: Record<string, ISection>, value) => {
          const positions = value.positions.reduce(
            (values: IPosition[], position) => {
              const index = values.findIndex(
                (val) => val.positionId === position.positionId,
              );
              if (index < 0) {
                return [
                  ...values,
                  {
                    positionId: position.positionId,
                    memberId: position.memberId,
                    name: position.positionName,
                    rituals: {
                      [item.ritualId]:
                        position.memberId === memberId
                          ? {
                              events: [
                                {
                                  eventId: item.eventId,
                                  name: item.name,
                                  startDate: item.startDate,
                                  endDate: item.endDate,
                                },
                              ],
                              total: 1,
                            }
                          : {
                              events: [],
                              total: 0,
                            },
                    },
                  },
                ];
              }

              if (position.memberId === memberId) {
                values.splice(index, 1, {
                  positionId: position.positionId,
                  memberId: position.memberId,
                  name: position.positionName,
                  rituals: {
                    ...values[index].rituals,
                    [item.ritualId]: {
                      events: [
                        ...(values[index].rituals[item.ritualId].events ?? []),
                        {
                          eventId: item.eventId,
                          name: item.name,
                          startDate: item.startDate,
                          endDate: item.endDate,
                        },
                      ],
                      total:
                        (values[index].rituals[item.ritualId]?.total ?? 0) + 1,
                    },
                  },
                });
              }

              return values;
            },
            [],
          );

          return {
            ...list,
            [value.name]: {
              name: value.name,
              sectionId: value.ritualSectionId,
              positions,
            },
          };

          // const positions: IPosition[] = list[value.name].positions;

          // detail.forEach((val) => {
          //   const index = positions.findIndex(
          //     (v) => v.positionId === val.positionId && v.memberId === memberId
          //   );

          //   if (index >= 0) {
          //     positions.splice(index, 1, {
          //       ...positions[index],
          //       rituals: {
          //         ...positions[index].rituals,
          //         [item.ritualId]:
          //           val.memberId === memberId
          //             ? {
          //                 events: [
          //                   ...(positions[index].rituals[item.ritualId]
          //                     ?.events ?? []),
          //                   ...val.rituals[item.ritualId].events,
          //                 ],
          //                 total:
          //                   (positions[index].rituals[item.ritualId]?.total ??
          //                     0) + 1,
          //               }
          //             : positions[index].rituals[item.ritualId],
          //       },
          //     });
          //   } else if (val.memberId === memberId) {
          //     positions.push({
          //       ...val,
          //       rituals: {
          //         [item.ritualId]: {
          //           events: [
          //             {
          //               eventId: item.eventId,
          //               name: item.name,
          //               startDate: item.startDate,
          //               endDate: item.endDate,
          //             },
          //           ],
          //           total: 1,
          //         },
          //       },
          //     });
          //   }
          // });

          // return {
          //   ...list,
          //   [value.name]: {
          //     ...list[value.name],
          //     name: value.name,
          //     positions,
          //   },
          // };
        },
        {},
      );

      Object.entries(result).forEach(([key, value]) => {
        if (!items[key]) {
          items[key] = value;
        } else {
          const positions: IPosition[] = [...items[key].positions];
          value.positions.forEach((val) => {
            const index = positions.findIndex(
              (v) => v.positionId === val.positionId,
            );

            if (index >= 0) {
              positions.splice(index, 1, {
                ...positions[index],
                rituals: {
                  ...positions[index].rituals,
                  [item.ritualId]:
                    val.memberId === memberId
                      ? {
                          events: [
                            ...(positions[index].rituals[item.ritualId]
                              ?.events ?? []),
                            ...val.rituals[item.ritualId].events,
                          ],
                          total:
                            (positions[index].rituals[item.ritualId]?.total ??
                              0) + 1,
                        }
                      : positions[index].rituals[item.ritualId],
                },
              });
            } else {
              positions.push({
                ...val,
                rituals: {
                  [item.ritualId]: {
                    events: [
                      {
                        eventId: item.eventId,
                        name: item.name,
                        startDate: item.startDate,
                        endDate: item.endDate,
                      },
                    ],
                    total: val.memberId === memberId ? 1 : 0,
                  },
                },
              });
            }
          });

          items[key] = {
            ...items[value.name],
            sectionId: value.sectionId,
            name: value.name,
            positions,
          };
        }
      });

      return items;
    }, {});
  }, [data]);

  return (
    <Segment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Position</Table.HeaderCell>
            {rituals.map((v) => (
              <Table.HeaderCell key={v.ritualId}>{v.name}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {Object.values(report).length ? (
            Object.values(report).map((section) => (
              <>
                <Table.Row>
                  <Table.Cell
                    colSpan={rituals.length + 1}
                    textAlign="center"
                    positive
                  >
                    <strong>{section.name}</strong>
                  </Table.Cell>
                </Table.Row>
                {section.positions.map((position) => (
                  <>
                    <Table.Row key={position.positionId}>
                      <Table.Cell>{position.name}</Table.Cell>
                      {rituals.map((v) => (
                        <Table.Cell key={v.ritualId}>
                          {position.rituals[v.ritualId]?.total ? (
                            <PositionSegmentModal
                              events={position.rituals[v.ritualId].events}
                              trigger={
                                <a>
                                  {position.rituals[v.ritualId]?.total ?? 0}
                                </a>
                              }
                            />
                          ) : v.positions.includes(position.positionId) ? (
                            0
                          ) : (
                            "-"
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  </>
                ))}
              </>
            ))
          ) : (
            <Table.Row>
              <Table.Cell textAlign="center">{Lang.MSG_NO_DATA}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default PositionSegment;

import React, { PropsWithChildren, createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import PositionReducer, {
  IPositionState,
  defaultState,
  duckActions,
} from 'src/ducks/position.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    PositionReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IPositionContext = ReturnType<typeof useReducer>;

const PositionContext = createContext<Partial<IPositionContext>>({
  state: defaultState,
}) as React.Context<IPositionContext>;

type IProps = {
  state?: Partial<IPositionState>;
};

const PositionProvider: React.FC<PropsWithChildren<IProps>> = ({
  children,
  state,
}) => {
  const reducer = useReducer(state);

  return (
    <PositionContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </PositionContext.Provider>
  );
};

const usePositionContext = () => useContext(PositionContext);

export type IUsePositionContext = ReturnType<typeof usePositionContext>;

export { PositionContext, PositionProvider, usePositionContext };

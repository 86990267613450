import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Icon, Input } from "semantic-ui-react";
import { IDataTableToolbarParams } from "src/components/organisms/datatable/datatable.component";
import Lang from "src/libraries/languages";
import styles from "./table-search.module.less";

function TableSearch({
  value,
  setValue,
  delay = 0,
}: IDataTableToolbarParams & { delay?: number }) {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value, setVal]);

  const handleChange = useCallback(
    debounce((dataValue) => {
      setValue(dataValue);
    }, delay),
    [setValue],
  );

  return (
    <div className={styles.wrapper}>
      <span>{Lang.LBL_SEARCH}:</span>
      <Input
        value={val ?? ""}
        onChange={(_, data) => {
          setVal(data.value);
          handleChange(data.value);
        }}
        icon={
          value ? (
            <Icon name="close" link onClick={() => setValue("")} />
          ) : undefined
        }
      />
    </div>
  );
}

export default TableSearch;

import { Button, Modal } from "semantic-ui-react";
import DivisionForm from "../division-form/division-form.component";
import { ReactNode, useCallback, useRef, useState } from "react";
import { useDivisionContext } from "src/contexts/division.context";
import { IDivision, IDivisionForm } from "src/models/division.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { divisionActionTypes } from "src/ducks/division.duck";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";

type IProps = {
  data: IDivision;
  trigger: ReactNode;
};

function DivisionEdit({ data, trigger }: IProps) {
  const { state, actions } = useDivisionContext();
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const status = getServiceStatus(
    state,
    divisionActionTypes.DIVISION_DATA_UPDATE,
  );

  const handleSubmit = useCallback(
    async (formData: IDivisionForm) => {
      if (data) {
        const result = await actions.updatePUT(data?.divisionId, formData);

        if (result.payload) {
          ToastSuccess("Division updated successfully.");
          actions.listGET();
          setIsOpen(false);
        }
      }
    },
    [actions, data, setIsOpen],
  );
  return (
    <Access type={AccessType.DIVISION_UPDATE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>Edit Division</Modal.Header>
        <Modal.Content>
          <DivisionForm
            defaultValues={data}
            formRef={formRef}
            onSubmit={handleSubmit}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={status.fetching}
            disabled={status.fetching}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default DivisionEdit;

export enum AccessRole {
  Admin = 1,
  JurisdictionAdmin = 2,
  DivisionAdmin = 3,
  DistrictAdmin = 4,
  LodgeAdmin = 5,
  Member = 6,
}

export enum AccessType {
  USER_CREATE,
  USER_READ,
  USER_UPDATE,
  USER_DELETE,

  USER_DIVISION,
  USER_DISTRICT,
  USER_LODGE,

  POSITION_CREATE,
  POSITION_READ,
  POSITION_UPDATE,
  POSITION_DELETE,

  EVENT_READ,
  EVENT_UPDATE,
  EVENT_DELETE,

  MEMBER_READ,
  MEMBER_UPDATE,
  MEMBER_DELETE,

  REPORT_READ,

  RITUAL_CREATE,
  RITUAL_UPDATE,
  RITUAL_DELETE,
  RITUAL_SECTION_DELETE,
  RITUAL_SECTION_POSITION_DELETE,

  DIVISION_CREATE,
  DIVISION_UPDATE,
  DIVISION_DELETE,

  DISTRICT_CREATE,
  DISTRICT_UPDATE,
  DISTRICT_DELETE,

  LODGE_CREATE,
  LODGE_UPDATE,
  LODGE_DELETE,
}

export const AccessPermission: Record<AccessRole, AccessType[]> = {
  [AccessRole.Admin]: Object.values(AccessType).filter((v) =>
    Number.isInteger(v),
  ) as unknown as AccessType[],
  // [AccessRole.Admin]: [AccessType.USER_CREATE, AccessType.USER_DISTRICT],

  [AccessRole.JurisdictionAdmin]: Object.values(AccessType)
    .filter(
      (v) =>
        ![AccessType.USER_DELETE, AccessType.MEMBER_DELETE].includes(
          v as AccessType,
        ),
    )
    .filter((v) => Number.isInteger(v)) as unknown as AccessType[],

  [AccessRole.DivisionAdmin]: [
    AccessType.USER_UPDATE,

    AccessType.USER_DISTRICT,
    AccessType.USER_LODGE,

    AccessType.EVENT_UPDATE,
    AccessType.EVENT_DELETE,

    AccessType.MEMBER_UPDATE,
    AccessType.MEMBER_DELETE,
  ],

  [AccessRole.DistrictAdmin]: [
    AccessType.USER_UPDATE,
    AccessType.USER_LODGE,
    AccessType.EVENT_UPDATE,
    AccessType.EVENT_DELETE,

    AccessType.MEMBER_UPDATE,
    AccessType.MEMBER_DELETE,
  ],

  [AccessRole.LodgeAdmin]: [
    AccessType.EVENT_READ,
    AccessType.MEMBER_READ,
    AccessType.EVENT_UPDATE,
    AccessType.EVENT_DELETE,

    AccessType.MEMBER_UPDATE,
    AccessType.MEMBER_DELETE,
  ],

  [AccessRole.Member]: [AccessType.REPORT_READ],
};

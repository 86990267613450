import { ICommonState, IReducerAction } from "src/libraries/thunk.library";
import districtServices from "src/services/district.service";
import { IDistrict } from "src/models/district.model";

export const districtActionTypes = {
  DISTRICT_DATA_READ: "DISTRICT_DATA_READ",
  DISTRICT_LIST_READ: "DISTRICT_LIST_READ",
  DISTRICT_DATA_CREATE: "DISTRICT_DATA_CREATE",
  DISTRICT_DATA_UPDATE: "DISTRICT_DATA_UPDATE",
  DISTRICT_DATA_DELETE: "DISTRICT_DATA_DELETE",
  DISTRICT_DATA_SET: "DISTRICT_DATA_SET",
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: districtActionTypes.DISTRICT_DATA_READ,
    service: districtServices.dataGET,
  },

  listGET: {
    type: districtActionTypes.DISTRICT_LIST_READ,
    service: districtServices.listGET,
  },

  createPOST: {
    type: districtActionTypes.DISTRICT_DATA_CREATE,
    service: districtServices.createPOST,
  },

  updatePUT: {
    type: districtActionTypes.DISTRICT_DATA_UPDATE,
    service: districtServices.updatePUT,
  },

  dataDELETE: {
    type: districtActionTypes.DISTRICT_DATA_DELETE,
    service: districtServices.dataDELETE,
  },

  // This is a sync action
  setData: (data: IDistrict) => ({
    type: districtActionTypes.DISTRICT_DATA_SET,
    payload: {
      data,
    },
  }),
};

export type IDistrictAsync = typeof duckActions;

export interface IDistrictState
  extends ICommonState<typeof districtActionTypes> {
  data?: IDistrict;
  list: IDistrict[];
  total: number;
}

export const defaultState: IDistrictState = {
  status: {},
  list: [],
  total: 0,
};

const DistrictReducer = (
  state: IDistrictState,
  action: IReducerAction<IDistrictAsync>,
): IDistrictState => {
  switch (action.type) {
    case districtActionTypes.DISTRICT_DATA_SET:
    case districtActionTypes.DISTRICT_DATA_READ:
    case districtActionTypes.DISTRICT_DATA_UPDATE:
    case districtActionTypes.DISTRICT_DATA_CREATE: {
      return {
        ...state,
        data: action.payload?.data,
      };
    }

    case districtActionTypes.DISTRICT_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case districtActionTypes.DISTRICT_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.districtId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default DistrictReducer;

import { useCallback, useState } from "react";
import { Button, ButtonProps, Modal, ModalProps } from "semantic-ui-react";
import Lang from "src/libraries/languages";

type IProps = ModalProps & {
  onConfirm: () => Promise<void> | void;
  confirmButton?: ButtonProps;
  cancelButton?: ButtonProps;
};

function Confirm({
  header,
  content,
  actions,
  open,
  confirmButton = {
    content: Lang.LBL_CONFIRM,
  },
  cancelButton = {
    content: Lang.LBL_CANCEL,
  },
  onConfirm,
  size = "mini",
  ...props
}: IProps) {
  const [isOpen, setIsOpen] = useState(open || false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await onConfirm();

    setLoading(false);
    setIsOpen(false);
  }, [setIsOpen, onConfirm, setLoading]);

  return (
    <Modal
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      size={size}
      {...props}
    >
      {header && <Modal.Header>{header.toString()}</Modal.Header>}
      {content && <Modal.Content>{content.toString()}</Modal.Content>}
      <Modal.Actions>
        <Button
          {...cancelButton}
          type="button"
          onClick={() => setIsOpen(false)}
        />
        <Button
          loading={loading}
          disabled={loading}
          {...confirmButton}
          type="button"
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default Confirm;

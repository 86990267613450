import { ReactNode, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import Lang from "src/libraries/languages";
import DataTable, {
  IDataTableColumn,
} from "../../datatable/datatable.component";
import { getDate } from "src/helpers/datetime.helper";
import EventDetail from "src/components/pages/event/event-detail/event-detail.component";
import { IEvent } from "src/models/event.model";

export type IEventModal = {
  eventId: number;
  name: IEvent["name"];
  startDate: IEvent["startDate"];
  endDate?: IEvent["endDate"];
};

type IProps = {
  events: IEventModal[];
  trigger: ReactNode;
};

const columns: IDataTableColumn<IEventModal>[] = [
  {
    index: "startDate",
    title: "Date",
    render: (value) => getDate(value),
  },
  {
    index: "name",
    title: "Name",
    render: (_, values) => (
      <EventDetail id={values.eventId} trigger={<a role="button">{values.name}</a>} />
    ),
  },
];

function PositionSegmentModal({ events, trigger }: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Modal
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={trigger}
      size="tiny"
    >
      <Modal.Header>Events</Modal.Header>
      <Modal.Content scrolling>
        <DataTable columns={columns} data={events} sortable />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={() => setIsOpen(false)}>
          {Lang.LBL_CLOSE}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default PositionSegmentModal;

import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AccessRole, AccessType, CookieKeys } from "src/constants";
import { useUserContext } from "src/contexts/user.context";
import { hasAccess } from "src/helpers/access.helper";
import { getCookie } from "src/helpers/cookie.helper";
import { IUser } from "src/models/user.model";

const getDefaultLanding = (state: any, role?: number) => {
  switch (role) {
    case AccessRole.JurisdictionAdmin:
    case AccessRole.DivisionAdmin:
    case AccessRole.DistrictAdmin: {
      return "/divisions";
    }

    case AccessRole.LodgeAdmin:
    case AccessRole.Member: {
      return "/events";
    }
  }

  return state ? `${state?.pathname ?? ""}${state?.search ?? ""}`.trim() : "/";
};

const useAuth = (requireAuth = true) => {
  const {
    state: { me },
  } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (
      requireAuth &&
      !getCookie(CookieKeys.UserToken) &&
      !location.pathname.includes("auth")
    ) {
      navigate("/auth", {
        state: {
          pathname: window.location.pathname,
          search: window.location.search,
        },
        replace: true,
      });

      return;
    } else if (
      !requireAuth &&
      getCookie(CookieKeys.UserToken) &&
      location.pathname.includes("auth")
    ) {
      navigate(getDefaultLanding(location.state, me?.userTypeId), {
        replace: true,
      });

      return;
    }

    setReady(true);
  }, [navigate, me, setReady, requireAuth, location]);

  useEffect(() => {
    if (location.pathname.includes("index.html")) {
      navigate("/", {
        replace: true,
      });
    }
  }, [location, navigate]);

  return ready;
};

export const useUserAction = () => {
  const {
    state: { me },
  } = useUserContext();

  const hasEdit = useCallback(
    (user: IUser) => {
      if (!me || !hasAccess(AccessType.USER_UPDATE)) {
        return false;
      }

      if (
        [AccessRole.Admin, AccessRole.JurisdictionAdmin].includes(me.userTypeId)
      ) {
        return true;
      }

      if (me.userTypeId === AccessRole.DivisionAdmin) {
        return user?.divisionId === me.divisionId;
      } else if (me.userTypeId === AccessRole.DistrictAdmin) {
        return user?.districtId === me.districtId;
      } else if (me.userTypeId === AccessRole.LodgeAdmin) {
        return user?.lodgeId === me.lodgeId;
      }

      return false;
    },
    [me],
  );

  const hasDelete = useCallback(
    (user: IUser) => {
      if (!me || !hasAccess(AccessType.USER_DELETE)) {
        return false;
      }

      if (
        [AccessRole.Admin, AccessRole.JurisdictionAdmin].includes(me.userTypeId)
      ) {
        return true;
      }

      if (me.userTypeId === AccessRole.DivisionAdmin) {
        return user?.divisionId === me.divisionId;
      } else if (me.userTypeId === AccessRole.DistrictAdmin) {
        return user?.districtId === me.districtId;
      } else if (me.userTypeId === AccessRole.LodgeAdmin) {
        return user?.lodgeId === me.lodgeId;
      }

      return false;
    },
    [me],
  );

  return { hasEdit, hasDelete };
};

export default useAuth;

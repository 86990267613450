import { ReactNode, useCallback, useRef, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { usePositionContext } from "src/contexts/position.context";
import { IPositionForm } from "src/models/position.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { positionActionTypes } from "src/ducks/position.duck";
import PositionForm from "../position-form/position-form.component";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";

type IProps = {
  trigger: ReactNode;
};

function PositionAdd({ trigger }: IProps) {
  const { state, actions } = usePositionContext();
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const status = getServiceStatus(
    state,
    positionActionTypes.POSITION_DATA_CREATE,
  );

  const handleSubmit = useCallback(
    async (formData: IPositionForm) => {
      const result = await actions.createPOST(formData);

      if (result.payload) {
        ToastSuccess("Position created successfully.");
        actions.listGET();
        setIsOpen(false);
      }
    },
    [actions, setIsOpen],
  );
  return (
    <Access type={AccessType.POSITION_DELETE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>Add Position</Modal.Header>
        <Modal.Content>
          <PositionForm formRef={formRef} onSubmit={handleSubmit} />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={status.fetching}
            disabled={status.fetching}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default PositionAdd;

import { Controller, useFormContext } from "react-hook-form";
import { Button, Form, Icon } from "semantic-ui-react";
import { useFieldArray } from "react-hook-form";
import { IRitualForm, IRitualSectionForm } from "src/models/ritual.model";
import { usePositionContext } from "src/contexts/position.context";
import mainStyles from "../ritual-form.module.less";
import styles from "./ritual-form-section.module.less";
import {
  Draggable,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd";
import { PropsWithChildren, useEffect } from "react";
import { getServiceStatus } from "src/selectors/status.selector";
import { positionActionTypes } from "src/ducks/position.duck";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";
import Confirm from "src/components/atoms/confirm/confirm.component";
import Lang from "src/libraries/languages";
import { createPortal } from "react-dom";

export type IReOrderFunction = (
  index: string,
  callback: (from: number, to: number) => void,
) => void;

type IProps = {
  index: number;
  formRef?: React.RefObject<HTMLFormElement>;
  handleChange: (formData: IRitualSectionForm) => void;
  handleRemove: () => void;
  handleOrder: IReOrderFunction;
};

export function RitualPortal({
  snapshot,
  children,
}: PropsWithChildren<{ snapshot: DraggableStateSnapshot }>) {
  const usePortal: boolean = snapshot.isDragging;

  if (!usePortal) {
    return <li>{children}</li>;
  }

  // if dragging - put the item in a portal
  return <li>{createPortal(children, document.body)}</li>;
}

function RitualSectionForm({
  index: sectionIndex,
  handleRemove,
  handleOrder,
}: IProps) {
  const { state } = usePositionContext();
  const status = getServiceStatus(
    state,
    positionActionTypes.POSITION_LIST_READ,
  );
  const { control } = useFormContext<IRitualForm>();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `sections.${sectionIndex}.positions`,
  });

  const isDraggable = fields.length > 1;

  useEffect(() => {
    handleOrder(`child-${sectionIndex}`, (from, to) => {
      move(from, to);
    });
  }, [sectionIndex, handleOrder, move]);

  useEffect(() => {
    if (!fields.length) {
      append({
        positionId: undefined as any,
      });
    }
  }, [fields, append]);

  return (
    <div className={styles.wrapper}>
      <div className={mainStyles.field}>
        <Controller
          name={`sections.${sectionIndex}.name`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Input
              {...field}
              placeholder="Section Name"
              error={error?.message}
            />
          )}
        />
        <Access type={AccessType.RITUAL_SECTION_DELETE}>
          <Confirm
            header={Lang.TTL_CONFIRM_DELETE}
            content="Are you sure you want to delete this section?"
            confirmButton={{
              content: Lang.LBL_DELETE,
              negative: true,
            }}
            trigger={<Icon name="trash" />}
            onConfirm={handleRemove}
          />
        </Access>
      </div>

      <Droppable droppableId={`child-${sectionIndex}`} type="childContainer">
        {(provided) => (
          <ul
            className={mainStyles.list}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {fields.map(({ id }, index) => (
              <Draggable
                key={id}
                draggableId={id}
                index={index}
                isDragDisabled={!isDraggable}
              >
                {({ innerRef, dragHandleProps, draggableProps }, snapshot) => (
                  <RitualPortal key={id} snapshot={snapshot}>
                    <div
                      ref={innerRef}
                      {...draggableProps}
                      className={mainStyles.section}
                    >
                      {isDraggable && (
                        <div
                          className={mainStyles.dragIcon}
                          {...dragHandleProps}
                        >
                          <Icon name="list" />
                        </div>
                      )}
                      <div className={mainStyles.field}>
                        <Controller
                          name={`sections.${sectionIndex}.positions.${index}.positionId`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Form.Select
                              {...field}
                              options={state.list.map((v) => ({
                                key: v.positionId,
                                value: v.positionId,
                                text: v.name,
                              }))}
                              search
                              placeholder="Position Name"
                              error={error?.message}
                              onChange={(_, data) => {
                                field.onChange(
                                  data.value ? Number(data.value) : undefined,
                                );

                                return data.value;
                              }}
                              clearable
                              loading={status.fetching}
                            />
                          )}
                        />
                        <Access
                          type={AccessType.RITUAL_SECTION_POSITION_DELETE}
                        >
                          <Confirm
                            header={Lang.TTL_CONFIRM_DELETE}
                            content="Are you sure you want to delete this position?"
                            confirmButton={{
                              content: Lang.LBL_DELETE,
                              negative: true,
                            }}
                            trigger={<Icon name="trash" />}
                            onConfirm={() => remove(index)}
                          />
                        </Access>
                      </div>
                    </div>
                  </RitualPortal>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>

      <div className={styles.actions}>
        <Button
          disabled={state.list.length === fields.length}
          loading={status.fetching}
          type="button"
          icon="plus"
          size="tiny"
          circular
          onClick={() =>
            append({
              positionId: undefined as any,
            })
          }
        />
      </div>
    </div>
  );
}

export default RitualSectionForm;

export default {
  TTL_WELCOME: "Welcome to the",
  TTL_APP_NAME: "Tiler’s Register",

  TTL_TOAST_SUCCESS: "Success",
  TTL_TOAST_ERROR: "Error",
  TTL_TOAST_WARNING: "Warning",
  TTL_TOAST_INFO: "Note",

  TTL_FORGOT_PASSWORD: "Forgot Password?",
  TTL_RESET_PASSWORD: "Set Password",

  TTL_USER_LIST: "Users",
  TTL_USER_ADD: "Add User",
  TTL_USER_EDIT: "Edit User",

  TTL_CONFIRM_DELETE: "Confirm Delete",

  TTL_POSITION_REPORT: "Position Report",
  TTL_MEMBER_LIST: "Member List",
};

import { Controller } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { useDivisionContext } from "src/contexts/division.context";
import { divisionActionTypes } from "src/ducks/division.duck";
import { getDivisionName } from "src/helpers/division.helper";
import { useValidator } from "src/hooks/validator.hook";
import Lang from "src/libraries/languages";
import {
  IDistrict,
  IDistrictForm,
  DistrictFormSchema,
} from "src/models/district.model";
import { getServiceStatus } from "src/selectors/status.selector";

type IProps = {
  defaultValues?: Partial<IDistrict>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (formData: IDistrictForm) => Promise<void>;
};

function DistrictForm({ defaultValues, formRef, onSubmit }: IProps) {
  const { state } = useDivisionContext();
  const status = getServiceStatus(
    state,
    divisionActionTypes.DIVISION_LIST_READ,
  );
  const { control, handleSubmit } = useValidator(DistrictFormSchema, {
    defaultValues: defaultValues as IDistrictForm,
  });

  return (
    <Form
      ref={formRef}
      data-testid="form"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
    >
      <Controller
        name="divisionId"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Select
            {...field}
            options={state.list.map((v) => ({
              key: v.divisionId,
              value: v.divisionId,
              text: getDivisionName(v),
            }))}
            search
            placeholder={Lang.LBL_DIVISION}
            error={error?.message}
            onChange={(_, data) => {
              field.onChange(data.value ? Number(data.value) : undefined);

              return data.value;
            }}
            clearable
            loading={!state.list.length && status.fetching}
          />
        )}
      />

      <Controller
        name="name"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Form.Input {...field} placeholder="Name" error={error?.message} />
        )}
      />
    </Form>
  );
}

export default DistrictForm;

import { IDataTableState } from "src/components/organisms/datatable/datatable.component";
import { privateClient } from "src/libraries/http.library";
import { SuccessPayloadSchema } from "src/models";
import {
  IMemberForm,
  MemberDetailSchema,
  MemberListPayloadSchema,
  MemberPayloadSchema,
} from "src/models/member.model";

const client = privateClient();
const endpoint = "member";

const memberServices = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, MemberDetailSchema);
  },

  listGET: async (params: Partial<IDataTableState> = {}) => {
    return client.get(`/${endpoint}`, params, MemberListPayloadSchema);
  },

  createPOST: async (data: IMemberForm) => {
    return client.post(`/${endpoint}`, data, MemberPayloadSchema);
  },

  updatePUT: async (id: number, data: Partial<IMemberForm>) => {
    return client.put(`/${endpoint}/${id}`, data, MemberPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, SuccessPayloadSchema);
  },
};

export default memberServices;

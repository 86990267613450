import { AccessRole } from "src/constants";
import yup from "src/libraries/validator.library";

const UserShape = {
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Invalid Email Address")
    .required(),
  code: yup.string(),
  status: yup.string(),
  userTypeId: yup.number().required(),
  divisionId: yup.number().nullable(),
  districtId: yup.number().nullable(),
  lodgeId: yup.number().nullable(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
};

export const UserSchema = yup.object({
  ...UserShape,
  userId: yup.number().required(),
});

export const UserListPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(UserSchema).defined(),
});

export const UserCreateSchema = yup.object({
  ...UserShape,
  divisionId: yup
    .number()
    .nullable()
    .when("userTypeId", {
      is: (id: AccessRole) =>
        [
          AccessRole.DivisionAdmin,
          AccessRole.DistrictAdmin,
          AccessRole.LodgeAdmin,
          AccessRole.Member,
        ].includes(id),
      then: (schema) => schema.required(),
    }),
  districtId: yup
    .number()
    .nullable()
    .when("userTypeId", {
      is: (id: AccessRole) =>
        [
          AccessRole.DistrictAdmin,
          AccessRole.LodgeAdmin,
          AccessRole.Member,
        ].includes(id),
      then: (schema) => schema.required(),
    }),
  lodgeId: yup
    .number()
    .nullable()
    .when("userTypeId", {
      is: (id: AccessRole) =>
        [AccessRole.LodgeAdmin, AccessRole.Member].includes(id),
      then: (schema) => schema.required(),
    }),
});

export const UserLoginSchema = yup.object({
  email: yup
    .string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Invalid Email Address")
    .required(),
  password: yup.string().required(),
});

export const UserRecoveryPasswordSchema = yup.object({
  email: yup
    .string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Invalid Email Address")
    .required(),
});

export const UserChangePasswordSchema = yup.object({
  token: yup.string(),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Minimum 8 characters with at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character",
    )
    .required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});

export const UserLoginPayloadSchema = yup.object({
  data: UserSchema.clone(),
  authentication: yup
    .object({
      token: yup.string(),
      expiry: yup.number(),
    })
    .required(),
});

export const UserRecoverySchema = yup.object({
  password: yup.string().required(),
  token: yup.string().required(),
});

export const UserListSchema = yup.array().of(UserSchema.clone());

export type IUser = yup.InferType<typeof UserSchema>;
export type IUserCreate = yup.InferType<typeof UserCreateSchema>;
export type IUserLogin = yup.InferType<typeof UserLoginSchema>;
export type IUserRecoveryPassword = yup.InferType<
  typeof UserRecoveryPasswordSchema
>;
export type IUserChangePassword = yup.InferType<
  typeof UserChangePasswordSchema
>;

import { ICommonState, IReducerAction } from "src/libraries/thunk.library";
import ritualServices from "src/services/ritual.service";
import { IRitual } from "src/models/ritual.model";

export const ritualActionTypes = {
  RITUAL_DATA_READ: "RITUAL_DATA_READ",
  RITUAL_LIST_READ: "RITUAL_LIST_READ",
  RITUAL_DATA_CREATE: "RITUAL_DATA_CREATE",
  RITUAL_DATA_UPDATE: "RITUAL_DATA_UPDATE",
  RITUAL_DATA_DELETE: "RITUAL_DATA_DELETE",
  RITUAL_DATA_SET: "RITUAL_DATA_SET",
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: ritualActionTypes.RITUAL_DATA_READ,
    service: ritualServices.dataGET,
  },

  listGET: {
    type: ritualActionTypes.RITUAL_LIST_READ,
    service: ritualServices.listGET,
  },

  createPOST: {
    type: ritualActionTypes.RITUAL_DATA_CREATE,
    service: ritualServices.createPOST,
  },

  updatePUT: {
    type: ritualActionTypes.RITUAL_DATA_UPDATE,
    service: ritualServices.updatePUT,
  },

  dataDELETE: {
    type: ritualActionTypes.RITUAL_DATA_DELETE,
    service: ritualServices.dataDELETE,
  },

  // This is a sync action
  setData: (data: IRitual) => ({
    type: ritualActionTypes.RITUAL_DATA_SET,
    payload: {
      data,
    },
  }),
};

export type IRitualAsync = typeof duckActions;

export interface IRitualState extends ICommonState<typeof ritualActionTypes> {
  data?: IRitual;
  list: IRitual[];
  total: number;
}

export const defaultState: IRitualState = {
  status: {},
  list: [],
  total: 0,
};

const RitualReducer = (
  state: IRitualState,
  action: IReducerAction<IRitualAsync>,
): IRitualState => {
  switch (action.type) {
    case ritualActionTypes.RITUAL_DATA_SET:
    case ritualActionTypes.RITUAL_DATA_READ: {
      return {
        ...state,
        data: action.payload?.data,
      };
    }

    case ritualActionTypes.RITUAL_DATA_CREATE: {
      if (action.payload?.data) {
        const { data } = action.payload;
        return {
          ...state,
          data: data,
          list: [...state.list, data],
        };
      }

      return state;
    }

    case ritualActionTypes.RITUAL_DATA_UPDATE: {
      if (action.payload?.data) {
        const list = [...state.list];
        const { data } = action.payload;
        const index = list.findIndex((v) => v.ritualId === data.ritualId);

        list.splice(index, 1, data);

        return {
          ...state,
          data: data,
          list,
        };
      }

      return state;
    }

    case ritualActionTypes.RITUAL_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case ritualActionTypes.RITUAL_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.ritualId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default RitualReducer;

import { useCallback } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Button, Form, Modal } from "semantic-ui-react";
import { useEventContext } from "src/contexts/event.context";
import { useMemberContext } from "src/contexts/member.context";
import { memberActionTypes } from "src/ducks/member.duck";
import { getFullName } from "src/helpers/member.helper";
import { useValidator } from "src/hooks/validator.hook";
import {
  EventAttendeeFormSchema,
  IEventAttendeeForm,
} from "src/models/event.model";
import { getServiceStatus } from "src/selectors/status.selector";
import styles from "./event-attendance-form.module.less";
import Lang from "src/libraries/languages";
import MemberAdd from "../../member/member-add/member-add.component";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import { eventActionTypes } from "src/ducks/event.duck";
import classNames from "classnames";

type IProps = {
  eventId: number;
  defaultValues?: IEventAttendeeForm;
  formRef?: React.RefObject<HTMLFormElement>;
};

function EventAttendanceForm({ eventId, formRef, defaultValues }: IProps) {
  const { control, handleSubmit } = useValidator(EventAttendeeFormSchema, {
    defaultValues,
  });
  const { state: eventState, actions } = useEventContext();
  const { state } = useMemberContext();
  const status = getServiceStatus(state, memberActionTypes.MEMBER_LIST_READ);
  const updateStatus = getServiceStatus(
    eventState,
    eventActionTypes.EVENT_ATTENDANCE_CREATE,
  );

  const handleFormSubmit = useCallback(
    async (dataForm: IEventAttendeeForm) => {
      const result = await actions.attendancePOST(eventId, dataForm);

      if (result.payload) {
        ToastSuccess(Lang.MSG_ATTENDANCE_UPDATE_SUCCESS);
      }
    },
    [actions, eventId],
  );

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "attendees",
  });

  const handleAdd = useCallback(() => {
    append({
      eventId,
      memberId: "",
    } as any);

    setTimeout(() => {
      const element = document.getElementsByName(
        `attendees.${fields.length}.memberId`,
      );

      if (element.length) {
        (element[0].children[0] as any).focus();
      }
    }, 100);
  }, [append, fields]);

  return (
    <Form
      key={`attendee_form_${eventId}`}
      ref={formRef}
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(handleFormSubmit)(event);
      }}
    >
      <div className={classNames(styles.form, "modal-content-scrolling")}>
        <div className={styles.header}>
          <div>
            <h5>Attendees:</h5>
            <MemberAdd
              trigger={
                <Button size="tiny" type="button">
                  Add Member
                </Button>
              }
            />
          </div>
        </div>

        <ul className={styles.members}>
          {fields.length ? (
            fields.map((val, index) => (
              <li key={val.id}>
                <label>{index + 1}:</label>
                <Controller
                  name={`attendees.${index}.memberId`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Form.Select
                      {...field}
                      options={state.list.map((v) => ({
                        key: v.memberId,
                        value: v.memberId,
                        text: getFullName(v),
                        content: getFullName(v, true),
                      }))}
                      loading={status.fetching}
                      search
                      error={error?.message}
                      onChange={(_, { value }) => {
                        if (!value) {
                          remove(index);
                        } else {
                          update(index, {
                            ...val,
                            eventId,
                            memberId: (value || "") as number,
                          });
                        }

                        return value;
                      }}
                      clearable
                      placeholder={Lang.LBL_SELECT}
                      selectOnBlur={false}
                    />
                  )}
                />
              </li>
            ))
          ) : (
            <li>{Lang.MSG_NO_ATTENDEES}</li>
          )}
        </ul>
      </div>
      <Modal.Actions>
        <Button
          type="button"
          className={styles.addRow}
          icon="plus"
          circular
          disabled={!!fields.filter((v) => !v.memberId).length}
          onClick={handleAdd}
        />
        <Button
          primary
          type="button"
          onClick={() => formRef?.current?.requestSubmit()}
          loading={updateStatus.fetching}
          disabled={updateStatus.fetching}
        >
          {Lang.LBL_SAVE}
        </Button>
      </Modal.Actions>
    </Form>
  );
}

export default EventAttendanceForm;

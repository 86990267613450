import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Loader } from "semantic-ui-react";
import { useEventContext } from "src/contexts/event.context";
import { eventActionTypes } from "src/ducks/event.duck";
import { getServiceStatus } from "src/selectors/status.selector";
import styles from "./event-print.module.less";
import { getLodgeName } from "src/helpers/lodge.helper";
import { getDate, getTime } from "src/helpers/datetime.helper";
import FieldReadOnly from "src/components/atoms/field-readonly/field-readonly.component";
import { getFullName } from "src/helpers/member.helper";

function EventPrint() {
  const { id } = useParams();
  const { state, actions } = useEventContext();
  const status = getServiceStatus(state, eventActionTypes.EVENT_DATA_READ);
  const eventId = Number(id);

  const { data } = state;

  useEffect(() => {
    actions.dataGET(eventId);
  }, [actions, eventId]);

  const candidate = useMemo(() => {
    if (data && data.ritual?.isConferral) {
      const value = data.sections.find((v) =>
        v.name.toLowerCase().includes("candidate"),
      );

      if (value && value.positions.length) {
        return value.positions[0];
      }
    }

    return null;
  }, [data]);

  if (
    ((!data || (data && data.eventId !== eventId)) && status.fetching) ||
    !data
  ) {
    return <Loader active />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.header}>
          <h2>
            {getLodgeName({
              name: data.lodgeName,
              lodgeNo: data.lodgeNo,
            })}
          </h2>
          <h3>{data.address}</h3>
          <div className={styles.date}>{getDate(data.startDate)}</div>

          <h1>{data.name}</h1>
        </div>

        <ul className={styles.info}>
          <li>
            <FieldReadOnly label="Ritual Type" value={data.ritualName} />
          </li>
          {candidate && (
            <li>
              <FieldReadOnly label="Candidate" value={getFullName(candidate)} />
            </li>
          )}
          <li>
            <FieldReadOnly label="Open Time" value={getTime(data.startDate)} />
          </li>
          <li>
            <FieldReadOnly
              label="Close Time"
              value={getTime(data.endDate) ?? "-"}
            />
          </li>
        </ul>

        <ul className={styles.grid}>
          {data.sections
            .filter((v) =>
              candidate ? !v.name.toLowerCase().includes("candidate") : true,
            )
            .map((value) => (
              <li key={value.eventSectionId}>
                <ul className={styles.subList}>
                  <li>
                    <div className={styles.position}>
                      <div />
                      <strong>{value.name}</strong>
                    </div>
                  </li>
                  {value.positions
                    .filter((v) =>
                      v.positionName.toLowerCase().includes("officers coach")
                        ? !!v.memberId
                        : true,
                    )
                    .map((val) => {
                      return (
                        <li key={val.positionId}>
                          <div className={styles.position}>
                            <strong>{val.positionName}</strong>

                            {val.firstName ? (
                              <div>{`${val.firstName} ${val.lastName}`}</div>
                            ) : (
                              <strong>-</strong>
                            )}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </li>
            ))}
        </ul>
      </div>

      <div className={styles.watermark}>
        <strong>{window.location.hostname.replace("www.", "")}</strong>
      </div>
    </div>
  );
}

export default EventPrint;

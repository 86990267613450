import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal, Popup, Radio } from "semantic-ui-react";
import { useRitualContext } from "src/contexts/ritual.context";
import { IRitualForm } from "src/models/ritual.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { ritualActionTypes } from "src/ducks/ritual.duck";
import RitualForm from "../ritual-form/ritual-form.component";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";
import styles from "../ritual-form/ritual-form.module.less";

type IProps = {
  trigger: ReactNode;
};

function RitualAdd({ trigger }: IProps) {
  const { state, actions } = useRitualContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isConferral, setIsConferral] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const status = getServiceStatus(state, ritualActionTypes.RITUAL_DATA_CREATE);

  const handleSubmit = useCallback(
    async (formData: IRitualForm) => {
      const sections = formData.sections.map((value, index) => ({
        ...value,
        sequenceNo: index + 1,
        positions: value.positions.map((v, i) => ({
          ...v,
          sequenceNo: i + 1,
        })),
      }));

      const result = await actions.createPOST({
        ...formData,
        isConferral,
        sections,
      });

      if (result.payload) {
        ToastSuccess("Ritual created successfully.");
        actions.listGET();
        setIsOpen(false);
      }
    },
    [actions, setIsOpen, isConferral],
  );

  useEffect(() => {
    if (!isOpen) {
      setIsConferral(false);
    }
  }, [isOpen, setIsConferral]);

  return (
    <Access type={AccessType.RITUAL_CREATE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>
          Add Ritual
          <Popup
            trigger={
              <div className={styles.conferral}>
                <Radio
                  toggle
                  checked={isConferral}
                  title="Conferral"
                  onChange={(_, { checked, value }) => {
                    setIsConferral(!!checked);

                    return value;
                  }}
                />
              </div>
            }
            content={
              <div>
                Turning this <strong>ON</strong> sets this ritual to be a
                Conferral and will be added to the Member positions Report.
              </div>
            }
            basic
          />
        </Modal.Header>
        <Modal.Content scrolling className="no-padding">
          <RitualForm formRef={formRef} onSubmit={handleSubmit} />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={status.fetching}
            disabled={status.fetching}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default RitualAdd;

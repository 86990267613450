import yup from "src/libraries/validator.library";
import { LodgeSchema } from "./lodge.model";

export const DistrictShape = {
  name: yup.string().required(),
  divisionId: yup.number().required(),
  lodges: yup.array().of(LodgeSchema).default([]),
  updatedAt: yup.date(),
  updatedBy: yup.number(),
  createdAt: yup.date(),
  createdBy: yup.number(),
};

export const DistrictSchema = yup.object({
  ...DistrictShape,
  districtId: yup.number().required(),
});

export const DistrictFormSchema = yup.object({
  name: yup.string().required().default(""),
  divisionId: yup.number().required(),
});

export const DistrictListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(DistrictSchema.clone()),
});

export const DistrictPayloadSchema = yup.object({
  data: DistrictSchema.clone(),
});

export type IDistrict = yup.Asserts<typeof DistrictSchema>;
export type IDistrictForm = yup.Asserts<typeof DistrictFormSchema>;

import { ReactNode, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import { useDivisionContext } from "src/contexts/division.context";
import { divisionActionTypes } from "src/ducks/division.duck";
import Lang from "src/libraries/languages";
import { IDivision } from "src/models/division.model";
import { getServiceStatus } from "src/selectors/status.selector";

type IProps = {
  data: IDivision;
  trigger: ReactNode;
};

function DivisionDelete({ data, trigger }: IProps) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { state, actions } = useDivisionContext();
  const status = getServiceStatus(
    state,
    divisionActionTypes.DIVISION_DATA_DELETE,
  );

  const handleConfirm = useCallback(async () => {
    const result = await actions.dataDELETE(data.divisionId);

    if (result.payload) {
      ToastSuccess(result.payload.message);

      actions.listGET();
      setIsOpen(false);
    }
  }, [data, actions, setIsOpen, navigate]);

  return (
    <Access type={AccessType.DIVISION_DELETE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        size="tiny"
      >
        <Modal.Header>{Lang.TTL_CONFIRM_DELETE}</Modal.Header>
        <Modal.Content>
          Are you sure you want to delete <strong>{`${data.name}`}</strong> as a
          division?
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            type="button"
            negative
            onClick={handleConfirm}
            loading={status.fetching}
            disabled={status.fetching}
          >
            {Lang.LBL_CONFIRM}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default DivisionDelete;

import { Button, Modal } from "semantic-ui-react";
import PositionForm from "../position-form/position-form.component";
import { ReactNode, useCallback, useRef, useState } from "react";
import { usePositionContext } from "src/contexts/position.context";
import { IPosition, IPositionForm } from "src/models/position.model";
import { ToastSuccess } from "src/components/atoms/toast/toast.component";
import Lang from "src/libraries/languages";
import { getServiceStatus } from "src/selectors/status.selector";
import { positionActionTypes } from "src/ducks/position.duck";
import Access, {
  AccessType,
} from "src/components/atoms/access/access.component";

type IProps = {
  data: IPosition;
  trigger: ReactNode;
};

function PositionEdit({ data, trigger }: IProps) {
  const { state, actions } = usePositionContext();
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const status = getServiceStatus(
    state,
    positionActionTypes.POSITION_DATA_UPDATE,
  );

  const handleSubmit = useCallback(
    async (formData: IPositionForm) => {
      if (data) {
        const result = await actions.updatePUT(data?.positionId, formData);

        if (result.payload) {
          ToastSuccess("Position updated successfully.");
          actions.listGET();
          setIsOpen(false);
        }
      }
    },
    [actions, data, setIsOpen],
  );
  return (
    <Access type={AccessType.POSITION_UPDATE}>
      <Modal
        open={isOpen}
        trigger={trigger}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header>Edit Position</Modal.Header>
        <Modal.Content>
          <PositionForm
            defaultValues={data}
            formRef={formRef}
            onSubmit={handleSubmit}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setIsOpen(false)}>
            {Lang.LBL_CANCEL}
          </Button>
          <Button
            primary
            type="button"
            onClick={() => formRef.current?.requestSubmit()}
            loading={status.fetching}
            disabled={status.fetching}
          >
            {Lang.LBL_SAVE}
          </Button>
        </Modal.Actions>
      </Modal>
    </Access>
  );
}

export default PositionEdit;

import { AxiosRequestConfig } from "axios";
import { IDataTableState } from "src/components/organisms/datatable/datatable.component";
import { privateClient } from "src/libraries/http.library";
import { SuccessPayloadSchema } from "src/models";
import {
  IEventForm,
  EventListPayloadSchema,
  EventPayloadSchema,
  EventPositionReportPayloadSchema,
  IEventNoteForm,
  EventNoteListPayloadSchema,
  EventNotePayloadSchema,
  IEventAttendeeForm,
  EventAttendeesPayloadSchema,
  EventMemberAttendancePayloadSchema,
} from "src/models/event.model";

const client = privateClient();
const endpoint = "event";
const noteEndpoint = "eventNote";
const attendeeEndpoint = "eventAttendee";

const eventServices = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, EventPayloadSchema);
  },

  listGET: async (params: Partial<IDataTableState> = {}) => {
    return client.get(`/${endpoint}`, params, EventListPayloadSchema);
  },

  createPOST: async (data: IEventForm) => {
    return client.post(`/${endpoint}`, data, EventPayloadSchema);
  },

  updatePUT: async (id: number, data: IEventForm) => {
    return client.put(`/${endpoint}/${id}`, data, EventPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, SuccessPayloadSchema);
  },

  reportMemberGET: async (
    params: Partial<IDataTableState>,
    options: Partial<AxiosRequestConfig> = {},
  ) => {
    return client.get(
      `/${endpoint}?action=report`,
      params,
      EventPositionReportPayloadSchema,
      options,
    );
  },

  noteListPOST: async (eventId: number) => {
    return client.get(
      `/${noteEndpoint}/${eventId}`,
      {},
      EventNoteListPayloadSchema,
    );
  },

  notePOST: async (data: IEventNoteForm) => {
    return client.post(`/${noteEndpoint}`, data, EventNotePayloadSchema);
  },

  notePUT: async (id: number, data: IEventNoteForm) => {
    return client.put(`/${noteEndpoint}/${id}`, data, EventNotePayloadSchema);
  },

  noteDELETE: async (id: number) => {
    return client.delete(`/${noteEndpoint}/${id}`, SuccessPayloadSchema);
  },

  attendanceGET: async (eventId: number) => {
    return client.get(
      `/${attendeeEndpoint}/${eventId}`,
      {},
      EventAttendeesPayloadSchema,
    );
  },

  attendancePOST: async (eventId: number, data: IEventAttendeeForm) => {
    return client.post(
      `/${attendeeEndpoint}`,
      { ...data, eventId },
      EventAttendeesPayloadSchema,
    );
  },

  attendanceEventGET: async (memberId: number) => {
    return client.get(
      `/${attendeeEndpoint}`,
      {
        action: "events",
        memberId,
      },
      EventMemberAttendancePayloadSchema,
    );
  },
};

export default eventServices;

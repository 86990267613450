import { privateClient } from "src/libraries/http.library";
import { SuccessPayloadSchema } from "src/models";
import {
  IPositionForm,
  PositionListPayloadSchema,
  PositionPayloadSchema,
} from "src/models/position.model";

const client = privateClient();
const endpoint = "position";

const positionServices = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, PositionPayloadSchema);
  },

  listGET: async () => {
    return client.get(`/${endpoint}`, {}, PositionListPayloadSchema);
  },

  createPOST: async (data: IPositionForm) => {
    return client.post(`/${endpoint}`, data, PositionPayloadSchema);
  },

  updatePUT: async (id: number, data: IPositionForm) => {
    return client.put(`/${endpoint}/${id}`, data, PositionPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, SuccessPayloadSchema);
  },
};

export default positionServices;

import yup, { checkDuplicate } from "src/libraries/validator.library";
import { DefaultSchema } from ".";
import { PositionSchema } from "./position.model";

export const RitualSectionPositionSchema = yup
  .object({
    ritualSectionPositionId: yup.number().required(),
    ritualSectionId: yup.number().required(),
    positionId: yup.number().required(),
    sequenceNo: yup.number(),
    position: PositionSchema.clone(),
  })
  .concat(DefaultSchema);

export const RitualSectionPositionFormSchema = yup.object({
  positionId: yup.number().required(),
});

export const RitualSectionSchema = yup
  .object({
    ritualSectionId: yup.number().required(),
    name: yup.string().required(),
    ritualId: yup.number().required(),
    positions: yup.array().of(RitualSectionPositionSchema).defined(),
    sequenceNo: yup.number(),
  })
  .concat(DefaultSchema);

export const RitualSectionFormSchema = yup.object({
  name: yup.string().required(),
  sequenceNo: yup.number(),
  positions: yup.array().of(RitualSectionPositionFormSchema).min(1).required(),
});

export const RitualShape = {
  name: yup.string().required(),
  isConferral: yup.boolean(),
  ritualId: yup.number().required(),
  sections: yup.array().of(RitualSectionSchema).defined(),
};

export const RitualSchema = yup.object(RitualShape).concat(DefaultSchema);

export const RitualFormSchema = yup.object({
  name: yup.string().required().default(""),
  isConferral: yup.boolean(),
  sections: yup
    .array()
    .of(RitualSectionFormSchema)
    .min(1)
    .required()
    .test({
      name: "unique",
      test: checkDuplicate("name", "Section already exists"),
    }),
});

export const RitualListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(RitualSchema.clone()),
});

export const RitualPayloadSchema = yup.object({
  data: RitualSchema.clone(),
});

export type IRitual = yup.Asserts<typeof RitualSchema>;
export type IRitualForm = yup.Asserts<typeof RitualFormSchema>;
export type IRitualSection = yup.Asserts<typeof RitualSectionSchema>;
export type IRitualSectionPosition = yup.Asserts<
  typeof RitualSectionPositionSchema
>;
export type IRitualSectionForm = yup.Asserts<typeof RitualSectionFormSchema>;

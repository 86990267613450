export default {
  LBL_FIRST_NAME: "First Name",
  LBL_LAST_NAME: "Last Name",
  LBL_FULL_NAME: "Name",
  LBL_EMAIL: "E-mail",
  LBL_USER_TYPE: "User Type",
  LBL_DIVISION: "Division",
  LBL_DISTRICT: "District",
  LBL_LODGE: "Lodge",
  LBL_LODGE_NUMBER: "Lodge Number",
  LBL_RITUAL_TYPE: "Ritual Type",
  LBL_PASSWORD: "Password",
  LBL_PASSWORD_CONFIRM: "Confirm Password",

  LBL_LOGIN: "Login",
  LBL_CANCEL: "Cancel",
  LBL_CLOSE: "Close",
  LBL_SEND: "Send",
  LBL_SAVE: "Save",
  LBL_DELETE: "Delete",

  LBL_SEARCH: "Search",
  LBL_CONFIRM: "Confirm",

  LBL_CONTACT_NUMBER: "Number",
  LBL_ADDRESS: "Address",
  LBL_POSITION: "Position",

  LBL_MEMBER_NUMBER: "Member No.",
  LBL_STATUS: "Status",
  LBL_LEVEL: "Level",
  LBL_ACTIONS: "Actions",

  LBL_NOTE_MESSAGE: "Enter Message",

  LBL_FILTER_BY: "Filter By",

  LBL_SELECT: "Select",
  LBL_SELECT_DIVISION: "Select Division",
  LBL_SELECT_DISTRICT: "Select District",
  LBL_SELECT_LODGE: "Select Lodge",
};

import { AccessRole } from "src/constants";

export default {
  roles: {
    [AccessRole.Admin]: "Admin",
    [AccessRole.JurisdictionAdmin]: "Jurisdiction Admin",
    [AccessRole.DivisionAdmin]: "Division Admin",
    [AccessRole.DistrictAdmin]: "District Admin",
    [AccessRole.LodgeAdmin]: "Lodge Admin",
    [AccessRole.Member]: "Member",
  },
};

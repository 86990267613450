import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import { useLodgeContext } from "src/contexts/lodge.context";
import { useMemberContext } from "src/contexts/member.context";
import { useRitualContext } from "src/contexts/ritual.context";
import { useValidator } from "src/hooks/validator.hook";
import Lang from "src/libraries/languages";
import {
  IEvent,
  IEventForm,
  EventFormSchema,
  IEventSection,
  IEventPosition,
} from "src/models/event.model";
import { IRitual } from "src/models/ritual.model";
import MemberAdd from "../../member/member-add/member-add.component";
import styles from "./event-form.module.less";
import { IMember } from "src/models/member.model";
import { getLodgeName } from "src/helpers/lodge.helper";
import { useMemberLodgeList } from "src/hooks/member.hook";
import { ILodge } from "src/models/lodge.model";
import { getFullName } from "src/helpers/member.helper";
import classNames from "classnames";

const hasAutoFill = (current: IEventSection, previous?: IEventSection) => {
  const positions = (previous?.positions ?? []).map((v) => v.positionId);

  return !!current.positions.filter((v) => positions.includes(v.positionId))
    .length;
};

type IProps = {
  isSync?: boolean;
  defaultValues?: Partial<IEvent>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (formData: IEventForm) => Promise<void>;
};

function EventForm({
  formRef,
  defaultValues,
  isSync = false,
  onSubmit,
}: IProps) {
  const { control, handleSubmit, getValues, watch, setValue, resetField } =
    useValidator(EventFormSchema, {
      defaultValues: {
        ...defaultValues,
        selectedDate: defaultValues
          ? moment(defaultValues.startDate).format("YYYY-MM-DD")
          : undefined,
      } as unknown as IEventForm,
    });
  const { state: lodgeState, actions: lodgeActions } = useLodgeContext();
  const { state: ritualState, actions: ritualActions } = useRitualContext();
  const { state: memberState, actions: memberActions } = useMemberContext();
  const allowedLodgeList = useMemberLodgeList();
  const [ritual, setRitual] = useState<IRitual>(
    defaultValues as unknown as IRitual,
  );
  const [loading, setLoading] = useState(false);
  const [ritualList, setRitualList] = useState<IRitual[]>([]);
  const [lodgeList, setLodgeList] = useState<ILodge[]>([]);

  const { fields, append } = useFieldArray({
    control,
    name: "sections",
  });

  // For updating ritual dropdown list
  useEffect(() => {
    if (defaultValues && ritualState.list.length && ritual) {
      const exist = ritualState.list.find(
        (v) => v.ritualId === defaultValues.ritualId,
      );

      if (!exist) {
        setRitualList([
          ...ritualState.list,
          {
            ritualId: defaultValues.ritualId,
            name: defaultValues.ritualName,
            sections: defaultValues.sections,
          } as unknown as IRitual,
        ]);
      } else {
        const list = ritualState.list.map((value) => {
          // We'll just update the ritual name
          if (value.ritualId === defaultValues.ritualId) {
            return {
              ...value,
              name: isSync ? value.name : (defaultValues.ritualName as string),
            };
          }

          return value;
        });

        setRitualList(list);
      }

      setRitual((isSync ? exist : defaultValues) as unknown as IRitual);
    } else {
      setRitualList(ritualState.list);
    }
  }, [defaultValues, ritualState.list, setRitualList, setRitual, isSync]);

  useEffect(() => {
    if (defaultValues && allowedLodgeList.length) {
      const exist = allowedLodgeList.find(
        (v) => v.lodgeId === defaultValues.lodgeId,
      );

      if (!exist) {
        setLodgeList([
          ...allowedLodgeList,
          {
            lodgeId: defaultValues.lodgeId,
            name: defaultValues.lodgeName,
            address: defaultValues.lodgeAddress,
            lodgeNo: defaultValues.lodgeNo,
          } as unknown as ILodge,
        ]);

        return;
      }
    }

    setLodgeList(allowedLodgeList);
  }, [defaultValues, allowedLodgeList, setLodgeList]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        lodgeActions.listGET(),
        ritualActions.listGET(),
        memberActions.listGET(),
      ]);
      setLoading(false);
    };

    fetchData();
  }, [lodgeActions, ritualActions, memberActions, setLoading]);

  const selectedDate = watch("selectedDate");
  const lodgeId = watch("lodgeId");

  useEffect(() => {
    if (ritual) {
      resetField("sections", {
        defaultValue: [],
      });

      const list = (ritual?.sections ?? []).reduce(
        (items: IEventSection[], item) => {
          const section = (defaultValues?.sections ?? []).find(
            (v) => v.ritualSectionId === item.ritualSectionId,
          );

          if (
            defaultValues &&
            defaultValues.ritualId === ritual.ritualId &&
            defaultValues.lodgeId === lodgeId &&
            section &&
            item.ritualSectionId === section.ritualSectionId &&
            !isSync
          ) {
            return [...items, section];
          } else {
            let positions: IEventPosition[] = item.positions.map((value) => ({
              ...value,
              memberId: undefined as unknown as number,
              positionName:
                value.position?.name ??
                (value as unknown as IEventSection["positions"][number])
                  .positionName,
            }));

            if (isSync && section) {
              const usedIndexes: number[] = [];

              positions = positions.map((value) => {
                const position = section.positions.find(
                  (val) =>
                    val.eventPositionId &&
                    !usedIndexes.includes(val.eventPositionId) &&
                    val.positionId === value.positionId,
                );

                if (position && position.eventPositionId) {
                  usedIndexes.push(position.eventPositionId);
                }

                return position
                  ? {
                      ...position,
                      ritualSectionPositionId: value.ritualSectionPositionId,
                      sequenceNo: value.sequenceNo,
                    }
                  : value;
              });
            }

            return [
              ...items,
              {
                ritualSectionId: item.ritualSectionId,
                name: item.name,
                sequenceNo: item.sequenceNo,
                positions,
              },
            ];
          }
        },
        [],
      );

      append(list);
    }
  }, [ritual, append, resetField, defaultValues, lodgeId, isSync]);

  const handleAutoFill = useCallback(
    (sectionNo: number) => {
      const list = [...fields];
      const fieldPositions = list[sectionNo].positions;

      list.splice(sectionNo, 1);
      list.reverse();

      const positions = list
        .map((v) => v.positions)
        .flat()
        .filter((v) => v.memberId);

      fieldPositions.forEach((value, index) => {
        if (!value.memberId) {
          const data = positions.find((v) => v.positionId === value.positionId);

          if (data) {
            resetField(`sections.${sectionNo}.positions.${index}`, {
              defaultValue: {
                ...value,
                memberId: data?.memberId ?? null,
                firstName: data?.firstName ?? "",
                lastName: data?.lastName ?? "",
              },
            });
          }
        }
      });
    },
    [fields, resetField],
  );

  useEffect(() => {
    if (defaultValues && ritual && defaultValues.ritualId === ritual.ritualId) {
      setValue(
        "ritualName",
        isSync ? ritual.name : defaultValues?.ritualName ?? "",
      );
    }
  }, [isSync, defaultValues, ritual, setValue]);

  return (
    <div className={styles.wrapper}>
      <Form
        ref={formRef}
        data-testid="form"
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        loading={
          loading &&
          (!lodgeState.list.length ||
            !ritualState.list.length ||
            !memberState.list)
        }
      >
        <div className={styles.form}>
          <Grid columns={3} stackable doubling>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Form.Field>
                      <label>Name</label>
                      <Form.Input fluid {...field} error={error?.message} />
                    </Form.Field>
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
              <Grid.Column>
                <Controller
                  name="lodgeId"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Form.Field>
                      <label>{Lang.LBL_LODGE}</label>
                      <Form.Select
                        {...field}
                        options={lodgeList.map((v) => ({
                          key: v.lodgeId,
                          value: v.lodgeId,
                          text: getLodgeName(v),
                        }))}
                        search
                        error={error?.message}
                        onChange={(_, data) => {
                          const lodge = lodgeList.find(
                            (v) => v.lodgeId === data.value,
                          );
                          field.onChange(lodge ? lodge.lodgeId : undefined);

                          setValue("address", lodge?.address ?? "");
                          setValue("lodgeName", lodge?.name ?? "");
                          setValue("lodgeNo", lodge?.lodgeNo ?? "");
                          setValue("lodgeAddress", lodge?.address ?? "");
                          resetField("sections", {
                            defaultValue: [],
                          });

                          return data.value;
                        }}
                        clearable
                        selectOnBlur={false}
                      />
                    </Form.Field>
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
              <Grid.Column>
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Form.Field>
                      <label>Address</label>
                      <Form.Input fluid {...field} error={error?.message} />
                    </Form.Field>
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
              <Grid.Column>
                <Controller
                  name="ritualId"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Form.Field>
                      <label>{Lang.LBL_RITUAL_TYPE}</label>
                      <Form.Select
                        {...field}
                        options={ritualList.map((v) => ({
                          key: v.ritualId,
                          value: v.ritualId,
                          text: v.name,
                        }))}
                        search
                        error={error?.message}
                        onChange={(_, { value }) => {
                          const data = ritualList.find(
                            (v) => v.ritualId === value,
                          );
                          field.onChange(value ? value : undefined);

                          setValue("ritualName", data?.name ?? "");
                          resetField("sections", {
                            defaultValue: [],
                          });

                          setRitual(
                            (!isSync &&
                            defaultValues &&
                            defaultValues.ritualId === value
                              ? defaultValues
                              : data) as IRitual,
                          );

                          return value;
                        }}
                        clearable
                        selectOnBlur={false}
                      />
                    </Form.Field>
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Controller
                  name="selectedDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Form.Field>
                      <label>Date</label>
                      <Form.Input
                        {...field}
                        type="date"
                        error={error?.message}
                        onChange={(_, { value }) => {
                          field.onChange(value);
                          if (value) {
                            const values = getValues();
                            const dates: (keyof Pick<
                              IEventForm,
                              "startDate" | "endDate"
                            >)[] = ["startDate", "endDate"];

                            dates.forEach((field) => {
                              if (values[field]) {
                                const time = moment(values[field]).format(
                                  "HH:mm",
                                );
                                setValue(
                                  field,
                                  moment(`${value} ${time}`).toDate(),
                                );
                              }
                            });
                          }

                          return value;
                        }}
                      />
                    </Form.Field>
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name="startDate"
                  control={control}
                  render={({
                    field: { value, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Field>
                      <label>Open Time</label>
                      <Form.Input
                        {...field}
                        value={value && moment(value).format("HH:mm")}
                        type="time"
                        error={error?.message}
                        onChange={(_, { value }) => {
                          field.onChange(
                            moment(
                              `${
                                selectedDate || moment().format("YYYY-MM-DD")
                              } ${value}`,
                              "YYYY-MM-DD HH:mm",
                            ).toDate(),
                          );

                          return value;
                        }}
                      />
                    </Form.Field>
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name="endDate"
                  control={control}
                  render={({
                    field: { value, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Field>
                      <label>Close Time</label>
                      <Form.Input
                        {...field}
                        value={value && moment(value).format("HH:mm")}
                        type="time"
                        error={error?.message}
                        icon={
                          value ? (
                            <Icon
                              name="delete"
                              link
                              onClick={() => setValue("endDate", "" as any)}
                            />
                          ) : undefined
                        }
                        onChange={(_, { value }) => {
                          field.onChange(
                            moment(
                              `${
                                selectedDate || moment().format("YYYY-MM-DD")
                              } ${value}`,
                              "YYYY-MM-DD HH:mm",
                            ).toDate(),
                          );

                          return value;
                        }}
                      />
                    </Form.Field>
                  )}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {ritual ? (
            <div className={styles.sections}>
              <div className={styles.actions}>
                <div>
                  <MemberAdd
                    trigger={
                      <Button size="tiny" type="button">
                        Add a Member
                      </Button>
                    }
                  />
                </div>
              </div>

              <ul className={styles.list}>
                {fields.map((value, sectionIndex) => (
                  <li key={value.id}>
                    <div className={classNames(styles.section)}>
                      <div>
                        <strong>{value.name}</strong>
                      </div>
                    </div>

                    {hasAutoFill(value, fields[sectionIndex - 1]) && (
                      <div className={styles.autofill}>
                        <Button
                          size="mini"
                          type="button"
                          onClick={() => handleAutoFill(sectionIndex)}
                        >
                          Auto Fill
                        </Button>
                      </div>
                    )}

                    <ul className={styles.subList}>
                      {value.positions.map((val, positionIndex) => {
                        const memberList = [...memberState.list];

                        if (
                          defaultValues &&
                          ritual.ritualId === defaultValues.ritualId &&
                          lodgeId === defaultValues.lodgeId &&
                          !memberList.find(
                            (v) => v.memberId === val.memberId,
                          ) &&
                          val.firstName &&
                          val.lastName
                        ) {
                          memberList.push(val as unknown as IMember);
                        }

                        return (
                          <li key={val.positionId}>
                            <div className={styles.position}>
                              <label>{val.positionName}</label>

                              <Controller
                                name={`sections.${sectionIndex}.positions.${positionIndex}.memberId`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <Form.Select
                                    {...field}
                                    value={field.value as any}
                                    options={memberList.map((v) => ({
                                      key: v.memberId,
                                      value: v.memberId,
                                      text: `${getFullName(v)}`,
                                      content: getFullName(v, true),
                                    }))}
                                    search
                                    error={error?.message}
                                    defaultUpward={false}
                                    onChange={(_, data) => {
                                      const member = memberList.find(
                                        (v) =>
                                          v.memberId === Number(data.value),
                                      );

                                      resetField(
                                        `sections.${sectionIndex}.positions.${positionIndex}`,
                                        {
                                          defaultValue: {
                                            ...val,
                                            memberId: member?.memberId ?? null,
                                            firstName: member?.firstName ?? "",
                                            lastName: member?.lastName ?? "",
                                          },
                                        },
                                      );

                                      return data.value;
                                    }}
                                    clearable
                                    selectOnBlur={false}
                                  />
                                )}
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </Form>
    </div>
  );
}

export default EventForm;

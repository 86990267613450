import { useCallback, useEffect, useState } from "react";
import { Icon, Input, Segment } from "semantic-ui-react";
import { useEventContext } from "src/contexts/event.context";
import { eventActionTypes } from "src/ducks/event.duck";
import { getServiceStatus } from "src/selectors/status.selector";
import EventDetail from "../../event/event-detail/event-detail.component";
import { getDate } from "src/helpers/datetime.helper";
import DataTable, {
  IDataTableColumn,
  IDataTableState,
} from "src/components/organisms/datatable/datatable.component";
import { IEventMemberAttendance } from "src/models/event.model";
import Lang from "src/libraries/languages";

type IProps = {
  memberId: number;
};

const columns: IDataTableColumn<IEventMemberAttendance>[] = [
  {
    index: "event.startDate",
    title: "Date",
    render: (value) => getDate(value),
  },
  {
    index: "name",
    title: "Name",
    render: (_, values) => (
      <EventDetail id={values.eventId} trigger={<a>{values.event.name}</a>} />
    ),
  },
];

function MemberEventAttendance({ memberId }: IProps) {
  const { state, actions } = useEventContext();
  const [keyword, setKeyword] = useState<string>("");
  const [list, setList] = useState<IEventMemberAttendance[]>([]);
  const status = getServiceStatus(
    state,
    eventActionTypes.EVENT_MEMBER_ATTENDANCE_LIST_READ,
  );

  useEffect(() => {
    actions.attendanceEventGET(memberId);
  }, [memberId, actions]);

  useEffect(() => {
    if (keyword) {
      setList(
        state.memberEventList.filter((v) =>
          `${v.event.name} ${getDate(v.event.startDate)}`
            .toLowerCase()
            .includes(keyword.toLowerCase()),
        ),
      );
    } else {
      setList(state.memberEventList);
    }
  }, [state.memberEventList, keyword, setList]);

  const handleFilter = useCallback(
    (params: IDataTableState) => {
      const filter = params.filters.find((v) => v.name === "filter");

      if (filter) {
        setKeyword(filter.value);
      }
    },
    [setKeyword],
  );

  return (
    <Segment>
      <h4>Events Attended</h4>

      <DataTable
        columns={columns}
        data={list}
        loading={status.fetching}
        onChange={handleFilter}
        rowsPerPage={[10]}
        toolbars={{
          filter: ({ value, setValue }) => (
            <Input
              placeholder={Lang.LBL_SEARCH}
              value={value}
              onChange={(_, data) => setValue(data.value)}
              icon={
                value ? (
                  <Icon name="close" link onClick={() => setValue("")} />
                ) : undefined
              }
            />
          ),
        }}
      />
    </Segment>
  );
}

export default MemberEventAttendance;
